<template>
  <div class="external-navbar">
    <div class="logo">
      <router-link v-if="hostName === 'developer'" :to="`/${$i18n.locale}/login`">
        <img src="/static/img/getwork_logo/full_dark.svg" alt="">
      </router-link>
      <a
        v-else
        :href="url"
        target="_blank"
        rel="noopener noreferrer">
        <img src="/static/img/getwork_logo/full_dark.svg" alt="">
      </a>
    </div>
    <div class="lang-switcher-wrap">
      <language-switcher/>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/UIComponents/Custom/languageSwitcher";
import {mapGetters} from "vuex";
export default {
  name: "ExternalNavbar",
  components: {
    LanguageSwitcher,
  },
  computed: {
    ...mapGetters(['hostName']),
    url() {
      if (['uxuidesigner', 'designer'].includes(this.hostName) && this.$i18n.locale === 'en') {
        return 'https://en.cashforapp.net/endesigners'
      }
      if (['uxuidesigner', 'designer'].includes(this.hostName) && this.$i18n.locale === 'ru') {
        return 'https://get-work.app/ru/designers'
      }
    }
  },
}
</script>

<style scoped>

</style>
