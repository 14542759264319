import { createI18n } from 'vue-i18n'
import ru from './locale.ru.json'
import en from './locale.en.json'


const messages = {
  en,
  ru,
}

export default createI18n({
  locale: 'ru',
  messages
})
