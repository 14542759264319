export default {
  namespaced: true,

  state: {
    type: undefined,
    mode: undefined, // 'new', 'edit', 'view'
    preload: undefined,
    callback: undefined,
    show: false,
    props: undefined // type data: any
  },

  getters: {
    type: state => state.type,
    mode: state => state.mode,
    preload: state => state.preload,
    callback: state => state.callback,
    show: state => state.show,
    props: state => state.props
  },

  mutations: {
    openModal(state, payload) {
      const { type, mode, props, preload, callback } = payload
      state.type = type
      state.mode = mode
      state.preload = preload
      state.callback = callback
      state.show = true
      state.props = props
    },
    closeModal(state) {
      state.type = undefined
      state.mode = undefined
      state.preload = undefined
      state.callback = undefined
      state.show = false
    },
    setMode(state, value) {
      state.mode = value
    }
  }
}
