import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3";
import FormDataV3 from "@/utils/formDataApiV3";

export default {
  state: {
    currencyList: []
  },

  getters: {
    currencyList: state => state.currencyList
  },

  mutations: {
    setCurrencyList(state, value) {
      state.currencyList = value
    }
  },

  actions: {
    async getCurrency({ commit, rootGetters }) {
      const ABORT_KEY = 'currency'
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      const controller = new AbortController()
      commit('setAbortController', { controller, name: ABORT_KEY })
      try {
        let url = new UrlBuilderApiV3()
          .setEndpoint('types')
          .addFilters(`filter[type]=currencies`)
          .build()
        const response = await axiosV3.get(url, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
        const currencyList = response.data
        commit('setCurrencyList', currencyList)
        commit('deleteAbortController', ABORT_KEY)
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('Не удалось получить курс валют. Попробуйте позже.')
      }
    },

    async updateRate({ state, commit, dispatch }, payload) {
      const { id, value } = payload
      try {
        const data = new FormDataV3()
        data.addParam('type', 'currencies')
        data.addParam('id', id)
        data.addAttr('value', value)
        const url = new UrlBuilderApiV3()
          .setEndpoint(`types/${id}`)
          .build()
        const response = await axiosV3.patch(url, data)
        const currencyInfo = response.data
        const currencyList = state.currencyList
        const updatedList = currencyList.map(item => {
          if (item.id === id) {
            return currencyInfo
          }
          return item
        })
        commit('setCurrencyList', updatedList)
      } catch (e) {
        console.info(e)
        dispatch('setNotifyError', 'Не удалось обновить курс валют. Попробуйте позже.')
      }
    },
  }
}
