import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3";
import { PROGRAMMER_TASK_TYPES } from "@/utils/constants";

export default {
  state: {
    applications: null,
    application: null,
    applicationsListPageCount: null,
    applicationsListTotalCount: null,
    creatorAppData: {
      unity: {},
      unity_export: {},
      webgl: {}
    },
    applicationsWithTasks: [],
    needShowTaskCard: false,
    appController: null
  },
  getters: {
    applications: state => state.applications,
    application: state => state.application,
    applicationsListPageCount: state => state.applicationsListPageCount,
    applicationsListTotalCount: state => state.applicationsListTotalCount,
    creatorAppData: state => state.creatorAppData,
    applicationsWithTasks: state => state.applicationsWithTasks,
    needShowTaskCard: state => state.needShowTaskCard,
    appController: state => state.appController
  },
  mutations: {
    setApplications(state, applications) {
      state.applications = applications
    },
    setApplication(state, application) {
      state.applications.push(application)
      this.commit('setApplications', state.applications)
    },
    setApplicationsListPageCount(state, value) {
      state.applicationsListPageCount = value
    },
    setApplicationsListTotalCount(state, value) {
      state.applicationsListTotalCount = value
    },
    setCreatorAppData(state, value) {
      state.creatorAppData = value
    },
    setApplicationsWithTasks(state, value) {
      state.applicationsWithTasks = value
    },
    setNeedShowTaskCard(state, value) {
      state.needShowTaskCard = value
    },
    updateTaskStatusesForApp(state, payload) {
      const { appId, taskStatuses } = payload
      const app = state.applications.find(item => item.id === appId)
      if (!app.task) return
      for (const taskStatus of taskStatuses) {
        const { taskId, statusName } = taskStatus
        const task = app.task.find(item => item.id === taskId)
        if (!task) continue
        task.status_name = statusName
      }
    },
    setAppController(state, value) {
      state.appController = value
    }
  },
  actions: {
    async getAsoApplications({ rootGetters, commit }, payload) {
      let { requestArgs, page, search, userId } = payload
      const customFilters = requestArgs.concat()
      const ABORT_KEY = 'appList'
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
      let urlBuilder = new UrlBuilderApiV3()
      urlBuilder.setEndpoint('applications')
      if (search) urlBuilder.setSearch(search)
      if (userId) customFilters.push(`filter[aso_manager][id]=${userId}`)
      const inclusions = [
        'status',
        'tag',
        'store',
      ]
      if (!userId) inclusions.push(...['aso_manager', 'quality_type'])
      if (customFilters.length) urlBuilder.addFilters(customFilters)
      const finalUrl = urlBuilder
        .addInclude(inclusions)
        .setPage(page)
        .build()
      try {
        const response = await axiosV3.get(finalUrl, {
          signal: rootGetters.abortControllers[ABORT_KEY].signal
        })
        const metaData = response.meta
        commit('setApplications', response.data)
        commit('setApplicationsListPageCount', metaData.page.last_page)
        commit('setApplicationsListTotalCount', metaData.page.total)
        commit('deleteAbortController', ABORT_KEY, { root: true })
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('При загрузке списка приложений произошла ошибка. Попробуйте позже.')
      }
    },
    clearApplications({ commit }) {
      commit('setApplications', null)
      commit('setApplicationsListPageCount', null)
    },
    creatorAppDataChanged({ state, commit }, payload) {
      const { desktop_name, version, version_number, assembly_number, frameworkName, initData } = payload
      const creatorAppData = state.creatorAppData
      const newData = { ...creatorAppData[frameworkName] }
      if (desktop_name) {
        if (desktop_name === initData.desktop_name) {
          delete newData.desktop_name
        } else {
          newData.desktop_name = desktop_name
        }
      } else if (version) {
        if (version === initData.version) {
          delete newData.version
        } else {
          newData.version = version
        }
      } else if (version_number) {
        if (version_number === initData.version_number) {
          delete newData.version_number
        } else {
          newData.version_number = version_number
        }
      } else if (assembly_number) {
        if (assembly_number === initData.assembly_number) {
          delete newData.assembly_number
        } else {
          newData.assembly_number = assembly_number
        }
      }
      const updatedData = {
        ...creatorAppData,
        [frameworkName]: newData
      }
      commit('setCreatorAppData', updatedData)
    },
    clearCreatorAppData({ commit }) {
      commit('setCreatorAppData', {
        unity: {},
        unity_export: {},
        webgl: {}
      })
    },
    //TODO Api v3: объеденить с downloadApplicationV3
    async downloadApplication({ commit, state }, appId) {
      if (state.appController) {
        state.appController.abort()
      }
      const newController = new AbortController()
      commit('setAppController', newController)
      try {
        const response = await axios.get(`/v2/admin/applications/${appId}`, {
          signal: state.appController.signal
        })
        const app = response.data.response
        commit('setSelectedApplication', app)
        return true
      } catch (e) {
        console.log(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('При загрузке данных произошла ошибка. Попробуйте позже.')
      }
    },
    //TODO Api v3: объеденить с downloadApplication
    async downloadApplicationV3({ commit, state }, appId) {
      if (state.appController) {
        state.appController.abort()
      }
      const newController = new AbortController()
      commit('setAppController', newController)
      const url = new UrlBuilderApiV3()
        .setEndpoint(`applications/${appId}`)
        .addInclude('tasks.subtasks', 'tasks.type')
        .build()
      try {
        const response = await axiosV3.get(url, {
          signal: state.appController.signal
        })
        const subtask = response.included.find(subtask => PROGRAMMER_TASK_TYPES.includes(subtask.attributes.type))
        return {
          application: {...response.data},
          id: subtask.id,
          title: subtask.attributes.title
        }
      } catch (e) {
        console.log(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('При загрузке данных произошла ошибка. Попробуйте позже.')
      }
    },
    selectApplicationById({ state, commit, rootGetters }, payload) {
      const { id, forUpdate } = payload
      let result
      const appList = forUpdate ? rootGetters['app_manager/applicationsForUpdate'] : state.applications
      const app = appList.find(item => item.id === id)
      if (!app) {
        appList.map(item => {
          if (!result && item.children && item.children.length) {
            for (const childApp of item.children) {
              if (childApp.id === id) {
                result = childApp
                break
              }
            }
          }
        })
      } else {
        result = app
      }
      commit('setSelectedApplication', result)
    },

    async downloadTaskListForApp({ state, rootState, commit }, appId) {
      try {
        let updatedAppsWithTasks
        const appsWithTasks = state.applicationsWithTasks
        const app = state.applications.find(item => item.id === appId)
        const url = new UrlBuilderApiV3()
          .setEndpoint(`applications/${appId}`)
          .addInclude('tasks.status', 'tasks.type', 'tasks.subtasks.status', 'tasks.subtasks.user')
          .build()
        const response = await axiosV3.get(url)
        const transformSubtrasks = tasks => {
          return tasks.map(item => {
            item.subtasks.forEach(subtask => {
              item[subtask.type] = subtask
            })
            return {...item}
          })
        }
        const task = transformSubtrasks(response.data.tasks)
        let getStatusName = id => rootState.types.status.find(s => s.id === id).name
        let taskStatuses = task.map(item => ({ taskId: item.id, statusName: getStatusName(+item.status.id) }))
        let updatedApp = { ...app, task }
        let appExists = appsWithTasks.findIndex(item => item.id === appId) >= 0
        if (appExists) {
          updatedAppsWithTasks = appsWithTasks.map(item => {
            if (item.id === updatedApp.id) return updatedApp
            return item
          })
        } else {
          updatedAppsWithTasks = appsWithTasks
          updatedAppsWithTasks.push(updatedApp)
        }
        commit('setApplicationsWithTasks', updatedAppsWithTasks)
        commit('updateTaskStatusesForApp', { appId, taskStatuses })
      } catch (e) {
        console.log(e)
        this.$app.$notifyError('При загрузке списка задач произошла ошибка. Попробуйте позже.')
      }
    },

    clearAppsWithTasks({ commit }) {
      commit('setApplicationsWithTasks', [])
    },
  }
}
