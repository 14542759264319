import sidebarLinks from '@/router/sidebarRoutes'
import _i18n from '@/i18n/i18n'

const i18n = _i18n.global

export default store => {

  /* Параметр для дебага на localhost.
   TODO поменять на 0 перед code review */
  const externalDebugModeIndex = 0
  const externalDebugModes = ['', 'uxuidesigner', 'designer', 'developer', 'dev-uxuidesigner', 'dev-designer', 'dev-developer']
  const externalDebugMode = externalDebugModes[externalDebugModeIndex]

  const hostname = process.env.NODE_ENV === 'development' ? externalDebugMode : location.hostname.split('.')[0]
  store.commit('setHostName', hostname)
  const isDesigner = !!hostname.match(/^([a-z]+-)*designer$/)
  const isUxuiDesigner = !!hostname.match(/^([a-z]+-)*uxuidesigner$/)
  const isDeveloper = hostname.includes('developer')

  const hasExternalLayout = isDesigner || isUxuiDesigner || isDeveloper

  const beforeEnter = (to, from, next) => {
    const locale = to.params.locale
    if (locale && locale !== i18n.locale) {
      i18n.locale = locale
    }
    const currentUser = store.getters.user
    if (currentUser) {
      return next('/todo/list')
    }
    return next()
  }

  const beforeEmailLayout = (to, from, next) => {
    if (from.path !== '/settings/email-layouts') return next('/settings/email-layouts')

    return next()
  }

  return [
    {
      name: 'main',
      path: '/',
      redirect: `/${store ? store.getters.locale : 'ru'}/login`,
    },
    {
      name: 'SSO redirect',
      path: '/sso-redirect',
      meta: { auth: false },
      component: () => import('@/components/Pages/UserData/SsoRedirect'),
    },
    {
      name: 'login',
      path: '/login',
      redirect: hasExternalLayout ? `/${store ? store.getters.locale : 'ru'}/login` : undefined,
      meta: { layout: 'login', auth: false },
      component: () => import('@/components/Pages/UserData/Login'),
    },
    {
      name: 'external_login',
      path: '/:locale/login',
      redirect: hasExternalLayout ? undefined : '/login',
      meta: { layout: 'external', auth: false },
      component: () => import('@/components/Pages/UserData/common/external/LoginContent'),
      beforeEnter: beforeEnter
    },
    {
      name: 'external_register',
      path: '/:locale/register',
      redirect: hasExternalLayout ? undefined : '/register',
      meta: { layout: 'external', auth: false },
      component: () => import('@/components/Pages/UserData/common/external/RegisterContent'),
      beforeEnter: beforeEnter
    },
    {
      name: 'access-denied',
      path: '/access-denied',
      meta: { layout: 'login', auth: false },
      component: () => import('src/components/Pages/GeneralViews/AccessDenied')
    },
    {
      name: 'Layout email',
      path: '/settings/email-layout',
      meta: { layout: 'main', auth: true },
      component: () => import('@/components/Pages/Settings/EmailLayouts/EmailLayoutPage'),
      beforeEnter: beforeEmailLayout
    },
    {
      name: 'Localization email',
      icon: 'nc-icon nc-settings',
      path: '/settings/localizations-email/:id',
      meta: { layout: 'main', auth: true },
      component: () => import('@/components/Pages/Settings/EmailLocalizations/EmailLocalizationPage'),
      props: true,
    },
    {
      name: 'App files downloading',
      path: '/application/share',
      meta: { layout: 'download', auth: true },
      component: () => import('src/components/Pages/GeneralViews/AppDownloadingPage')
    },
    {
      name: 'App files public downloading',
      path: '/application/public-share',
      meta: { layout: 'download' },
      component: () => import('src/components/Pages/GeneralViews/AppDownloadingPage')
    },
    ...sidebarLinks,
    {
      name: 'Not found',
      path: "/:pathMatch(.*)*",
      component: () => import('src/components/Pages/GeneralViews/NotFoundPage')
    }
  ]
}
