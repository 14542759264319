import { createStore } from 'vuex'
import '../axios'
import auth from './auth'
import applications from './applications'
import userData from './userData'
import tasks from './tasks'
import users from './users'
import dashboard from './dashboard'
import accruals from './accruals'
import taskOptions from './taskOptions'
import notifications from './notifications'
import settings from '@/store/settings'
import loadingFiles from '@/store/loadingFiles'
import intercom from '@/store/intercom'
import aso_manager from '@/store/aso_manager'
import executors from '@/store/executors'
import programmer from '@/store/programmer'
import creator from '@/store/creator'
import selected from '@/store/selected'
import abortControllers from '@/store/abortControllers'
import app_manager from '@/store/app_manager'
import superadministrator from '@/store/superadministrator'
import stats from '@/store/stats'
import taskComments from '@/store/taskComments'
import modalWindow from '@/store/modalWindow'
import tables from '@/store/tables'


const _createStore = () => createStore({
  state: {
    types: null,
    locale: 'ru',
    titleText: 'Get Work',
    titleBadgeText: undefined,
    scrolledDown: null,
    hostName: '',
    complexAgeRestrictions: undefined,
    showSidebar: false
  },
  getters: {
    types: state => state.types,
    differentCountries: state => {
      return state.types && state.types.countries ? state.types.countries.filter(item => item.name !== 'all') : []
    },
    appStatuses: state => state.types && state.types.app_status ? state.types.app_status : [],
    locale: state => state.locale,
    titleText: state => state.titleText,
    titleBadgeText: state => state.titleBadgeText,
    scrolledDown: state => state.scrolledDown,
    hostName: state => state.hostName.split('-').at(-1),
    complexAgeRestrictions: state => state.complexAgeRestrictions,
    showSidebar: state => state.showSidebar,
  },
  mutations: {
    setTypes(state, types) {
      const _types = {
        ...types,
        countries: types.countries.filter(item => !['all', 'xa', 'xb'].includes(item.name))
      }
      localStorage.setItem('types', JSON.stringify(_types))
      state.types = _types
    },
    setUILocale(state, value) {
      state.locale = value
    },
    setTitleText(state, text) {
      state.titleText = text
    },
    setTitleBadgeText(state, text) {
      state.titleBadgeText = text
    },
    setScrolledDown(state, value) {
      state.scrolledDown = value
    },
    setHostName(state, value) {
      state.hostName = value
    },
    setComplexAgeRestrictions(state, value) {
      localStorage.setItem('complexAgeRestrictions', JSON.stringify(value))
      state.complexAgeRestrictions = value
    },
    setShowSidebar(state, value) {
      state.showSidebar = value
    }
  },
  actions: {
    async getTypes({ commit, dispatch }) {
      const localTypes = localStorage.getItem('types')
      if (localTypes) {
        commit('setTypes', JSON.parse(localTypes))
        dispatch('fillFilterOptions')
      }
      try {
        const getAllTypes = await axios.get('type')
        const getAppConsoleStatusType = await axiosV3.get('types?filter[type]=application-console-statuses')
        const [allTypes, appConsoleStatusType] = await Promise.allSettled([getAllTypes, getAppConsoleStatusType])
        //TODO удалить после отключения брендов на фронте и подключения поля "Запрос"
        let result = {...allTypes.value.data.response, application_console_status: appConsoleStatusType.value.data}
        result.brand = [
          {
            name: 'test',
            id: 1,
            title: 'Test'
          }
        ]
        result.currencyExchange = Number(result.currency.find(currency => currency.code.toLowerCase() === 'usd').value)
        commit('setTypes', result)
        commit('superadministrator/setPassportVerifyStatus', allTypes.value.data.response['need_upload_passport'])
        dispatch('fillFilterOptions')
      } catch (error) {
        console.info(error)
        commit('superadministrator/setPassportVerifyStatus', false)
      }
    },
    setScrolledDown({ state, commit }, value) {
      if (state.scrolledDown === value) return
      commit('setScrolledDown', value)
    },

    async downloadComplexAgeRestrictions({ commit }) {
      const localData = localStorage.getItem('complexAgeRestrictions')
      if (localData) {
        commit('setComplexAgeRestrictions', JSON.parse(localData))
      }
      try {
        const response = await axios('restrictions/tree')
        const result = response.data.response
        commit('setComplexAgeRestrictions', result)
      } catch (e) {
        console.log(e)
      }
    }
  },
  modules: {
    auth,
    applications,
    userData,
    tasks,
    users,
    dashboard,
    accruals,
    taskOptions,
    notifications,
    settings,
    loadingFiles,
    intercom,
    aso_manager,
    executors,
    programmer,
    creator,
    selected,
    abortControllers,
    app_manager,
    superadministrator,
    stats,
    taskComments,
    modalWindow,
    tables
  }
})

const store = _createStore()

export default store
