export default {
  state: {
    abortControllers: {}
  },
  getters: {
    abortControllers: state => state.abortControllers
  },
  mutations: {
    setAbortController(state, payload) {
      const { controller, name } = payload
      state.abortControllers[name] = controller
    },
    deleteAbortController(state, name) {
      if (!state.abortControllers[name]) return
      delete state.abortControllers[name]
    }
  },
  actions: {
    cancelRequests({ state, commit }, names = null) {
      for (const [name, controller] of Object.entries(state.abortControllers)) {
        if (!names || names.includes(name)) {
          controller.abort()
          commit('deleteAbortController', name)
        }
      }
    }
  }
}
