import { defineAsyncComponent } from 'vue'


export const formTooltipSettings = [
  {
    fieldName: 'Application ID',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/AppVersionTooltip')),
    icon: 'fa fa-info'
  },
  {
    fieldName: 'Application ID Unity',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/AppVersionTooltip')),
    icon: 'fa fa-info'
  },
  {
    fieldName: 'Application ID Unity Export',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/AppVersionTooltip')),
    icon: 'fa fa-info'
  },
  {
    fieldName: 'Application ID WebGL',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/AppVersionTooltip')),
    icon: 'fa fa-info'
  },
  {
    fieldName: 'Название приложения',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/AppNameTooltip')),
    icon: 'fa fa-info'
  },
  {
    fieldName: 'Название приложения Unity',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/AppNameTooltip')),
    icon: 'fa fa-info'
  },
  {
    fieldName: 'Название приложения Unity Export',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/AppNameTooltip')),
    icon: 'fa fa-info'
  },
  {
    fieldName: 'Название приложения WebGL',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/AppNameTooltip')),
    icon: 'fa fa-info'
  },
  {
    fieldName: 'Название на рабочем столе',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/AppDesktopNameTooltip')),
    icon: 'fa fa-info'
  },
  {
    fieldName: 'ID товара',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/ProductIdTooltip')),
    icon: 'fa fa-info',
    placement: 'top',
  },
  {
    fieldName: 'Реферальный код',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/ReferralCodeTooltip')),
    icon: 'fa fa-info',
    placement: 'top',
  },
  {
    fieldName: 'Промокод',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/PromoCodeTooltip')),
    icon: 'fa fa-info',
    placement: 'top',
  },
  {
    fieldName: 'Велком бонус',
    content: defineAsyncComponent(() => import('@/components/UIComponents/Tooltip/WelcomeBonusTooltip')),
    icon: 'fa fa-info',
    placement: 'top',
  },
]
