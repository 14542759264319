import {
  checkAssignToUser,
  checkNotificationsAndSetBadge,
  compareFormData,
  preparePublishTaskData,
  prepareUpdateTaskData,
  transformChanges,
  sortHistory
} from '@/utils/helpers'
import { ASO_MAN_UPDATE_TASK_TYPES, ROUTE_FROM_APP_MAN_UPDATE_TYPE_MAPPER } from '@/utils/constants'
import moment from 'moment'
import FormDataV3 from '@/utils/formDataApiV3'
import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3"
import store from '@/store'

export default {
  /* TODO API v3: сделать данный метод только для верификации
     TODO API v3: вынести общие части с методом downloadModerationTaskList */
  async downloadTaskList({commit, rootGetters}, payload) {
    const {taskType, page, requestArgs, search} = payload
    const isVerifications = taskType === 'verifications'
    //TODO подключить к модерации и верификации новый тулбар и отрефакторить данный метод
    const rootSearch = rootGetters['listSearch']
    const rootFilters = rootGetters['selectedFilters']
    const filterCheckbox = rootGetters['filterCheckbox']
    const ABORT_KEY = 'taskList'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', {controller, name: ABORT_KEY}, {root: true})
    const userFilters = rootFilters ? `&${rootFilters}` : ''
    const urlBuilder = new UrlBuilderApiV3()
    urlBuilder.setEndpoint('subtasks')
    const getVerifyUrl = () => {
      const defaultStatusFilters = [
        'filter[status][name]=published,verified,failed_verification',
      ]
      const statusFilters = requestArgs ? requestArgs : defaultStatusFilters
      const defaultFilters = [
        'filter[type]=manager_verification',
        statusFilters,
      ]
      if (search) defaultFilters.push(`filter[related_uploader_verification][user][id]=${search}`)
      const filters = defaultFilters.join('&')
      const includeParams = [
        'user',
        'task',
        'status',
        'related_uploader_verification.user',
        'related_uploader_verification.verification'
      ]
      return urlBuilder.addFilters(filters)
        .addInclude(includeParams)
        .setPage(page)
        .build()
    }
    const getTasksUrl = () => {
      const filters = [
        'filter[type]=app_manager,app_manager_update',
        'filter[status][not_name]=waiting'
      ]
      if (rootSearch) filters.push(`filter[search2]=${rootSearch}`)
      if (userFilters.length) filters.push(userFilters)
      if (requestArgs) filters.push(requestArgs)
      if (filterCheckbox) filters.push(filterCheckbox)
      const filtersString = filters.join('&')
      const includeParams = [
        'task.uploader.moderation',
        'task.uploader_update.moderation',
        'task.uploader.user',
        'task.uploader_update.user',
        'task.uploader.status',
        'comments',
        'task.uploader',
        'task.uploader_update',
        'task.type.parent',
        'task.purchases',
        'application.quality_type',
        'application.tag',
        'application.category',
        'application.type',
        'status'
      ]
      return urlBuilder.addFilters(filtersString)
        .addInclude(includeParams)
        .addAggregate('total_types')
        .setPage(page)
        .build()
    }

    try {
      const url = isVerifications ? getVerifyUrl() : getTasksUrl()
      let response = await axiosV3.get(url, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
      let taskList = isVerifications ? response.data : response.data
      const metaData = isVerifications ? response.meta : response.meta
      if (isVerifications) {
        taskList = checkNotificationsAndSetBadge(
          taskList,
          rootGetters.notificationList,
          'verification'
        )
      }
      commit('setTaskList', taskList)
      commit('setTaskListPageCount', metaData.page.last_page)
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Task list error')
    }
  },

  async takeTask(ctx, id) {
    try {
      await axios.put(`processing/tasks/${id}`)
      this.$app.$notifySuccess('Task in progress')
    } catch (e) {
      console.info(e)
      this.$app.$notifyError(e)
      throw (e)
    }
  },

  async deleteTask({rootGetters}) {
    if (!rootGetters.selectedMainTask || !rootGetters.selectedMainTask.id) return
    const taskId = rootGetters.selectedMainTask.id
    try {
      await axios.delete(`v2/task/delete/${taskId}`)
      this.$app.$notifySuccess('Task deleted')
    } catch (e) {
      console.info(e)
      this.$app.$notifyError(e)
    }
  },

  async createUpdateTask(ctx, model) {
    try {
      const data = prepareUpdateTaskData(model)
      const urlUpdateType = ROUTE_FROM_APP_MAN_UPDATE_TYPE_MAPPER[model.updateType]
      if (!urlUpdateType) return
      await axios.post(`app_manager/upload/${urlUpdateType}/application`, data)
      this.$app.$notifySuccess('Task added')
      return true
    } catch (e) {
      console.log(e)
    }
  },

  async editUpdateTask({rootGetters}, payload) {
    let url
    const {model, appId, returnFailedTask, uploaderTaskId} = payload
    const mainTaskId = rootGetters.selectedMainTask.id
    const initModel = {
      ...rootGetters.selectedMainTask.application,
      ...rootGetters.selectedMainTask.uploader,
      ...rootGetters.selectedMainTask.purchases[0]
    }
    const initData = prepareUpdateTaskData(initModel)
    const _data = prepareUpdateTaskData(model)
    const data = compareFormData(initData, _data, 'update')
    if (!data && !returnFailedTask) return false

    const appManTaskId = rootGetters.selectedTask.id
    const taskFromAsoMan = ASO_MAN_UPDATE_TASK_TYPES.includes(model.updateType)
    const urlUpdateType = ROUTE_FROM_APP_MAN_UPDATE_TYPE_MAPPER[model.updateType]
    url = taskFromAsoMan ? `app_manager/update/tasks/${appManTaskId}` : `app_manager/upload/${urlUpdateType}/application/${appId}`
    try {
      if (data) {
        data.set('task_id', mainTaskId)
        data.delete('application[id]')
        await axios.put(url, data)
      }
      if (returnFailedTask) {
        rootGetters.selectedTask.status.name === 'purchase_moderation_rejected'
          ? await axios.put(`return-to-purchase-moderation/tasks/${uploaderTaskId}`)
          : await axios.put(`return/tasks/${uploaderTaskId}`)
      }
      this.$app.$notifySuccess('Task updated')
      return true
    } catch (e) {
      console.info(e)
      if (e.accessRightsError) {
        this.$app.$notifyError('App manager rights error')
      } else {
        this.$app.$notifyError(e)
      }
    }
  },

  async editPublishTask({dispatch, rootGetters}, payload) {
    const {
      type,
      model,
      mainTaskId,
      appId,
      failedTaskUploader,
      failedVerifUploader,
      failedModerationUploader,
      uploaderTaskId,
      returnTaskToAll,
      paused,
      confirmationTaskUploader
    } = payload
    let initModel = {
      ...rootGetters.selectedApplication,
      ...rootGetters.selectedMainTask.uploader
    }
    const isNoDevelopApp = type === 'no_develop'
    const initData = preparePublishTaskData(initModel)
    const _data = preparePublishTaskData(model)
    let data = compareFormData(initData, _data, isNoDevelopApp ? 'no_develop' : 'publish')
    const assignToUser = checkAssignToUser(initModel, model)
    const updateTaskAfterReturn = failedTaskUploader === 'other'
    const url = `app_manager/upload/build/${isNoDevelopApp ? 'no-develop/' : ''}application/${appId}`
    const tryRestoreTask = async fieldName => {
      switch (fieldName) {
        case 'current':
          await axios.put(`restore/tasks/${uploaderTaskId}`)
          break
        case 'other':
          await axios.put(`restore-to-all/tasks/${uploaderTaskId}`)
          break
      }
    }

    if (paused !== undefined) {
      if (data) {
        data.append('paused', Number(paused))
      } else {
        data = new FormData()
        data.append('paused', Number(paused))
      }
      data.set('name', rootGetters.selectedApplication.name)
    }

    if (!data && !failedTaskUploader && !failedVerifUploader && !returnTaskToAll && !confirmationTaskUploader && !assignToUser && !failedModerationUploader) return false
    try {

      // В большинстве случаев нужно вначале обновить данные, затем при необходимости вернуть задачу
      if (data && !updateTaskAfterReturn) {
        data.append('task_id', mainTaskId)
        await axios.put(url, data)
      }
      switch (failedTaskUploader) {
        case 'current':
          await axios.put(`return/tasks/${uploaderTaskId}`)
          break
        case 'other':

          // В этом случае вначале нужно вначале вернуть задачу, а потом обновлять данные
          await axios.put(`return-to-all/tasks/${uploaderTaskId}`)
          if (data && updateTaskAfterReturn) {
            data.append('task_id', mainTaskId)
            await axios.put(url, data)
          }
          break
      }

      if (failedModerationUploader) {
        await axios.put(`return/tasks/${uploaderTaskId}`)
      }

      await tryRestoreTask(failedVerifUploader)
      await tryRestoreTask(confirmationTaskUploader)
      if (returnTaskToAll) {
        await axios.put(`return-to-all/tasks/${uploaderTaskId}`)
      }
      await dispatch('assignTaskToUser', {assignToUser, uploaderTaskId})
      this.$app.$notifySuccess('Task updated')
      return true
    } catch (e) {
      console.info(e)
      if (e.accessRightsError) {
        this.$app.$notifyError('App manager rights error')
      } else {
        this.$app.$notifyError(e)
      }
    }
  },

  async assignTaskToUser(ctx, payload) {
    const {assignToUser, uploaderTaskId} = payload
    if (assignToUser) {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
        const userData = new URLSearchParams()
        userData.set('user_id', assignToUser)
        await axios.put(`assignment_waiting/tasks/${uploaderTaskId}`, userData, config)
      } catch (e) {
        console.info(e)
        this.$app.$notifyError('Не удалось назначить задачу пользователю')
      }
    }
  },

  async addTaskCreatedByCreator({dispatch}, payload) {
    try {
      const {model, managerTaskId, paused} = payload
      const data = preparePublishTaskData(model)
      data.append('paused', Number(paused ?? false))
      const result = await axios.put(`completion/tasks/${managerTaskId}`, data)
      const uploaderTaskId = result.data.response.task.uploader.id
      const assignToUser = model.user_id
      await dispatch('assignTaskToUser', {assignToUser, uploaderTaskId})
      return true
    } catch (e) {
      console.info(e)
      this.$app.$notifyError(e)
    }
  },

  async addTaskOfUpdatingCreatedByAsoMan(ctx, payload) {
    try {
      const {model, managerTaskId} = payload
      const data = prepareUpdateTaskData(model)
      await axios.put(`completion/tasks/${managerTaskId}`, data)
      return true
    } catch (e) {
      console.info(e)
      this.$app.$notifyError(e)
    }
  },

  async getApplications({rootGetters, commit}, payload) {
    const {requestArgs, page, search} = payload
    const ABORT_KEY = 'appList'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', {controller, name: ABORT_KEY}, {root: true})
    const rootSearch = rootGetters['listSearch'] || search
    const filters = requestArgs + rootGetters['selectedFilters']
    const rootFilters = filters ? filters : ''
    const searchStr = rootSearch ? rootSearch : ''
    const forUpdate = !!filters && filters.includes('can_update')
    let urlBuilder = new UrlBuilderApiV3()
    const includes = [
      'status',
      'marathon',
      'sharing_google_account'
    ]
    urlBuilder.setEndpoint('applications')
    if (!forUpdate) {
      urlBuilder.addInclude(includes)
    } else {
      urlBuilder.addInclude('default_locale', 'locales')
    }
    if (filters) urlBuilder.addFilters(rootFilters)
    if (searchStr) urlBuilder.setSearch(searchStr)
    const url = urlBuilder.setPage(page).build()
    try {
      const response = await axiosV3.get(url, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const applicationsList = response.data
      const metaData = response.meta
      if (forUpdate) {
        commit('setApplicationsForUpdate', applicationsList)
      } else {
        commit('setApplications', applicationsList)
        commit('setApplicationsListPageCount', metaData.page.last_page)
      }
      commit('deleteAbortController', ABORT_KEY, {root: true})
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('При загрузке списка приложений произошла ошибка. Попробуйте позже.')
    }
  },

  async downloadUserMatches({commit, rootGetters}) {
    const userID = rootGetters.selectedUser.id
    const ABORT_KEY = 'userMatches'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', {controller, name: ABORT_KEY}, {root: true})
    try {
      const response = await axios.get(`v2/admin/fingerprint/matches/${userID}`, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const matchedUsers = response.data.response
      commit('deleteAbortController', ABORT_KEY, {root: true})
      return {
        name: 'Совпадения по цифровому отпечатку браузера',
        data: matchedUsers,
        showDuplicates: true
      }
    } catch (e) {
      console.log(e)
    }
  },

  async confirmVerification({rootGetters}) {
    try {
      const taskId = rootGetters.selectedTask.task.uploader.id
      const response = await axios.put(`verified/tasks/${taskId}`)
      return response.data.success
    } catch (e) {
      console.info(e)
      this.$app.$notifyError(e)
    }
  },

  async rejectVerification({rootGetters}) {
    try {
      const taskId = rootGetters.selectedTask.task.uploader.id
      const response = await axios.put(`verification/failure/tasks/${taskId}`)
      return response.data.success
    } catch (e) {
      console.info(e)
      this.$app.$notifyError(e)
    }
  },

  async confirmDeletionReason({rootGetters}, payload) {
    const {reason, isEdit} = payload
    const reasonId = rootGetters.selectedDeletionReason.id
    const data = new FormDataV3()
    data.addParam('id', reasonId)
    data.addParam('type', 'deletion-reasons')
    data.addRelation('kind', {data: {id: String(payload.model.type_id), type: 'deletion-reason-types'}})
    data.addRelation('user_console_status', {
      data: {
        id: String(payload.model.user_console_status_id),
        type: 'user-console-statuses'
      }
    })
    data.addRelation('application_console_status', {
      data: {
        id: payload.model.application_console_status,
        type: 'application-console-statuses'
      }
    })
    data.addMeta('allow_next_publication', payload.model.allow_next_publication)
    if (reason || isEdit) data.addAttr('reason', reason)
    const confirDeletionUrl = new UrlBuilderApiV3().setEndpoint(`deletion-reasons/${reasonId}`).build()
    try {
      const response = await axiosV3.patch(confirDeletionUrl, data)
      return response.data
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось сохранить данные. Попробуйте позже.')
    }
  },

  async getDeletedAppsList({commit, rootGetters}, payload) {
    const {page, search, requestArgs} = payload
    const ABORT_KEY = 'deletedApps'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', {controller, name: ABORT_KEY}, {root: true})
    const customFilters = []
    if (search) customFilters.push(`filter[search1]=${search}`)
    if (requestArgs) customFilters.push(requestArgs)
    const urlBuilder = new UrlBuilderApiV3().setEndpoint('deletion-reasons')
    if (customFilters.length) urlBuilder.addFilters(customFilters.join('&'))
    const deletionReasonList = urlBuilder.addInclude('application', 'user')
      .setPage(page)
      .build()
    try {
      const response = await axiosV3.get(deletionReasonList, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
      const appList = response.data
      const lastPage = response.meta.page.last_page
      commit('setDeletedAppsList', appList)
      commit('setDeletedAppsPageCount', lastPage)
      commit('deleteAbortController', ABORT_KEY, {root: true})
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Не удалось обновить список приложений. Попробуйте позже.')
    }
  },

  clearDeletedAppsList({commit}) {
    commit('setDeletedAppsList', null)
    commit('setDeletedAppsPageCount', null)
  },

  async getDeletionReasonCardData({commit}, payload) {
    const {reasonId, userId} = payload
    try {
      const deletionUrl = new UrlBuilderApiV3()
        .setEndpoint(`deletion-reasons/${reasonId}`)
        .addInclude('application', 'user', 'kind', 'subtask', 'user_console_status', 'application_console_status')
        .build()
      const totalAppsFilters = [
        `filter[user][id]=${userId}`,
        'filter[status][name]=completed'
      ].join('&')
      const totalAppsUrl = new UrlBuilderApiV3()
        .setEndpoint('subtasks')
        .addFilters(totalAppsFilters)
        .addAggregate('total_types')
        .addFields('fields[subtasks]=title')
        .setPage(1, 1)
        .build()
      const deletionReason = axiosV3.get(deletionUrl)
      const totalApps = axiosV3.get(totalAppsUrl)
      const [reason, total] = await Promise.all([deletionReason, totalApps]);
      const data = reason.data
      const countApps = total.data.meta?.total_types?.uploader || 0

      commit('setSelectedDeletionReason', data, {root: true})
      commit('setTotalAppComplete', countApps, {root: true})
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось получить данные о причине удаления. Попробуйте позже.')
    }
  },

  async restartAllAppsMarathon() {
    //TODO выпилить, более не используется
    try {
      const response = await axios.post('app_manager/restart-all-apps-marathon/applications')
      const message = response.data.response.message
      if (message) {
        this.$app.$notifySuccess(message)
      }
      return true
    } catch (e) {
      console.info(e)
      this.$app.$notifyError('При обновлении приложений произошла ошибка. Попробуйте позже.')
    }
  },

  async checkInStore({commit}, application) {
    const appId = application.id
    const url = new UrlBuilderApiV3()
      .setEndpoint(`applications/${appId}/check-in-store`)
      .addInclude('status', 'marathon')
      .build()
    try {
      const result = await axiosV3.post(url)
      const newValue = result.data
      commit('changeApplication', {
        application,
        newValue,
      })
      this.$app.$notifySuccess('Информация о приложении обновлена')
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('При обновлении приложения произошла ошибка. Попробуйте позже.')
    }
  },

  clearApplications({commit}) {
    commit('setApplications', [])
    commit('setApplicationsListPageCount', null)
  },

  async downloadVerificationData({commit}, id) {
    try {
      const response = await axios.get(`v2/app_manager/verifications/${id}`)
      commit('setSelectedVerification', response.data.response, {root: true})
      return true
    } catch (e) {
      console.info(e)
    }
  },

  async uploadVerificationData(ctx, payload) {
    let data
    const {id, type, comment} = payload
    const url = `v2/app_manager/verifications/${id}/${type}`
    if (comment !== undefined) {
      data = new FormData()
      data.set('comment', comment)
    }
    try {
      await axios.post(url, data)
      const msg = {
        approve: 'Верификация подтверждена',
        reject: 'Верификация отклонена',
        comment: 'Новый комментарий сохранен'
      }[type]
      this.$app.$notifySuccess(msg)
      return true
    } catch (e) {
      console.info(e)
      this.$app.$notifyError('При сохранении произошла ошибка. Попробуйте позже.')
      return false
    }
  },

  async downloadSimpleTaskList({commit, rootGetters}, payload) {
    const {page, url, role} = payload
    const userId = rootGetters.user.id
    const moderationTaskList = ['manager-simple', 'manager-simple-gmail'].includes(url)
    const isCreAso = ['creator', 'aso_manager'].includes(role)
    const search = rootGetters.listSearch
    const filters = rootGetters.selectedFilters
    const filterCheckbox = rootGetters.filterCheckbox
    const ABORT_KEY = 'simpleTaskList'
    const controller = new AbortController()
    const tagTask = role === 'superadministrator' ? 'gmail' : 'testimonial'
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    let urlBuilder = new UrlBuilderApiV3()
    let defaultFilters = [
      `filter[simple][tag]=${tagTask}`,
      'filter[type]=simple'
    ].join('&')
    if (filters) defaultFilters += filters
    if (filterCheckbox) defaultFilters += filterCheckbox
    if (search) defaultFilters += `&filter[search1]=${search}`
    let inclusions = [
      'parent_simple',
      'related_manager_simple',
      'status',
      'related_user_simple.google_account.numbers',
      'comments'
    ].join(',')
    if (isCreAso || moderationTaskList) {
      const searchType = Number(search) ? 'id' : 'title'
      let filterTags;
      if (moderationTaskList) {
        filterTags = url === 'manager-simple-gmail' ? 'gmail' : 'testimonial'
      } else {
        filterTags = [
          'designer',
          'programmer',
          'uidesigner'
        ].join(',')
      }
      defaultFilters = moderationTaskList
        ? [
          `filter[simple][tag]=${filterTags}`,
          search ? `filter[search3]=${search}` : ''
        ].join('&')
        : [
          `filter[simple][tag]=${filterTags}`,
          `filter[created_by]=${userId}`,
          search ? `filter[${searchType}]=${search}` : ''
        ].join('&')
      if (moderationTaskList) defaultFilters += `filter[type]=manager_simple${filters}`
      if (filters && !moderationTaskList) {
        const typeFilter = filters === 'published' ? '[has_user_task]=0' : `[user_task_status]=${filters}`
        defaultFilters += `&filter[simple]${typeFilter}`
      }
      inclusions = moderationTaskList
        ? [
          'parent_simple',
          'status',
          'related_user_simple.user',
          'related_user_simple.google_account.numbers',
          'comments'
        ].join(',')
        : [
          'status',
          'user'
        ].join(',')
      if (isCreAso) {
        const aggregates = [
          'simple_user_children',
          'simple_manager_children'
        ].join(',')
        urlBuilder.addAggregate(aggregates)
      }
      if (moderationTaskList) urlBuilder.addSorting('-publish_at')
    }
    let finalUrl = urlBuilder.setEndpoint('subtasks')
      .addInclude(inclusions)
      .addFilters(defaultFilters)
      .setPage(page, 15)
      .build()
    commit('setAbortController', {controller, name: ABORT_KEY}, {root: true})
    try {
      const response = await axiosV3.get(finalUrl, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const taskList = response.data
      const metaData = response.meta.page
      if (isCreAso) {
        for await (const task of taskList) {
          if (task.meta.simple_user_children?.length) {
            const response = await axiosV3.get(`/subtasks/${task.meta.simple_user_children[0]}?include=status,user`)
            task.status = response.data.status
            task.user = response.data.user
          }
        }
      }
      commit('setTaskList', taskList)
      commit('setTaskListPageCount', metaData.last_page)
      commit('deleteAbortController', ABORT_KEY, {root: true})
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      const titleErr = url === 'simple' ? 'Task list error' : 'Simple moderation list error'
      this.$app.$notifyError(titleErr)
    }
  },

  async downloadSimpleTaskStats(ctx, id) {
    try {
      const response = await axios.get(`tasks/simple/${id}/stats`)
      return response.data.response
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('При загрузке статистики произошла ошибка. Попробуйте позже.')
    }
  },

  //TODO API v3: рефакторинг после перехода на api v3
  async downloadSimpleTask({commit, dispatch}, payload) {
    const {id, limit, creAso} = payload
    try {
      const response = creAso
        ? await axiosV3.get(`subtasks/${id}?include=status,task,user,moderation,comments,comments.user`)
        : await axios.get(`tasks/simple/${id}`)
      const selectSimpleTask = creAso ? response.data : response.data.response
      if (limit > 1) {
        const stats = await dispatch('downloadSimpleTaskStats', id)
        selectSimpleTask.failed = stats.failed
      }
      commit('setSelectedTask', selectSimpleTask, {root: true})
      store.commit('setSelectedUser', selectSimpleTask.user)
      return selectSimpleTask
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Simple task error')
    }
  },
  async uploadSimpleTask({commit, rootGetters}, payload) {
    const {model, action, role} = payload
    const isCreateTask = action === 'new'
    const isCreAso = ['designer', 'programmer', 'uidesigner'].includes(model.role_executor.name)
    const selectedTask = rootGetters.selectedTask
    let taskId = isCreateTask ? '' : `/${selectedTask.id}`
    if (selectedTask?.type === 'user_simple') {
      const targetTask = rootGetters['app_manager/taskList'].find(task => {
        return task.meta.simple_user_children?.[0] === Number(selectedTask.id)
      })
      taskId = `/${targetTask.id}`
    }
    const typeReq = isCreateTask ? 'post' : 'put'
    const data = new FormData()
    const getSetter = (key, value) => {
      const setDefault = () => {
        if (!value && isCreateTask) return
        data.set(key, value)
      }
      const setDate = () => {
        if (!value) return
        data.set(key, moment(value).format('YYYY-MM-DD'))
      }
      const setArray = () => {
        value.map((item, index) => {
          data.set(`${key}[${index}]`, item)
        })
      }
      const setBoolean = () => {
        if (value === undefined || (key === 'only_verified_users' && model.user_id)) return
        data.set(key, `${Number(value)}`)
      }
      const setResult = () => {
        if (role === 'superadministrator') {
          data.set('result[google_account]', 1)
          return
        }
        ['link', 'file', 'image'].map(item => {
          data.set(`result[${item}]`, `${Number(value.includes(item))}`)
        })
      }
      const mapper = {
        publish_at: setDate,
        starts_at: setDate,
        ends_at: setDate,
        task_country_show: setArray,
        result: setResult,
        remove_after_fail: setBoolean,
        pause: setBoolean,
        only_verified_users: setBoolean,
        is_internal: setBoolean
      }
      return mapper[key] || setDefault
    }
    for (const [key, value] of Object.entries(model)) {
      if (key === 'role_executor') continue
      getSetter(key, value)()
    }
    if (!model.user_id && isCreateTask) {
      data.set('role_ids[0]', model.role_executor.id)
    }
    if (role === 'superadministrator' || isCreAso) {
      data.set('tag', isCreAso ? model.role_executor.name : 'gmail')
    }
    try {
      const {data: {response}} = await axios[typeReq](`tasks/simple${taskId}`, data)
      commit('updateSelectedTask', response, {root: true})
      if (!isCreateTask) this.$app.$notifySuccess('Задача успешно обновлена')
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError(`Upload ${action} simple task error`)
    }
  },
  async changeModeration({dispatch}, payload) {
    const {type, taskId, comment, status, userSimpleTaskId} = payload
    let isStartProcess
    if (type) isStartProcess = await dispatch('startProcess', {taskId, status})
    if (!isStartProcess && type) return
    const data = new FormData()
    const typeRoute = type === 'failed' ? 'user-simple' : 'manager-simple'
    const actualType = !type ? 'comment' : type
    const actionTaskId = type === 'failed' ? userSimpleTaskId : taskId
    if (comment) data.set('comment', comment)
    let urlBuilder = new UrlBuilderApiV3()
    const url = urlBuilder.setEndpoint(`tasks/${typeRoute}/${actionTaskId}/${actualType}`).build()
    try {
      await axios.post(url, data)
      const msg = {
        completed: 'Moderation confirmed',
        failed: 'Moderation failed',
        reject: 'Moderation rejected'
      }[type] || 'Moderation comment update'
      this.$app.$notifySuccess(msg)
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Moderation change status error')
    }
  },
  // TODO API v3: объеденить вместе с changeModeration после полного перехода на APi v3
  async rejectTask({dispatch}, payload) {
    const {type, taskId, comment, status} = payload
    let isStartProcess
    if (type) isStartProcess = await dispatch('startProcess', {taskId, status})
    if (!isStartProcess && type) return
    const data = new FormDataV3()
    data.addParam('id', String(taskId))
    data.addParam('type', 'subtasks')
    if (comment) data.addMeta('comment', comment)
    let urlBuilder = new UrlBuilderApiV3()
    const url = urlBuilder.setEndpoint(`/subtasks/${taskId}/revision`).build()
    try {
      await axiosV3.post(url, data)
      const msg = {
        completed: 'Moderation confirmed',
        failed: 'Moderation failed',
        reject: 'Moderation rejected'
      }[type] || 'Moderation comment update'
      this.$app.$notifySuccess(msg)
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Moderation change status error')
    }
  },
  async startProcess(ctx, payload) {
    const {taskId, status} = payload
    if (!taskId) {
      this.$app.$notifyError('Moderation change status error')
      return
    }
    if (status === 'process') return true
    let urlBuilder = new UrlBuilderApiV3()
    const url = urlBuilder.setEndpoint(`/subtasks/${taskId}/process`).build()
    try {
      await axiosV3.post(url)
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Moderation process error')
    }
  },
  async finishSimpleTask({rootGetters}, action) {
    const taskId = rootGetters.selectedTask.id
    try {
      await axios.post(`tasks/simple/${taskId}/${action}`)
      this.$app.$notifySuccess(`${action} simple task success`)
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError(`${action} simple task error`)
    }
  },
  async changeMarathonStatus({commit}, payload) {
    const {nextStatusMarathon, application} = payload
    const appId = application.id
    const url = new UrlBuilderApiV3()
      .setEndpoint(`applications/${appId}/toggle-marathon-status`)
      .addInclude('status', 'marathon', 'sharing_google_account')
      .build()
    try {
      const response = await axiosV3.post(url)
      this.$app.$notifySuccess(`Marathon successfully ${nextStatusMarathon}`)
      const newValue = response.data
      commit('changeApplication', {
        application,
        newValue
      })
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Marathon status error')
    }
  },
  async getHistoryChanges({ commit, state, rootGetters }, appId) {
    const ABORT_KEY = 'historyChanges'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    const nextPage = state.currentHistoryPage + 1
    try {
      let urlBuilder = new UrlBuilderApiV3()
      const appDataUrl = urlBuilder.setEndpoint('activity-logs')
        .addInclude('user', 'subject')
        .addFilters(`filter[parent][id]=${appId}`)
        .addSorting('-id')
        .setPage(nextPage, 100)
        .build()
      urlBuilder = new UrlBuilderApiV3()
      const otherChangeUrls = urlBuilder.setEndpoint('activity-logs')
        .addInclude('user')
        .addFilters(`filter[subject][id]=${appId}`)
        .addSorting('-id')
        .setPage(nextPage, 100)
        .build()
      const appData = axiosV3.get(appDataUrl, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
      const otherChanges = axiosV3.get(otherChangeUrls, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
      const [appInfo, otherHistory] = await Promise.all([appData, otherChanges]);
      commit('setCurrentHistoryPage', nextPage)
      if (!appInfo.data.length) return
      const availablePages = [appInfo.meta.page.last_page ?? 0, otherHistory.meta.page.last_page ?? 0]
      const filterOtherHistory = otherHistory.data.filter(item => {
        if (item.new?.apk_production_file && item.user) return item
        return false
      })
      let removeLocales = appInfo.included.filter(info => info.type === 'app-locales' && !!info.meta)
      if (removeLocales.length) {
        removeLocales = removeLocales.map(locale => {
          const copyAttr = locale.attributes
          return {
            new: copyAttr,
            event: 'deleted',
            created_at: locale.meta.deleted_at
          }
        })
      }
      const transformHistory = transformChanges([...appInfo.data, ...filterOtherHistory, ...removeLocales])
      commit('setHistoryChanges', transformHistory)
      commit('setTotalHistoryPages', Math.max(...availablePages))
      const otherHistoryIncluded = otherHistory.included ?? []
      commit('setHistoryReference', [...appInfo.included, ...otherHistoryIncluded])
      sortHistory('bottom')
      commit('deleteAbortController', ABORT_KEY, { root: true })
      return transformHistory.length
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('History changes error')
      return false
    }
  },
  async removeFailedTasks({commit}, id) {
    try {
      await axiosV3.delete(`/subtasks/${id}/failed-children`)
      commit('clearFailedSelectedTask', _, {root: true})
      this.$app.$notifySuccess('Проваленные задачи успешно удалены')
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось удалить проваленные задачи. Попробуйте позже.')
    }
  },

  async downloadModerationTaskList({commit, rootGetters}, payload) {
    const {page, requestArgs} = payload
    //TODO подключить к модерации новый тулбар и отрефакторить данный метод (данные поиска и фильтра получать через rootGetters)
    const ABORT_KEY = 'taskList'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', {controller, name: ABORT_KEY}, {root: true})
    const getUrl = () => {
      const reqArgs = requestArgs ? `${requestArgs}` : ''
      let inclusions = [
        'subtask',
        'subtask.task.subtasks',
        'subtask.task.type',
        'subtask.application',
        'subtask.user',
        'subtask.sharing_google_account'
      ].join(',')
      const urlBuilder = new UrlBuilderApiV3()
      urlBuilder.setEndpoint('moderations').addInclude(inclusions)
      if (reqArgs) urlBuilder.addFilters(reqArgs)
      return urlBuilder.setPage(page).build()
    }
    try {
      const response = await axiosV3.get(getUrl(), {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      /*TODO API v3: привести к общему виду список задач после полного перехода, чтобы было меньше преобразований
      данных с сервера*/
      let taskList = response.data.map(moderation => {
        return {
          ...moderation,
          taskModerationId: moderation.id,
          moderation: {
            title: moderation.title,
            status: moderation.status,
            completed_at: moderation.completed_at,
            screen: moderation.screen,
            upload_screen: moderation.upload_screen
          },
          id: moderation.subtask.task.subtasks.find(subtask => ['app_manager_update', 'app_manager'].includes(subtask.type)).id,
          title: moderation.subtask.task.subtasks.find(subtask => ['app_manager_update', 'app_manager'].includes(subtask.type)).title,
          user_id: moderation.subtask.user.id,
          version: moderation.subtask.application.version,
          type: moderation.subtask.type === 'uploader' ? 'upload' : 'update',
          task: {
            ...moderation.subtask.task,
            uploader: moderation.subtask.task.subtasks.find(subtask => ['uploader_update', 'uploader'].includes(subtask.type))
          }
        }
      })
      commit('setTaskList', taskList)
      commit('setTaskListPageCount', response.meta.page.last_page)
      commit('deleteAbortController', ABORT_KEY, {root: true})
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Task list error')
    }
  },
  async createRedirect({commit}, appId) {
    try {
      const result = await axiosV3.post(`/applications/${appId}/mobtracking`)
      const idRedirect = result.data.mobtracking_id
      commit('changeTaskList', {idRedirect, appId})
      this.$app.$notifySuccess('Редирект успешно создан')
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось создать редирект. Попробуйте позже.')
    }
  },
  async startBrowserSession(_, sharedId) {
    try {
      await axiosV3.post(`google-accounts/${sharedId}/impersonate`)
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось создать браузерную сессию. Попробуйте позже.')
    }
  }
}
