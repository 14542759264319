<template>
  <div class="external-footer">
    <div class="external-footer__content">
      <div class="external-footer__logo">
        <a :href="landingUrl" target="_blank" rel="noopener noreferrer">
          <img src="/static/img/getwork_logo/full_white.svg" alt="">
        </a>
      </div>
      <div class="external-footer__links">
        <div class="socnets">
          <a
            v-for="net in socnets"
            :key="net.name"
            :href="net.url"
            target="_blank"
            rel="noopener noreferrer">
            <img :src="`/static/img/icons/${net.name}_logo.svg`" alt="">
          </a>
        </div>
        <div class="copyright">
          <i class="fa fa-copyright"/>
          2022 GetWork
        </div>
      </div>
      <div class="external-footer__menu external-footer__review">
        <div class="menu-title">
          GetWork
        </div>
        <a :href="reviewsUrl" target="_blank" rel="noopener noreferrer">
          {{ $i18n.t('Reviews about us') }}
        </a>
      </div>
      <div class="external-footer__menu external-footer__docs">
        <div class="menu-title">
          {{ $i18n.t('Documentation') }}
        </div>
        <a :href="policyUrl" target="_blank" rel="noopener noreferrer">
          {{ $i18n.t('Privacy policy') }}
        </a>
      </div>
      <div class="external-footer__menu external-footer__faq">
        <div class="menu-title">
          {{ $i18n.t('Useful information') }}
        </div>
        <a :href="aboutUrl" target="_blank" rel="noopener noreferrer">
          {{ $i18n.t('About the project') }}
        </a>
        <a :href="faqUrl" target="_blank" rel="noopener noreferrer">
          FAQ
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExternalFooter',
  data: () => ({
    socnets: [
      {
        name: 'fb',
        url: 'https://www.facebook.com/getworksite'
      },
      {
        name: 'vk',
        url: 'https://vk.com/public210633084'
      },
      {
        name: 'tg',
        url: 'https://t.me/getwork_support'
      }
    ]
  }),
  computed: {
    landingUrl() {
      if (this.$i18n.locale === 'en') return 'https://get-work.app/main/en'
      return 'https://get-work.app/ru'
    },
    reviewsUrl() {
      return `https://get-work.app/${this.$i18n.locale}/reviews`
    },
    policyUrl() {
      return `https://get-work.app/${this.$i18n.locale}/policy`
    },
    aboutUrl() {
      return `https://get-work.app/${this.$i18n.locale}/faq#!/tab/412622613-1`
    },
    faqUrl() {
      return `https://get-work.app/${this.$i18n.locale}/faq#!/tab/412622613-2`
    }
  }
}
</script>

<style scoped>

</style>
