export default {
  state: {
    selectedApplication: undefined,
    selectedTask: undefined,
    selectedTaskPreview: undefined,
    selectedMainTask: undefined,
    selectedTaskExpired: false,
    selectedAttractionCode: undefined,
    selectedUser: undefined,
    selectedVerification: undefined,
    selectedDeletionReason: undefined,
    totalAppComplete: undefined
  },

  getters: {
    selectedApplication: state => state.selectedApplication,
    selectedTask: state => state.selectedTask,
    selectedTaskPreview: state => state.selectedTaskPreview,
    // TODO: Проверить, почему не используется - либо починить, либо выпилить
    selectedTaskExpired: state => state.selectedTaskExpired,
    selectedTaskStatus: state => {
      if (!state.selectedTask) return null
      return state.selectedTask.status
    },
    selectedMainTask: state => state.selectedMainTask,
    selectedAttractionCode: state => state.selectedAttractionCode,
    selectedUser: state => state.selectedUser,
    selectedVerification: state => state.selectedVerification,
    selectedDeletionReason: state => state.selectedDeletionReason,
    totalAppComplete: state => state.totalAppComplete,
  },

  mutations: {
    setSelectedApplication(state, value) {
      state.selectedApplication = value
    },
    updateSelectedApplication(state, value) {
      state.selectedApplication = {
        ...state.selectedApplication,
        ...value
      }
    },
    setSelectedTask(state, value) {
      state.selectedTask = value
    },
    updateSelectedTask(state, value) {
      state.selectedTask = {
        ...state.selectedTask,
        ...value
      }
    },
    clearFailedSelectedTask(state) {
      delete state.selectedTask.failed
    },
    setSelectedTaskPreview(state, value) {
      state.selectedTaskPreview = value
    },
    setSelectedTaskExpired(state, value) {
      state.selectedTaskExpired = value
    },
    setTaskComments(state, value) {
      if (!state.selectedTask) return
      state.selectedTask.comments = value
    },
    clearTaskComments(state) {
      if (!state.selectedTask) return
      state.selectedTask.comments = []
    },
    setSelectedMainTask(state, value) {
      state.selectedMainTask = value
    },
    setSelectedAttractionCode(state, value) {
      state.selectedAttractionCode = value
    },
    setSelectedUser(state, value) {
      state.selectedUser = value
    },
    setSelectedVerification(state, value) {
      state.selectedVerification = value
    },
    setSelectedDeletionReason(state, value) {
      state.selectedDeletionReason = value
    },
    setTotalAppComplete(state, value) {
      state.totalAppComplete = value
    }
  },

  actions: {
    openTaskCard({ commit }, payload) {
      const {task, dontSetApp} = payload
      commit('setSelectedTask', task)
      commit('setSelectedMainTask', task.task)
      if (task.task && task.task.application && !dontSetApp) {
        commit('setSelectedApplication', task.task.application)
      }
    },
    clearSelectedTask({ commit }) {
      commit('setSelectedTaskExpired', false)
      commit('setSelectedTask', null)
    },
    clearSelectedTaskData({ commit, dispatch }) {
      dispatch('clearSelectedTask')
      commit('setSelectedMainTask', null)
      commit('setSelectedApplication', null)
    }
  }
}
