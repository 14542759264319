export const DESIGNER_TASK_TYPES = [
  'designer',
  'designer_export'
]

export const PROGRAMMER_TASK_TYPES = [
  'programmer',
  'development_export_tasks'
]

export const CREATOR_TASK_TYPES_OF_CREATE_APP = [
  'no_develop',
  'no_develop_no_publish',
  'with_develop',
  'unity_export_application'
]

export const CREATOR_TASK_TYPES_WITH_DEVELOP = [
  'with_develop',
  'unity_export_application'
]

export const CREATOR_TASK_TYPES_WITHOUT_DEVELOP = [
  'no_develop',
  'no_develop_no_publish'
]

export const CREATOR_TASK_TYPES_WITH_PUBLISH = [
  'with_develop',
  'unity_export_application',
  'no_develop'
]

export const ASO_MAN_UPDATE_TASK_TYPES = [
  'update_aso_text',
  'update_aso_image',
  'update_aso_assembly_image'
]

export const APP_MANAGER_TASK_TYPE = {
  create_application: 'create_application',
  update_application: 'update_application',
  no_develop: 'no_develop',
  unity_export_application: 'unity_export_application',
  with_develop: 'with_develop',
  update_app_assembly: 'update_app_assembly',
  update_app_aso_image: 'update_app_aso_image',
  update_app_aso_image_assembly: 'update_app_aso_image_assembly',
  update_aso_text: 'update_aso_text',
  update_aso_image: 'update_aso_image',
  update_aso_assembly_image: 'update_aso_assembly_image',
  update_assembly_add_purchases: 'update_assembly_add_purchases',
}
export const AVAILABLE_APP_MANAGER_TASK_TYPES = [
  APP_MANAGER_TASK_TYPE.create_application,
  APP_MANAGER_TASK_TYPE.update_application,
  APP_MANAGER_TASK_TYPE.no_develop,
  APP_MANAGER_TASK_TYPE.unity_export_application,
  APP_MANAGER_TASK_TYPE.with_develop,
  APP_MANAGER_TASK_TYPE.update_app_assembly,
  APP_MANAGER_TASK_TYPE.update_app_aso_image,
  APP_MANAGER_TASK_TYPE.update_app_aso_image_assembly,
  APP_MANAGER_TASK_TYPE.update_aso_text,
  APP_MANAGER_TASK_TYPE.update_aso_image,
  APP_MANAGER_TASK_TYPE.update_aso_assembly_image,
  APP_MANAGER_TASK_TYPE.update_assembly_add_purchases,
]

export const AVAILABLE_TASK_TYPES_FOR_DELETING = [
  'update_app_assembly',
  'update_app_aso_image_assembly',
  'update_assembly_add_purchases',
  'update_app_aso_image'
]

export const UNUSED_APP_MANAGER_TASK_STATUSES = [
  'inactive',
  'checking',
  'revision',
  'waiting',
  'waiting_assigned',
  'failed_post_moderation',
  'failed_pre_moderation',
  'failed_verification',
  'verified',
  'verification',
  'confirmation'
]

export const APP_MANAGER_SIMPLE_TASK_STATUSES = [
  'waiting',
  'published',
  'completed',
  'failed',
  'paused'
]

export const APP_MANAGER_SIMPLE_TASK_STATUSES_CREASO = [
  'published',
  'completed',
  'process',
  'failed',
  'moderation',
  'revision'
]

export const EXECUTORS_HOSTNAMES = [
  'developer',
  'uxuidesigner',
  'designer'
]

export const USER_ROLE = {
  superadministrator: 'superadministrator',
  creator: 'creator',
  uidesigner: 'uidesigner',
  designer: 'designer',
  programmer: 'programmer',
  tester: 'tester',
  app_manager: 'app_manager',
  aso_manager: 'aso_manager',
}

export const USER_ROLE_FROM_HOSTNAME_MAPPER = {
  appmanager: 'app_manager',
  creator: 'creator',
  admin: 'superadministrator',
  asomanager: 'aso_manager',
  developer: 'programmer',
  uxuidesigner: 'uidesigner',
  designer: 'designer'
}

export const LOGIN_ROUTE_FROM_HOSTNAME_MAPPER = {
  appmanager: '/app_manager',
  creator: '/creator',
  admin: '/admin',
  asomanager: '/aso_manager',
  developer: '/developer',
  uxuidesigner: '/uidesigner',
  designer: '/designer'
}

export const AVAILABLE_LOCALES = [
  'en',
  'ru'
]

export const AVAILABLE_PAYOUTS_TYPES = {
  rub: [
   //'mc',
    'card_payout',
    //'qiwi'
  ],
  usd: [
    'card_payout',
    'paytm',
    'usdttrc20'
  ]
}

export const AVAILABLE_CURRENCIES = [
  {
    name: 'rub',
    title: 'RUB'
  },
  {
    name: 'usd',
    title: 'USD'
  }
]

export const AVAILABLE_USERS_STATUSES = [
  'published',
  'process',
  'checking',
  'revision',
  'completed',
  'failed'
]

export const AVAILABLE_TESTER_STATUSES = [
  'published',
  'process',
  'revision',
  'checking',
  'completed'
]

export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id='

export const ROUTE_FROM_APP_MAN_UPDATE_TYPE_MAPPER = {
  update_app_assembly: 'assembly',
  update_assembly_add_purchases: 'assembly_purchases',
  update_app_aso_image: 'aso_images',
  update_app_aso_image_assembly: 'assembly_aso'
}

export const DEVELOPMENT_SUBTASK_TYPES = [
  'development',
  'development_export'
]

export const SUBTASK_EXECUTORS_ROLES = [
  'uidesigner',
  'designer',
  'programmer'
]

export const UNITY_INTEGRATIONS = [
  'privacy_policy',
  'one_signal',
  'pushator',
  'appmetrica'
]

export const ANDROID_UNITY_EXPORT_WEBGL_INTEGRATIONS = [
  'gdpr',
  'gdpr_s',
  'vksdk',
  '2c',
  'gap',
  'iap'
]

export const MONTHS = [
  { name: 'January', days: 31 },
  { name: 'February', days: 29 },
  { name: 'March', days: 31 },
  { name: 'April', days: 30 },
  { name: 'May', days: 31 },
  { name: 'June', days: 30 },
  { name: 'July', days: 31 },
  { name: 'August', days: 31 },
  { name: 'September', days: 30 },
  { name: 'October', days: 31 },
  { name: 'November', days: 30 },
  { name: 'December', days: 31 }
]

export const CHECKOUT_API_DICT = {
  appCheckResult: {
    title: 'Проверка aab',
    subcat: {
      platformFramework:'Framework',
      appID: 'App Id',
      apiLevel: 'Api level',
      billingLibraryVersion: 'Billing library version'
    }
  },
  architectureCheckResult: {
    title: 'Проверка архитектуры'
  },
  signingCheckResult: {
    title: 'Проверка подписи',
    subcat: {
      isSignedRelease: 'Сборка подписана для релиза',
      signatureVersionsCorrect: 'Версии схем подписи верные',
    }
  }
}

export const FRAMEWORKS = [
  'unity',
  'unity_export',
  'webgl',
  'android_native_fw',
  'phonegap',
  'apache_cordova',
  'ionic_framework',
  'react_native',
  'xamarin',
  'flutter',
  'libgdx',
  'django',
  'godot'
]

export const PROGRAMMING_LANGUAGES = [
  'kotlin',
  'java',
  'csharp',
  'javascript',
  'typescript',
  'dart',
  'python',
  'c'
]

export const ACCESS_RIGHTS_ERROR_MSG = 'User does not have any of the necessary access rights.'

export const FINGERPRINT_EXTRA_TIME = 3000

export const FAILED_FINGERPRINT_ID = 'failed_to_receive'

export const IMAGE_RULES = {
  icon: {
    appSize: {
      google_play: {
        width: 512,
        height: 512
      },
      app_store: {
        width: 1024,
        height: 1024
      }
    }
  },
  screenDescription: {
    width: 1024,
    height: 500
  },
  screenTablet: {
    appSize: {
      google_play: {
        width: 1080,
        height: 1920
      },
      app_store: {
        width: 1242,
        height: 2208
      }
    }
  },
  screenPhone: {
    appSize: {
      google_play: {
        width: 960,
        height: 1920
      },
      app_store: {
        width: 1242,
        height: 2208
      }
    }
  }
}

// Ограничение от сервиса S3
export const MIN_CHUNK_SIZE = 5 * (1024 ** 2)

export const CREATOR_FAST_STATUSES = [
  'waiting',
  'published',
  'process',
  'checking',
  'revision',
  'failed',
  'completed'
]

export const DEFAULT_CREATOR_FAST_STATUSES = [
  'published',
  'process',
  'checking'
]

export const MARATHON_STATUS_STOP = [
  'stop_task_update',
  'stop_app_manager',
  'stop_removed_from_store'
]

export const TITLE_FIELD_HISTORY = {
  description: 'Описание',
  store_name: 'Название в Store',
  short_description: 'Краткое описание',
  phone_screenshot: 'скриншоты для телефона',
  table_screenshot: 'скриншоты для планшета',
  screen: 'картинки для описания',
  icon: 'иконка',
  apk_production_file: 'AAB-файл'
}

export const FILE_FIELDS_HISTORY = ['phone_screenshot', 'table_screenshot', 'screen', 'icon', 'apk_production_file']

export const TRANSLATE_STATUS_HISTORY = {
  created: 'создал',
  updated: 'обновил',
  deleted: 'Удалена'
}

export const FIELDS_WITH_SUBTASK = [
  'uidesigner',
  'designer',
  'designExport',
  'development',
  'developmentExport',
  'app_manager'
]

export const ICON_LOADING_MARKUP = '<path fill="currentColor" d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32zm448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32zm-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32zM195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0zm-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"></path>'

export const IMG_STORE_SIZES = {
  google_play: {
    icon: '512 х 512',
    descript: '1024 х 500',
    tablet: '1080 х 1920',
    phone: '960 x 1920',
  },
  app_store: {
    icon: '1024 х 1024',
    descript: '1024 х 500',
    tablet: '1242 х 2208',
    phone: '1242 х 2208',
  }
}

export const PASSWORD_CONDITIONS = [
  value => value.length >= 8,
  value => value.match(/[a-zA-Z]+/),
  value => value.match(/\d+/),
  value => value.match(/(\W|_)+/)
]

export const SIMPLE_TASK_STATUS = {
  checking: 'moderation',
  revision: 'moderation_rejected'
}

// Если компонент исп. для разных url с разными ролями, то исп. группировку, в противном случае формат {ключ: значение}
export const SIDEBAR_ROUTES_NAMES = {
  simpleTasks: {
    simpleTaskCreAso: 'Task list simple aso & creator',
    simpleTaskAppManager: 'Task list simple',
    simpleTaskAdmin: 'Gmail accounts tasks',
    simpleModerationTasks: 'Simple moderation',
    simpleModerationGmailTasks: 'Simple moderation gmail',
  },
  myApps: {
    creatorMyApp: 'Applications list creator',
    creatorArchiveApp: 'Archived applications list creator',
  },
  creatorNoDevelopNoPublish: 'Applications list without develop and public'
}

export const localeList = ['ru', 'en']

export const RESOLUTIONS_IMG_FIELDS = [
  {
    title: 'Разрешение для телефона',
    fieldModel: 'phone_screen_resolution'
  },
  {
    title: 'Разрешение для планшета',
    fieldModel: 'tablet_screen_resolution'
  },
]

export const TYPE_SUBTASKS = {
  app_manager: "Публикация",
  app_manager_update: "Публикация",
  designer: "Дизайн ASO",
  designer_adv: "Дизайн ASO",
  designer_export: "Дизайн ASO",
  ui_designer: "UX/UI",
  programmer: "Программирование",
  development_integration: "Программирование",
  development_export_tasks: "Программирование",
  tester: "Тестирование",
  uploader: "Публикация",
  uploader_update: "Публикация",
  uploader_verification: "",
  manager_verification: "",
  simple: "",
  manager_simple: "",
  user_simple: ""
}
