import moment from 'moment'
import FormDataV3 from "@/utils/formDataApiV3"
import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3"

export default {
  state: {
    /*
        Формат роли:
        {
          id: string,
          name: string,
          isActive: boolean,
          activeTaskTab: string, // выбранная вкладка со статусом задач
        },
    */
    userRoles: [],
    depositList: [],
    payoutList: [],
    payoutListPageCount: null,
    displayedBalance: {},
    userDataController: null
  },
  getters: {
    userRoles: state => state.userRoles,
    activeRoleName: state => {
      return state.userRoles.length ? state.userRoles.find(role => role.isActive).name : null
    },
    depositList: state => state.depositList,
    payoutList: state => state.payoutList,
    payoutListPageCount: state => state.payoutListPageCount,
    userWallet: (state, rootState) => {
      return rootState.user && !rootState.user.is_internal ? rootState.user.wallet : null
    },
    displayedBalance: (state, rootState) => {
      return rootState.user && !rootState.user.is_internal ? state.displayedBalance : null
    },
    userDataController: state => state.userDataController
  },
  mutations: {
    setUserRoles(state, value) {
      state.userRoles = value
      localStorage.setItem('rolesData', JSON.stringify(value))
    },
    clearUserRoles(state) {
      state.userRoles = []
      localStorage.removeItem('rolesData')
    },
    setDepositList(state, value) {
      state.depositList = value
    },
    setPayoutList(state, value) {
      state.payoutList = value
    },
    setPayoutListPageCount(state, value) {
      state.payoutListPageCount = value
    },
    setDisplayedBalance(state, value) {
      state.displayedBalance = value
    }
  },
  actions: {
    async updateProfile({ rootGetters, commit }, userData) {
      const user = rootGetters.user
        const data = new FormDataV3()
        data.addParam('type', 'users')
        data.addParam('id', `${user.id}`)

        for (const item in userData) {
          data.addAttr(item, userData[item])
        }

        const url = new UrlBuilderApiV3()
            .setEndpoint(`users/${user.id}`)
            .addInclude('wallet', 'roles')
            .build()
      try {
        const response = await axiosV3.patch(url, data)
        if (user?.interface_language) response.data.interface_language = user?.interface_language
        const userUpd = response.data
        commit('setUser', userUpd)
      } catch (e) {
        console.info(e)
        this.$app.$notifyError(e)
        throw(e)
      }
    },
    async updateUserPhoto({ rootGetters, commit, dispatch }, file) {
      const path = await dispatch('uploadFile', file)
      const user = rootGetters.user
      if (!path) return
      try {
        const data = new FormDataV3()
        data.addParam('type', 'users')
        data.addParam('id', `${user.id}`)

        data.addAttr('image', path)

        const url = new UrlBuilderApiV3()
            .setEndpoint(`users/${user.id}`)
            .addInclude('wallet', 'roles')
            .build()
        const response = await axiosV3.patch(url, data)
        if (user?.interface_language) response.data.interface_language = user?.interface_language
        const userUpd = response.data
        commit('setUser', userUpd)
        this.$app.$notifySuccess('User photo updated')
      } catch (e) {
        console.info(e)
        this.$app.$notifyError(e)
      }
    },
    resetUserRoles({ rootState, commit }) {
      const roles = rootState.auth.user.roles.map((item, i) => ({
        id: `${i}`,
        name: item.name,
        isActive: i === 0,
        activeTaskTab: 'published'
      }))
      commit('setUserRoles', roles)
    },
    selectUserRole({ state, commit }, id) {
      const updateRoles = state.userRoles.map(item => {
        item.isActive = item.id === id
        return item
      })
      commit('setUserRoles', updateRoles)
    },
    clearUserRoles({ commit }) {
      commit('clearUserRoles')
    },
    setActiveTaskTab({ state, commit }, name) {
      const updateRoles = state.userRoles.map(item => {
        if (item.isActive) {
          item.activeTaskTab = name
        }
        return item
      })
      commit('setUserRoles', updateRoles)
    },
    resetActiveTaskTab({ state, commit }) {
      const updateRoles = state.userRoles.map(item => {
        item.activeTaskTab = 'published'
        return item
      })
      commit('setUserRoles', updateRoles)
    },
    async updateDepositList({ commit, rootGetters }) {
      const ABORT_KEY = 'depositList'
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })
      try {
        const userId = rootGetters.user.id
        const url = new UrlBuilderApiV3()
          .setEndpoint('transactions')
          .addFilters('filter[confirmed]=1')
          .addFilters('filter[type]=deposit')
          .addFilters(`filter[user][id]=${userId}`)
          .addInclude('wallet', 'deposit_type')
          .build()
        const response = await axiosV3.get(url, {
          signal: rootGetters.abortControllers[ABORT_KEY].signal
        })
        const depositList = response.data
        commit('setDepositList', depositList)
        commit('deleteAbortController', ABORT_KEY)
      } catch (e) {
        console.info(e)
        if (e.handled || e.cancelled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('Accruals history error')
      }
    },
    async downloadPayoutList({ commit, rootGetters }, payload) {
      const ABORT_KEY = 'payoutList'
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })
      try {
        const { page } = payload
        const userId = rootGetters.user.id
        const url = new UrlBuilderApiV3()
          .setEndpoint('transactions')
          .addFilters('filter[confirmed]=1')
          .addFilters('filter[type]=withdraw')
          .addFilters(`filter[user][id]=${userId}`)
          .addInclude('wallet', 'deposit_type')
          .build()
        const response = await axiosV3.get(url, {
          signal: rootGetters.abortControllers[ABORT_KEY].signal
        })
        const payoutList = response.data
        const metaData = response.meta.page
        commit('setPayoutList', payoutList)
        if (metaData && metaData.last_page) {
          commit('setPayoutListPageCount', metaData.last_page)
        }
        commit('deleteAbortController', ABORT_KEY)
      } catch (e) {
        console.info(e)
        if (e.handled || e.cancelled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('Withdrawal history error')
      }
    },
    async createPayout(ctx, payload) {
      try {
        const { amount, paymentType, purse, wallet, bankCountry, bankCardData } = payload
        const data = new FormDataV3()
        data.addParam('type', 'payouts')
        data.addAttr('target', paymentType === 'card_payout' ? 'card' : paymentType)
        data.addAttr('amount', Number(amount))
        data.addAttr('purse', purse)
        data.addAttr('currency', wallet.toUpperCase())
        data.addAttr('country', bankCountry?.toUpperCase())
        if (bankCardData) {
          for (const [key, value] of Object.entries(bankCardData)) {
            if (['birth_date', 'country_code'].includes(key)) continue
            data.addMeta(key, String(value))
          }
          const day = bankCardData.birth_date.day
          const month = bankCardData.birth_date.month
          const year = bankCardData.birth_date.year
          const date_of_birth = moment(`${day}-${month}-${year}`, 'DD-MM-YYYY')
            .format('YYYY-MM-DD')
          data.addMeta('holder_birthday', date_of_birth)
          data.addMeta('payment_system', 'mastercard')
        }
        await axiosV3.post('/payouts', data)
        this.$app.$notifySuccess('Withdrawal was successful')
        return true
      } catch (e) {
        console.info(e)
        this.$app.$notifyError('Withdrawal funds error')
      }
    },
    setDisplayedBalance({ commit }, payload) {
      commit('setDisplayedBalance', payload)
    },
    async updateUserInterfaceLang({ rootGetters, commit }, newLang) {
      const userId = rootGetters.user.id
      const data = new FormDataV3()
      data.addParam('type', 'users')
      data.addParam('id', `${userId}`)
      data.addAttr('interface_language', newLang)
      try {
        const response = await axiosV3.patch(`users/${userId}`, data)
        const user = {
          ...rootGetters.user,
          interface_language: response.data.interface_language
        }
        commit('setUser', user)
      } catch (e) {
        if (e.handled) return
        this.$app.$notifyError('Failed to update interface language')
      }
    },
  }
}
