<template>
  <nav :class="classes" class="navbar">
    <slot/>
    <!--    <CollapseTransition>-->
    <transition>
      <div
        id="navigation"
        class="navbar-menu"
        :class="navbarMenuClasses">
        <ul class="navbar-nav">
          <slot name="navbar-menu"/>
        </ul>
      </div>
    </transition>
  </nav>
</template>
<script>
  export default {
    name: 'Navbar',
    model: {
      prop: 'showNavbar',
      event: 'change'
    },
    props: {
      showNavbar: {
        type: Boolean,
        description: "Whether navbar is visible"
      },
      navbarMenuClasses: {
        type: [String, Object],
        description: 'Navbar menu css classes'
      },
      transparent: {
        type: Boolean,
        default: true,
        description: 'Whether navbar is transparent'
      },
      position: {
        type: String,
        default: 'absolute',
        description: 'Navbar position (absolute|fixed|relative)'
      },
      type: {
        type: String,
        default: 'white',
        validator(value) {
          return ['white', 'default', 'primary', 'danger', 'success', 'warning', 'info'].includes(value);
        },
        description: 'Navbar type (primary|info|danger|default|warning|success)'
      },
      useClasses: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      classes() {
        if (!this.useClasses) return []
        let color = `bg-${this.type}`;
        let navPosition = `navbar-${this.position}`;
        return [
          { 'navbar-transparent': !this.showNavbar && this.transparent },
          { [color]: this.showNavbar || !this.transparent },
          navPosition]
      }
    },
  }
</script>
<style scoped>
  .navbar-relative {
    position: relative;
  }
</style>
