import _i18n from '@/i18n/i18n'
import { checkForValidateError } from './utils/helpers'
import { isNavigationFailure } from 'vue-router'
import { ACCESS_RIGHTS_ERROR_MSG } from '@/utils/constants'
import * as Sentry from '@sentry/vue'
import _axios from 'axios'
import Jsona from 'jsona'

const i18n = _i18n.global

const createAxiosInstance = (payload) => {
  const {store, type, interceptors, options = {}} = payload

  const defaultOptions = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: {
      common: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
  }

  const defaultInterceptors = {
    request: {
      onFulfilled: config => {
        const authHeader = !Object.prototype.hasOwnProperty.call(config.headers, 'Authorization') && localStorage.getItem('token')
          ? { Authorization: `Bearer ${localStorage.getItem('token')}` }
          : {}
        if (config.method === 'put' && config.data) {
          config.data.append('_method', 'PUT')
          config.method = 'post'
        }
        config.headers = {
          ...config.headers,
          ...authHeader,
          'Accept-Language': store.getters.locale
        }
        return config
      },
      onRejected: error => Promise.reject(error)
    },
    response: {
      onFulfilled: response => response,
      onRejected: async error => {
        if (error.__CANCEL__) return Promise.reject(error)

        const originalRequest = error.response.config
        let code
        let url
        try {
          url = error.response.config.url
          code = error.response.status
          Sentry.captureMessage(`Error AJAX: '${url}' with code ${code}`)
        } catch (e) {
          console.log('Failed to send Sentry message. Error:', e)
        }
        if (code && [404, 422].includes(code)) {
          const validateErrors = checkForValidateError(error)
          if (validateErrors?.length) {
            validateErrors.map(msg => {
              let result = msg
              if (msg.includes('can\'t to deliver')) {
                result = i18n.t('This number cannot be used')
              }
              store.$app.$notifyError(result)
            })
          } else {
            store.$app.$notifyError(error)
          }
          return Promise.reject({
            ...error,
            handled: true
          })
        }
        if (error.response && code === 401) {
          if(!originalRequest._retry && !!localStorage.getItem('refreshToken')) {
            await navigator.locks.request("tryRotateTokens", async () => {
              await store.dispatch('tryRotateTokens')
            })
            originalRequest._retry = true
            originalRequest.headers = {
              ...originalRequest.headers,
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
            return axios(originalRequest)
          }

          store.commit('clearUser')
          store.commit('clearToken')
          store.$app.$router.push(
            `/${i18n.locale}/login?status=401`,
            undefined,
            e => {
              if (isNavigationFailure(e)) return
              console.error(e)
            }
          )
        }
        if (
          error.response && code === 500 &&
          error.response.data.error.message === ACCESS_RIGHTS_ERROR_MSG
        ) {
          return Promise.reject({
            ...error,
            accessRightsError: true
          })
        }
        return Promise.reject(error)
      }
    }
  }

  window[type] = _axios.create({
    ...defaultOptions,
    ...options
  })

  const axios = window[type]

  axios.interceptors.request.use(
    interceptors?.request?.onFulfilled ?? defaultInterceptors.request.onFulfilled,
    interceptors?.request?.onRejected ?? defaultInterceptors.request.onRejected
  )

  axios.interceptors.response.use(
    interceptors?.response?.onFulfilled ?? defaultInterceptors.response.onFulfilled,
    interceptors?.response?.onRejected ?? defaultInterceptors.response.onRejected
  )
}

export const axios = (store) => createAxiosInstance({store, type: 'axios'})

export const axiosV3 = (store) => createAxiosInstance({store, type: 'axiosV3', interceptors: {
    response: {
      onFulfilled: response => {
        if (!response.data.jsonapi) {
          return {
            data: response.data.response,
            meta: response.data.meta
          }
        }
        if (response.data === '') return
        const preMeta = Array.isArray(response.data.data) ? response.data.meta : response.data.data.meta
        const meta = preMeta ? {...preMeta, ...response.data.meta || {}} : response.data.meta
        const dataFormatter = new Jsona()
        const transformData = dataFormatter.deserialize(response.data)
        return {
          data: transformData,
          included: response.data.included,
          meta: meta
        }
      }
    }
  }, options: {
    baseURL: `${process.env.VUE_APP_BACKEND_URL}/v3`,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json'
    }
  }})
