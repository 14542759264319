import {
  compareFormData,
  getElementFromTypes,
  prepareDataForAppWithDevelop,
  prepareDataForAppWithoutDev,
  prepareDataForNewAppWithDevelop,
  prepareDataForUnityExportApp,
  prepareLanguage,
  renameFields,
  transformObjectToFormData
} from '@/utils/helpers'
import { CREATOR_TASK_TYPES_OF_CREATE_APP } from '@/utils/constants'
import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3";

export default {
  async getApplications({ commit, rootGetters }, page) {
    const ABORT_KEY = 'appList'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    const creatorId = rootGetters.user.id
    const urlBuilder = new UrlBuilderApiV3()
    const url = urlBuilder.setEndpoint('applications')
      .addInclude('status', 'tasks.type','tasks.subtasks.status', 'store')
      .addFilters(rootGetters.selectedFilters.replace('{creatorId}', creatorId))
      .addAggregate('is_suspicious')
      .setSearch(rootGetters.listSearch)
      .setPage(page)
      .build()
    try {
      const response = await axiosV3.get(url, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const appList = response.data
      const metaData = response.meta.page
      commit('setApplications', appList)
      commit('setApplicationsListPageCount', metaData.last_page)
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      if (e.code === 'ERR_CANCELED') return
      if (!e.handled) {
        this.$app.$notifyError('При загрузке списка приложений произошла ошибка. Попробуйте позже.')
      }
    }
  },
  clearApplications({ commit }) {
    commit('setApplications', [])
    commit('setApplicationsListPageCount', undefined)
  },
  async editAppWithDevelop({ rootGetters }, payload) {
    const { taskId, model, initModel, subtask } = payload
    const appId = initModel.id

    const progLangsIds = {
      programming_language: []
    }
    rootGetters.selectedApplication.task
      .find(item => item.type.name === 'with_develop')
      .development
      .programming_languages
      .map(lang => progLangsIds.programming_language.push(lang.id))

    let data

    try {
      if (subtask) {
        data = prepareDataForNewAppWithDevelop(model, initModel)
      } else {
        const initData = prepareDataForAppWithDevelop({ ...initModel, ...progLangsIds })
        const _data = prepareDataForAppWithDevelop(model)
        data = compareFormData(initData, _data, 'with_develop')
      }
      if (!data) return false
      data.set('task_id', taskId)
      await axios.put(`creator/build/native/${appId}`, data)
      this.$app.$notifySuccess('App updated')
      return true
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('При сохранении приложения произошла ошибка. Попробуйте позже.')
    }
  },
  async editUnityExportApp({ rootState }, payload) {
    const { model, initModel, subtask } = payload
    const appId = subtask ? initModel.id : initModel.unity.id
    const types = rootState.types
    const frameworks = types.frameworks_for_native
    let data
    try {
      if (subtask) {
        const getChildAppId = frameworkName => {
          const framework = frameworks.find(item => item.name === frameworkName)
          if (!framework) return
          const childApp = initModel.children.find(app => app.framework_id === framework.id)
          if (!childApp) return
          return childApp.id
        }

        data = prepareDataForUnityExportApp(model, frameworks, true, initModel, types)
        for (const fw of Object.keys(model)) {
          if (_.isEmpty(model[fw])) continue
          switch (fw) {
            case 'unity':
              if (data.has('application[0][id]')) break
              data.set('application[0][id]', appId)
              break
            case 'unity_export':
              if (data.has('application[1][id]')) break
              data.set('application[1][id]', getChildAppId(fw))
              break
            case 'webgl':
              if (data.has('application[2][id]')) break
              data.set('application[2][id]', getChildAppId(fw))
              break
          }
        }
      } else {
        const initData = prepareDataForUnityExportApp(initModel, frameworks, true)
        const _data = prepareDataForUnityExportApp(model, frameworks, true)
        data = compareFormData(initData, _data, 'unity_export_application')
      }
      if (!data) return false
      await axios.put(`creator/build/export/${appId}`, data)
      this.$app.$notifySuccess('App updated')
      return true
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('При сохранении приложения произошла ошибка. Попробуйте позже.')
    }
  },
  async returnTask(ctx, taskId) {
    try {
      await axios.put(`return/tasks/${taskId}`)
      this.$app.$notifySuccess('Задача возвращена текущему исполнителю')
      return true
    } catch (e) {
      console.info(e)
      this.$app.$notifyError('Не удалось вернуть задачу. Попробуйте позже.')
      throw(e)
    }
  },
  async republishTask(ctx, taskId) {
    try {
      await axios.put(`restore-to-all/tasks/${taskId}`)
      this.$app.$notifySuccess('Задача опубликована заново')
      return true
    } catch (e) {
      console.info(e)
      this.$app.$notifyError('Не удалось опубликовать задачу заново. Попробуйте позже.')
      throw(e)
    }
  },
  openAppByIdAndAppVersion({ state, commit }, payload) {
    const SUBTASK_FIELDS = [
      'uidesigner',
      'designer',
      'design_export',
      'development',
      'development_export',
      'app_manager'
    ]

    const { parentAppVersion, appVersion, taskId } = payload
    const getApp = () => {
      if (parentAppVersion) {
        const parentApp = state.applications.find(item => item.version === parentAppVersion)
        if (!parentApp.children.length) return parentApp
        const appFamily = [parentApp, ...parentApp.children]
        return appFamily.find(item => item.version === appVersion)
      } else {
        return state.applications.find(item => item.version === appVersion)
      }
    }
    const app = getApp()
    if (!app || _.isEmpty(app)) return
    const mainTask = app.task.find(item => CREATOR_TASK_TYPES_OF_CREATE_APP.includes(item.type.name))
    if (!mainTask) return
    let task
    for (const field of SUBTASK_FIELDS) {
      if (!Object.keys(mainTask)
        .includes(field)) continue
      if (mainTask[field].id === parseInt(taskId)) {
        task = mainTask[field]
        break
      }
    }
    if (!task) return
    commit('setSelectedApplication', app, { root: true })
    commit('setSelectedTask', task, { root: true })
    commit('setSelectedMainTask', task.task, { root: true })
  },

  async downloadTaskListForApp({ state, rootState, commit, rootGetters }, appId) {
    const ABORT_KEY = 'taskListForApp_' + appId
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    try {
      let updatedAppsWithTasks
      const appsWithTasks = state.applicationsWithTasks
      const app = state.applications.find(item => item.id === appId)
      const response = await axios.get(`v2/admin/${appId}/tasks`, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const task = response.data.response
      const getStatusName = id => rootState.types.status.find(s => s.id === id).name
      const taskStatuses = task.map(item => ({
        taskId: item.id,
        statusName: getStatusName(item.status_id)
      }))
      const updatedApp = { ...app, task }
      const appExists = appsWithTasks.findIndex(item => item.id === appId) >= 0
      if (appExists) {
        updatedAppsWithTasks = appsWithTasks.map(item => {
          if (item.id === updatedApp.id) return updatedApp
          return item
        })
      } else {
        updatedAppsWithTasks = appsWithTasks
        updatedAppsWithTasks.push(updatedApp)
      }
      commit('setApplicationsWithTasks', updatedAppsWithTasks)
      // commit('updateTaskStatusesForApp', { appId, taskStatuses })
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      console.log(e)
      this.$app.$notifyError('При загрузке списка задач произошла ошибка. Попробуйте позже.')
    }
  },
  async downloadApplication({ commit, rootGetters }, appId) {
    const ABORT_KEY = 'appData_' + appId
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    try {
      const response = await axios.get(`/v2/admin/applications/${appId}`, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const app = response.data.response
      commit('setSelectedApplication', app, { root: true })
      commit('deleteAbortController', ABORT_KEY, { root: true })
      return app
    } catch (e) {
      console.log(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('При загрузке данных произошла ошибка. Попробуйте позже.')
    }
  },
  async buildAppWithDevelop(ctx, payload) {
    const { model } = payload
    try {
      const data = prepareDataForNewAppWithDevelop(model)
      await axios.post('creator/build/native', data)
      this.$app.$notifySuccess('App added')
      return true
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('При создании приложения произошла ошибка. Попробуйте позже.')
    }
  },
  async buildUnityExportApp({ rootState }, payload) {
    const { model } = payload
    const types = rootState.types
    const frameworks = types.frameworks_for_native
    try {
      const data = prepareDataForUnityExportApp(model, frameworks)
      await axios.post('creator/build/export', data)
      this.$app.$notifySuccess('App added')
      return true
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('При создании приложения произошла ошибка. Попробуйте позже.')
    }
  },
  async buildAppNoDevelop(ctx, payload) {
    const { model, type } = payload
    try {
      const data = prepareDataForAppWithoutDev(model)
      if (!model.prototype_link) data.delete('prototype_link')
      await axios.post(`creator/create/${type}/application`, data)
      this.$app.$notifySuccess('App added')
      return true
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('При создании приложения произошла ошибка. Попробуйте позже.')
    }
  },
  async uploadAppNoPublish({ commit, rootGetters }, model) {
    const mode = rootGetters['modalWindow/mode']
    const requestType = mode === 'new' ? 'post' : 'put'
    const app = rootGetters['selectedApplication']
    const appId = mode === 'edit' ? `/${app.id}` : ''
    if (Object.keys(model).includes('language')) {
      const defaultLang = model.language_default || app.language_default
      prepareLanguage(model, defaultLang)
    }
    let data = transformObjectToFormData(model)
    data = renameFields(data, {
      additional_files: 'files[prototypes][creator]'
    })
    // В задаче по добавлению приложений no_develop_no_publish поля ads нет, но бэк его требует
    if (mode === 'new') data.set('ads', '0')
    try {
      const { data: { response } } = await axios[requestType](`creator/create/no-publish/application${appId}`, data)
      commit('updateSelectedApplication', response, { root: true })
      this.$app.$notifySuccess(mode === 'new' ? 'App added' : 'App updated')
      return true
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('При сохранении приложения произошла ошибка. Попробуйте позже.')
    }
  },
  async editAppNoDevelop({ rootGetters }, payload) {
    const {
      appId,
      initModel,
      model,
      type
    } = payload
    const initData = prepareDataForAppWithoutDev(initModel)
    const _data = prepareDataForAppWithoutDev(model)
    const data = compareFormData(initData, _data, 'app')
    if (!data) return false
    if (model.programming_language === initModel.programming_language_id && model.framework_id === initModel.framework_id) {
      data.delete('programming_language_id')
    }
    if (type === 'no-develop' && !data.has('framework_id') && data.has('programming_language_id')) {
      data.set('framework_id', rootGetters.selectedApplication.framework_id)
    }
    try {
      await axios.put(`creator/create/${type}/application/${appId}`, data)
      this.$app.$notifySuccess('App updated')
      return true
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('При сохранении приложения произошла ошибка. Попробуйте позже.')
    }
  },
  async checkUniqueAppVersion(ctx, version) {
    try {
      const { data } = await axios.get(`creator/applications?page=1&search=${version}`)
      return data.response.data.filter(item => item.version === version)
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось проверить Application ID на уникальность')
    }
  },
  async forceCompleteSubtask({ rootGetters }, payload) {
    const { type, model, dataSafetyFile } = payload
    const taskId = rootGetters['selectedTask'].id
    const getUiData = () => {
      const data = new FormData()
        if (model.prototype_link !== null) {
          data.set('prototype_link', model.prototype_link)
        }
      data.set('comment', model.comment)
      if (model.task_files.length) {
        model.task_files.forEach((item, index) => {
          data.set(`task_files[${index}]`, item)
        })
      } else {
        data.set(`task_files[0]`, '')
      }
      return data
    }
    const getDesignerData = () => {
      const data = new FormData()
      const iconRequired = rootGetters.selectedTask.main_task_type !== 'update_aso_assembly_image'
      if (iconRequired || !!model.icon) {
        data.set('application[icon]', model.icon)
      }
      if (iconRequired || !!model.icon_background) {
        data.set('application[icon_background]', model.icon_background)
      }
      model.language.forEach((item, index) => {
        data.set(`language[${index}][name]`, item.name)
        data.set(`language[${index}][icon]`, item.icon)
        for (const field in item) {
          if (['name', 'icon'].includes(field)) continue
          if (item[field] === null) continue
          item[field].forEach((file, idx) => {
            data.set(`language[${index}][${field}][${idx}]`, file)
          })
        }
      })
      return data
    }
    const getProgrammerData = () => {
      const data = new FormData()
      const fieldRequired = rootGetters.selectedTask.main_task_type !== 'update_aso_assembly_image'
      Object.entries(model).forEach(item => {
        const [key, value] = item
        if (fieldRequired || !!value) {
          data.set(key, value)
        }
      })
      dataSafetyFile && data.set('data_safety_file', dataSafetyFile)
      return data
    }
    const getProgrammerUnityData = () => {
      const data = new FormData()
      Object.entries(model).forEach((item, index) => {
        const langData = item[1]
        Object.entries(langData).forEach(field => {
          const [key, value] = field
          data.set(`application[${index}][${key}]`, value)
        })
      })
      return data
    }
    const mapper = {
      ui_designer: getUiData,
      designer: getDesignerData,
      designer_export: getDesignerData,
      programmer: getProgrammerData,
      development_export_tasks: getProgrammerUnityData
    }

    const getter = mapper[type]
    if (!getter) return

    const data = getter(model)

    try {
      await axios.put(`force-completion/tasks/${taskId}`, data)
      return true
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось завершить задачу. Попробуйте позже.')
    }
  },
  async downloadSubtaskStatsForApps({ commit }) {
    try {
      const { data: { response } } = await axios('tasks/subtasks-statistics?types=unity_export_application,with_develop,no_develop')
      commit('setSubtaskStats', response)
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('При попытке загрузить статистику по статусам подзадач произошла ошибка.')
    }
  },
  updateSubtaskStatus({ commit, state }, data) {
    const appId = data.task.application_id
    const subtaskId = data.id
    const foundApp = state.applications.find(app => app.id === appId)
    const foundAppWithTasks = state.applicationsWithTasks.find(app => app.id === appId)
    const subtaskStatus = getElementFromTypes('status', data.status_id)

    if (!foundApp || !foundAppWithTasks) return

    commit('updateTaskStatus', {
      appId,
      mainTaskId: data.task.id,
      mainTaskStatusId: data.task.status_id,
      subtaskId,
      subtaskStatus
    })
  },
  async archiveApplication (discard, { appId }) {
    try {
      await axiosV3.post(`/applications/${appId}/archive/`)
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось перенести приложение в архив. Попробуйте позже.')
    }
  },
  async unArchiveApplication (discard, { appId }) {
    try {
      await axiosV3.delete(`/applications/${appId}/archive/`)
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось перенести приложение из архива. Попробуйте позже.')
    }
  },
}
