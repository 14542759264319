<template>
  <component
    :is="tag"
    :type="nativeType"
    class="btn"
    :class="classes"
    @click="handleClick">
    <span v-if="$slots.label" class="btn-label">
      <slot name="label"/>
    </span>
    <slot/>
    <span v-if="$slots.labelRight" class="btn-label btn-label-right">
      <slot name="labelRight"/>
    </span>
  </component>
</template>
<script>
  export default {
    name: 'PButton',
    props: {
      tag: {
        type: String,
        default: 'button',
        description: 'Html tag to use'
      },
      type: {
        type: String,
        default: 'default',
        description: 'Color type'
      },
      nativeType: {
        type: String,
        default: 'button',
        description: 'Native button type (e.g submit|button) '
      },
      round: Boolean,
      outline: Boolean,
      block: Boolean,
      social: Boolean,
      link: Boolean,
      icon: Boolean,
      wide: Boolean,
      size: String
    },
    emits: ['click'],
    computed: {
      classes() {
        let btnClasses = [
          {[`btn-outline-${this.type}`]: this.outline},
          {'btn-icon': this.icon},
          {'btn-round': this.round},
          {'btn-block': this.block},
          {'btn-social': this.social},
          {'btn-link': this.link},
          {'btn-wd': this.wide},
          `btn-${this.type}`
        ];
        if (this.size) {
          btnClasses.push(`btn-${this.size}`)
        }
        return btnClasses
      }
    },
    methods: {
      handleClick(evt) {
        this.$emit('click', evt)
      }
    }
  }
</script>
<style>
</style>
