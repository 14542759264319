import { getFPVisitorId, getUrlPartByRole, prepareExternalUserData } from '@/utils/helpers'
import { FAILED_FINGERPRINT_ID, USER_ROLE_FROM_HOSTNAME_MAPPER } from '@/utils/constants'
import countryPhoneCodes from '@/dictionary/countryPhoneCodes.json'
import FormDataV3 from '../utils/formDataApiV3'
import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3";

export default {
  state: {
    user: JSON.parse(localStorage.getItem('user')),
    codeCountry: ''
  },
  getters: {
    user: state => state.user,
    codeCountry: state => state.codeCountry
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    clearToken() {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
    },
    clearUser(state) {
      localStorage.removeItem('user')
      state.user = null
    },
    updateCodeCountry(state, code) {
      state.codeCountry = code
    },
    changeUserData(state, payload) {
      state.user = {
        ...state.user,
        ...payload
      }
      }
  },
  actions: {
    async redirectSso ({ commit }) {
      try {
        const state = this.$app.$route.query.session_state
        const code = this.$app.$route.query.code
        const data = new FormDataV3();
        data.addParam('type', 'users')
        data.addMeta('state', state)
        data.addMeta('code', code)
        const response = await axiosV3.post('/users/keycloak?include=roles', data)
        this.$app.$notifyInfo('Logined')
        localStorage.setItem('token', response.meta.access_token)
        localStorage.setItem('refreshToken', response.meta.refresh_token)
        commit('setUser', response.data)
        const role = response.data.roles[0].name
        const path = this.$app.$sidebar.getStartPage(role)
        await this.$app.$router.push(path)
      } catch (error) {
        this.$app.$notifyDanger(error.response?.data?.error ?? 'Login error')
        await this.$app.$router.push('/')
      }
    },
    async loginSso ({ commit }) {
      try {
        const response = await axiosV3.get('/users/keycloak')
        const ssoLoginPage = response.meta.url
        window.location.replace(ssoLoginPage)
      } catch (error) {
        this.$app.$notifyDanger(error.response?.data?.error ?? 'Login error')
        commit('clearUser')
        commit('clearToken')
      }
    },
    async tryRotateTokens({ commit }) {
      if (!localStorage.getItem('refreshToken')) {
        return
      }
      const rotateTokensFn = async () => {
        try {
          const urlBuilder = new UrlBuilderApiV3()
          const url = urlBuilder.setEndpoint('/users/keycloak/refresh')
          .build()
          const refreshToken = localStorage.getItem('refreshToken')
          const config = {
            _retry: true,
            headers: {Authorization: `Bearer ${refreshToken}`},
          }
          const response = await axiosV3.post(url, {}, config)
          localStorage.setItem('token', response.meta.access_token)
          localStorage.setItem('refreshToken', response.meta.refresh_token)
          } catch (error) {
          this.$app.$notifyDanger(error.response?.data?.error ?? 'Login error')
          commit('clearUser')
          commit('clearToken')
        }
      }
      const interval = setInterval(() => {
        localStorage.removeItem('tryRotateTokens')
        clearInterval(interval)
      }, 10000)
      const refreshMark = localStorage.getItem('tryRotateTokens')
      if (refreshMark) {
        return
      }
      localStorage.setItem('tryRotateTokens', true)
      await rotateTokensFn()
    },
    async login({ commit }, form) {
      try {
        const hostname = location.hostname.split('.')[0]
        const urlRole = getUrlPartByRole(hostname)
        const response = await axios.post(`auth${urlRole}/login`, form)
        this.$app.$notifyInfo('Logined')
        localStorage.setItem('token', response.data.response.access_token)
        commit('setUser', response.data.response.user)
        return true
      } catch (error) {
        console.info(error)
        this.$app.$notifyDanger(error.response?.data?.error ?? 'Login error')
        commit('clearUser')
        commit('clearToken')
      }
    },
    async register({ commit }, form) {
      try {
        const response = await axios.post('auth/register', form)
        this.$app.$notifyInfo('Registered')
        localStorage.setItem('token', response.data.response.access_token)
        commit('setUser', form)
        return true
      } catch (e) {
        console.info(e)
        commit('clearUser')
        this.$app.$notifyError(e)
        throw (e)
      }
    },
    async logout({ commit, dispatch }) {
      commit('clearUser')
      commit('clearToken')
      dispatch('clearNotificationList')
      dispatch('clearUserRoles')
      this.$app.$notifyInfo('Logout')
      dispatch('stopIntercomChat')
    },
    async uploadDataForLoginExternal({ commit, dispatch, rootGetters }, model) {
      try {
        const hostName = rootGetters.hostName
        const urlRole = getUrlPartByRole(hostName)
        const data = new FormData()
        data.set('phone', model.phone)
        data.set('password', model.password)
        if (model.fingerprintId !== FAILED_FINGERPRINT_ID) {
          data.set('visitor_id', model.fingerprintId)
        }
        const response = await axios.post(`auth${urlRole}/login`, data)
        const token = response.data.response.access_token
        const user = response.data.response.user
        this.$app.$notifyInfo('Logined')
        localStorage.setItem('token', token)
        commit('setUser', user)
        dispatch('updateIntercomSettings', {
          user_id: user.uuid
        })
        dispatch('sendFingerPrintVisitorId', user.id)
        return true
      } catch (error) {
        this.$app.$notifyDanger(error.response?.data?.error)
        commit('clearUser')
        commit('clearToken')
        console.log(error)
        return false
      }
    },
    async sendSms({ rootGetters }, payload) {
      try {
        const { phone, resolution } = payload
        const hostName = rootGetters.hostName
        const role = USER_ROLE_FROM_HOSTNAME_MAPPER[hostName]
        const data = new FormData()
        data.set('phone', phone)
        data.set('role', role)
        data.set('resolution', String(resolution))
        const response = await axios.post('/auth/send-sms', data)
        let msg
        try {
          msg = response.data.response.message
        } catch (e) {
          msg = 'Code sent'
        }
        this.$app.$notifySuccess(msg)
        return true
      } catch (e) {
        console.info(e)
        if (!e.handled) {
          let msg
          try {
            msg = e.response.data.error.message
          } catch (err) {
            msg = 'Verify phone error'
          }
          this.$app.$notifyDanger(msg)
        }
        return false
      }
    },
    async verifyUser(ctx, model) {
      try {
        const data = new FormData()
        data.set('phone', model.phone)
        data.set('code', model.code)
        await axios.post('/auth/verify-user', data)
        this.$app.$notifySuccess('Phone number verified')
        return true
      } catch (e) {
        console.info(e)
        if (!e.handled) {
          let msg
          try {
            msg = e.response.data.error.message
          } catch (err) {
            msg = 'Verify phone error'
          }
          this.$app.$notifyError(msg)
        }
        return false
      }
    },
    async uploadDataForRegisterExternal({ commit, dispatch, rootGetters }, model) {
      const hostName = rootGetters.hostName
      const data = prepareExternalUserData(model, hostName, rootGetters)
      if (model.fingerprintId !== FAILED_FINGERPRINT_ID) {
        data.set('visitor_id', model.fingerprintId)
      }
      const urlRole = getUrlPartByRole(hostName)
      try {
        const response = await axios.post(`auth${urlRole}/register`, data)
        const token = response.data.response.access_token
        const user = response.data.response.user
        this.$app.$notifyInfo('Registered')
        localStorage.setItem('token', token)
        commit('setUser', user)
        dispatch('updateIntercomSettings', {
          user_id: user.uuid
        })
        getFPVisitorId({ linkedId: user.id })
        return true
      } catch (e) {
        console.info(e)
        commit('clearUser')
        if (!e.handled) {
          this.$app.$notifyError(e)
        }
        return false
      }
    },
    async updateUserData({ commit, state }, userId) {
      //TODO Api v3: переписать метод auth_user, исп. 1 запрос
      try {
        const userData = axios.get('auth_user')
        const userWallet = axiosV3.get(`/users/${userId}?include=wallet`)
        const requestList = [userData, userWallet]
        const result = await Promise.allSettled(requestList)
        result[0].value.data.response.wallet = result[1].value.included.find(item => item.type === 'wallets').attributes
        if (state.user?.interface_language) result[0].value.data.response.interface_language = state.user?.interface_language
        commit('setUser', result[0].value.data.response)
      } catch (e) {
        console.info(e)
        commit('clearUser')
        if (!e.handled) {
          this.$app.$notifyError(e)
        }
      }
    },
    async sendFingerPrintVisitorId(linkedId) {
      const FPVisitorId = await getFPVisitorId({ linkedId })
      if (!FPVisitorId) return
      try {
        const data = new FormData()
        data.set('visitor_id', FPVisitorId)
        await axios.post('v2/user/fingerprint', data)
      } catch (e) {
        console.log('Ошибка при отправке visitorID:', e)
      }
    },
    changeCountry({ commit }, val) {
      commit('updateCodeCountry', val)
    },
    async initialCountry({ commit }) {
      try {
        const response = await axios.get('country')
        const countryCode = response.data.response.country_code
        const country = countryPhoneCodes.find(item => item['short_name'] === countryCode)
        commit('updateCodeCountry', `+${country.code}`)
      } catch (e) {
        commit('updateCodeCountry', '+63')
        console.log('Ошибка при определении коды страны:', e)
      }
    }
  }
}
