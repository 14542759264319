export default {
  state: {
    archive: [],
    filesDonwloadProcess: {}
  },

  getters: {
    archive: state => state.archive,
    filesDonwloadProcess: state => state.filesDonwloadProcess
  },
  mutations: {
    setArchive(state, value) {
      state.archive = value
    },
    setFilesProcess(state, payload) {
      state.filesDonwloadProcess = {
        ...state.filesDonwloadProcess,
        ...payload
      }
    }
  },
  actions: {
    async uploadFile(ctx, file) {
      const config = {
        baseURL: process.env.VUE_APP_UPLOAD_URL
      }
      try {
        const response = await axiosV3.postForm('/', {file}, config)
        return response.data.file
      } catch (e) {
        if (!e.handled) {
          this.$app.$notifyError('File uploading error')
        }
        console.info(e)
      }
    },
    // TODO: передача архива с бэка невозможна временно, поскольку структура ответа не подходит для JSON:API
    async downloadArchive({ commit, rootGetters }, path) {
      const appId = rootGetters.selectedApplication.id
      const archiveTreeLvl = !path ? 'root=1' : `starts_with=${path}`
      try {
        const response = await axios.get(`admin/applications/${appId}/source_files?${archiveTreeLvl}`)
        const notReadyYet = !path && !response.data.response.length
        commit('setArchive', notReadyYet ? null : response.data.response)
      } catch (e) {
        commit('setArchive', [])
        console.info(e)
      }
    },
    async getAppPackageVersion(ctx, url) {
      const data = new FormData()
      data.set('fileUrl', `${process.env.VUE_APP_CDN_URL}/${url}`)
      try {
        /* TODO Api v3: настроить с общим instance + настроить headers request axiosV3, чтобы запрос улетал без ошибок
           Сделан отдельный инстанс, потому что при использовании window.axios падает CORS (непонятно почему) */
        const instance = axios.create({
          baseURL: process.env.VUE_APP_PACKAGE_ANALYZER_URL,
        })
        const { data: { applicationId } } = await instance.post('Analyze', data)
        if (!applicationId) throw new Error()
        return applicationId
      } catch (e) {
        console.info(e)
        if (!e.handled) {
          this.$app.$notifyError('App package version error')
        }
        return ''
      }
    }
  }
}
