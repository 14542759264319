import { DESKTOP_APP_NAME_REGEXP, ONLY_LATIN_AND_DIGITS_REGEXP } from '@/utils/regexp'
import { PASSWORD_CONDITIONS } from '@/utils/constants'
import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";

export default {
  isAppVersion: value => {
    const EXCLUDED = [
      'new',
      'example'
    ]
    const parts = value.split('.')
    for (const part of parts) {
      if (EXCLUDED.includes(part.toLowerCase())) return false
      const match = part.match(ONLY_LATIN_AND_DIGITS_REGEXP)
      if (!match) return false
    }
    return true
  },
  isAppDesktopName: value => !value.match(DESKTOP_APP_NAME_REGEXP),
  requiredNoName: value => !!value,
  insufficientFunds: (value, { max, balance } = {}) => {
    let updateMax = (balance > max) ? false : Math.min(balance, max)
    if (!updateMax && typeof updateMax === 'boolean') {
      return true
    } else {
      return (value <= updateMax)
    }
  },
  greaterZero: value => !value.match(/^0/g),
  multipleOf10: value => !(Number(value) % 10),
  latinAndDigits: value => !!value.match(ONLY_LATIN_AND_DIGITS_REGEXP),
  url: value => {
    if (!value) return true
    try {
      return !!new URL(value)
    } catch (e) {
      return false
    }
  },
  firstChar: (value, args) => {
    const symbol = `^${args[0]}`
    const regex = new RegExp(symbol, 'g')
    return !!value.match(regex)
  },
  confirmed: (value, [target]) => value === target,
  formatPhone: value => isPossiblePhoneNumber(value) && isValidPhoneNumber(value),
  formatPasswd: value => {
    for (const condition of PASSWORD_CONDITIONS) {
      if (!condition(value)) return false
    }
    return true
  },
  phone: value => {
    return !!value.match(/^(\+7)?[\s-]?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$/)
  }
}
