export default function getMqOptions() {
  return {
    breakpoints: {
      xs: 0, // для ширины 479рх и менее        -     смартфон вертикально
      sm: 480, // для ширины от 480рх до 639рх  -     смартфон горизонтально
      md: 640, // для ширины от 640рх до 959рх  -     планшет вертикально
      lg: 960, // для ширины от 960рх до 1199рх -     планшет горизонтально
      xl: 1200, // для ширины 1200рх и более    -     десктоп
    }
  }
}
