import { createRouter, createWebHistory } from 'vue-router'
import _i18n from '@/i18n/i18n'

import initProgress from 'src/progressbar'

import getRoutes from '@/router/routes'

const i18n = _i18n.global

export default store => {

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: getRoutes(store)
  })

  router.beforeEach((to, from, next) => {
    const toAuthPage = to.matched.some(route => route.meta.auth)
    const fromAuthPage = from.matched.some(route => route.meta.auth)
    const user = store.getters.user
    const roles = to.meta.roles
    store.dispatch('cancelRequests')
    if (store.getters.activeRoleName === 'app_manager') {
      store.commit('app_manager/clearTaskListData')
    }
    store.commit('resetAllParameters')
    store.commit('setTitleText', i18n.t(to.name))

    //Если была получена 401 ошибка при любом запросе
    if (to.query.status === '401') {
      if (fromAuthPage) {
        store.$app.$notifyError('Session was over')
        return next(to.path)
      } else {
        return next(false)
      }
    }

    //Проверка аутентификации пользователя
    if (toAuthPage && !user) {
      const isSignOutPage = to.name === 'Sign out'
      if (!isSignOutPage) store.$app.$notifyInfo('NeedLogin')
      return next(`/${store.getters.locale}/login`)
    }

    //Проверка авторизации пользователя
    if (user && user.roles.length && roles && roles.length) {
      const authorization = user.roles
        .map(userRole => roles.includes(userRole.name))
        .includes(true)

      if (!authorization) {
        store.$app.$notifyError('No access')
        return next('/access-denied')
      }
    }

    return next()
  })

  initProgress(router)

  return router
}
