<template>
  <transition-fade :duration="300">
    <div
      v-if="show"
      id="modal-window-bg"
      class="modal-window-bg"
      :class="bgClass"
      @click.self="handleBgClick">
      <transition-slide appear>
        <div v-show="showDialog" class="modal-window-dialog">
          <div class="modal-window-close" @click="handleClickClose">
            <i class="nc-icon nc-simple-remove"/>
          </div>
          <transition-fade group :duration="500">
            <div
              v-if="preloading"
              key="dummy"
              :style="style"
              class="modal-window-dummy">
              <div v-for="index in 2" :key="`preloader-${index}`">
                <preloader width="100%" :row-height="60"/>
                <preloader
                  width="100%"
                  :rows="5"
                  :indent="15"
                  :margin-bottom="80"/>
              </div>
            </div>
            <div
              v-else
              key="content"
              v-loading="loading"
              class="modal-window-content"
              :style="style">
              <component
                :is="content"
                :style="contentStyle"
                v-bind="contentProps"
                @close="handleCloseEvent"
                @touched="handleTouched"
                @has-changes="handleHasChanges"/>
            </div>
          </transition-fade>
        </div>
      </transition-slide>
    </div>
  </transition-fade>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import MODAL_WINDOW_CONFIG from '@/utils/modalWindowConfig'
import Preloader from '@/components/UIComponents/Custom/Preloader'

export default {
  name: 'ModalWindow',
  components: {
    Preloader
  },
  data: () => ({
    showDialog: false,
    preloading: false,
    loading: false,
    touched: false,
    changed: false
  }),
  computed: {
    ...mapGetters(['activeRoleName']),
    ...mapGetters({
      type: 'modalWindow/type',
      preload: 'modalWindow/preload',
      callback: 'modalWindow/callback',
      show: 'modalWindow/show',
      mode: 'modalWindow/mode'
    }),
    config() {
      const roleConfig = MODAL_WINDOW_CONFIG[this.activeRoleName]
      const commonConfig = MODAL_WINDOW_CONFIG['common']
      return roleConfig[this.type] || commonConfig[this.type]
    },
    content() {
      // TODO: Передавать сюда только путь до файла. Тут вызывать defineAsyncComponent с прелоадерами в поле loadingComponent
      return this.config?.content
    },
    contentProps() {
      return this.config?.props ?? {}
    },
    style() {
      return this.config?.style ?? {}
    },
    contentStyle() {
      return this.config?.contentStyle ?? {}
    },
    bgClass() {
      const list = []
      if (this.config?.vertical) list.push(`vertical-${this.config.vertical}`)
      return list.join(' ')
    },
    showCloseConfirmDialog() {
      if (!this.config?.closeConfirm) return false
      if (this.type === 'application') return true
      return [
        this.mode === 'new' && this.touched,
        this.mode === 'edit' && this.changed
      ].some(i => i)
    }
  },
  watch: {
    async show(value) {
      this.showDialog = value
      if (value && !!this.preload) {
        this.preloading = true
        const getData = await this.preload()
        if (getData) this.preloading = false
      }
    }
  },
  methods: {
    ...mapMutations({
      closeModal: 'modalWindow/closeModal'
    }),
    async handleClickClose() {
      if (!this.showCloseConfirmDialog) {
        this.closeModal()
        return
      }
      try {
        await this.$confirm(
          'Несохраненные данные будут потеряны',
          'Закрыть форму?',
          {
            confirmButtonText: 'Закрыть форму',
            cancelButtonText: 'Отмена'
          })
        this.closeModal()
      } catch {}
    },
    handleBgClick() {
      if (this.config?.closeByOutsideClick) {
        this.handleClickClose()
      }
    },
    async handleCloseEvent() {
      if (this.callback) {
        this.loading = true
        await this.callback()
        this.loading = false
      }
      this.closeModal()
    },
    handleTouched(value) {
      this.touched = value
    },
    handleHasChanges(value) {
      this.changed = value
    }
  }
}
</script>
