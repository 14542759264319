import { PROG_LANG_FRAMEWORK_NAME_ANTIREGEX } from '@/utils/regexp'

export default class InputTransformer {
  constructor(value, methods) {
    this._value = value
    this._methods = methods.split('|')
  }

  change() {
    if (!this._methods[0]) return this._value
    return this._methods.reduce((result, methodName) => {
      const fullMethodName = methodName + 'TransformMethod'
      return InputTransformer[fullMethodName] ? InputTransformer[fullMethodName](result) : result
    }, this._value)
  }

  static onlyDigitsTransformMethod(value) {
    // Позволяет вводить только цифры, при этом не позволяет вводить ведущие нули

    if (value.match(/^0|\D+/)) return value.replace(/^0|\D+/g, '')
    return value
  }

  static onlyDigitsZeroTransformMethod(value) {
    // Позволяет вводить только цифры, включая ведущие нули

    if (value.match(/\D+/)) return value.replace(/\D+/g, '')
    return value
  }

  static onlyRussianLettersTransformMethod(value) {
    // Формат: только русские буквы и остальные знаки
    if (!value) return
    if (value.match(/[a-zA-Z]/)) {
      return value.replace(/[a-zA-Z]/g, '')
    }
    return value
  }

  static onlyLatinLettersTransformMethod(value) {
    // Формат: только латинские буквы и остальные знаки
    if (!value) return
    if (value.match(/[а-яА-Я]/)) {
      return value.replace(/[а-яА-Я]/g, '')
    }
    return value
  }

  static trimTransformMethod(value) {
    // Не позволяет ставить пробел нигде

    return value.replace(/\s+/g, '')
  }

  static onlyLatinTrimLettersTransformMethod(value) {
    // Формат: только латинские буквы и остальные знаки, кроме пробелов
    if (!value) return
    if (value.match(/[а-яА-Я]|\s+/)) {
      return value.replace(/[а-яА-Я]|\s+/g, '')
    }
    return value
  }

  static nameTransformMethod(value) {
    // Позволяет ставить пробел, но не в начале и не больше одного подряд

    const trimmedValue = value.trimStart()
    return trimmedValue.replace(/\s+/g, ' ')
  }

  static promoCodeTransformMethod(value) {
    // Позволяет вводить только латинские буквы, переводит их в верхний регистр и приклеивает решетку

    const transformedValue = value.replace(/[^a-zA-Z]+/g, '').toUpperCase()
    return transformedValue ? '#' + transformedValue : ''
  }

  static progLangTransformMethod(value) {
    // Удаляет все символы, попадающие в паттерн

    return value.replace(PROG_LANG_FRAMEWORK_NAME_ANTIREGEX, '').replace(/\s+/g, ' ').trimStart()
  }

  static versionTransformMethod(value) {
    // Позволяет вводить только цифры и точки

    return value.replace(/[^\d.]+/g, '')
  }

  static referralCodeTransformMethod(value) {
    // Формат: Английские символы в верхнем регистре и/или цифры; Длина не более 10 знаков; Без пробелов
    let upperCase = value.replaceAll(' ', '').replace(/[^A-Za-z0-9]/g, '').toUpperCase()
    if (upperCase.length > 10) upperCase = upperCase.slice(0, 10)
    return upperCase
  }
}
