// import axios from 'axios'

export default {
  state: {
    accrualsList: [],
    accrualsListPageCount: null
  },

  getters: {
    accrualsList: state => state.accrualsList,
    accrualsListPageCount: state => state.accrualsListPageCount
  },

  mutations: {
    setAccrualsList(state, value) {
      state.accrualsList = value
    },
    setAccrualsListPageCount(state, value) {
      state.accrualsListPageCount = value
    }
  },

  actions: {
    async downloadAccrualsList({ commit, rootGetters }, payload) {
      // Получение списка денежных операций

      if (!payload) return
      const { requestArgs, page, searchUser, searchTask } = payload
      const ABORT_KEY = 'accrualsList'
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })
      const searchUserArg = searchUser ? `&filter[user][id]=${searchUser}` : ''
      const searchTaskArg = searchTask ? `&filter[subtask][id]=${searchTask}` : ''
      console.log(requestArgs)
      try {
        const response = await axios.get(`admin/transactions?${requestArgs}page=${page}${searchUserArg}${searchTaskArg}`, {
          signal: rootGetters.abortControllers[ABORT_KEY].signal
        })
        const responseV3 = await axiosV3.get(`transactions?${requestArgs}include=user,subtask,deposit_type,wallet${searchUserArg}${searchTaskArg}&page[number]=${page}&page[limit]=10`, {
          signal: rootGetters.abortControllers[ABORT_KEY].signal
        })
        //const accrualsList = response.data.response.data
        const accrualsList = responseV3.data
        console.log(response.data.response.data)
        console.log(responseV3.data)
        const metaData = response.data.response.meta
        commit('setAccrualsList', accrualsList)
        commit('setAccrualsListPageCount', metaData.last_page)
        commit('deleteAbortController', ABORT_KEY)
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('Не удалось обновить список денежных операций. Попробуйте позже.')
      }
    },

    clearAccrualsListData({ commit }) {
      // Очистка списка денежных операций

      commit('setAccrualsList', [])
      commit('setAccrualsListPageCount', null)
    }
  }
}
