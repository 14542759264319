import FormDataV3 from '@/utils/formDataApiV3'
import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3";

export default {

  actions: {
    async addComment({ commit, dispatch, rootGetters }, model) {
      // Создание нового комментария к задаче

      const isTester = rootGetters.activeRoleName === 'tester'
      const taskId = isTester ? rootGetters.selectedTask.development_task_id : rootGetters.selectedTask.id
      const data = new FormDataV3()
      data.addParam('type', 'comments')

      if (model.text) {
        data.addAttr('text', model.text)
      }
      if (model.files.length) {
        data.addAttr('files', model.files)
      }
      data.addRelation('subtask', {data: {id: `${taskId}`, type: 'subtasks'}})
      const urlBuilder = new UrlBuilderApiV3()
      const url = urlBuilder
        .setEndpoint('comments')
        .addInclude('user')
        .build()
      try {
        const response = await axiosV3.post(url, data)
        const comment = response.data
        if (isTester) {
          dispatch('programmer/downloadComments')
        } else {
          commit('setTaskComments', [comment, ...rootGetters.selectedTask.comments])
        }
        this.$app.$notifySuccess('Comment added')
        return true
      } catch (e) {
        console.info(e)
        this.$app.$notifyError(e)
      }
    },

    async updateComment({ rootGetters, commit }, payload) {
      // Обновление комментария к задаче

      const { id, model } = payload
      const data = new FormDataV3
      data.addParam('type', 'comments')
      data.addParam('id', `${id}`)
      data.addAttr('text', model.text)
      if (model.files.length) {
        data.addAttr('files', model.files)
      } else {
        data.addAttr('files', [])
      }
      try {

        const response = await axiosV3.patch(`comments/${id}`, data)
        const savedComment = response.data
        const updatedComments = rootGetters.selectedTask.comments.map(item => {
          if (+item.id === +savedComment.id) {
            return {
              ...item,
              text: savedComment.text,
              files: savedComment.files
            }
          }
          return item
        })
        commit('setTaskComments', updatedComments)
        this.$app.$notifySuccess('Comment updated')
        return true
      } catch (e) {
        console.info(e)
        this.$app.$notifyError(e)
      }
    },

    async deleteComment({ rootGetters, commit }, commentId) {
      // Удаление комментария к задаче

      try {
        await axiosV3.delete(`comments/${commentId}`)
        const updatedTaskComments = rootGetters.selectedTask.comments.filter(item => item.id !== commentId)
        commit('setTaskComments', updatedTaskComments)
        this.$app.$notifySuccess('Comment deleted')
      } catch (e) {
        console.info(e)
        this.$app.$notifyError(e)
      }
    }
  }
}
