<template>
  <div class="card card-stats" :class="{[className]: !!className}">
    <slot name="full-content">
      <div class="card-body">
        <slot name="content">
          <div class="row">
            <div class="col-5">
              <slot name="header">
                <div
                  class="icon-big text-center"
                  :class="`text-${type}`">
                  <i :class="icon"/>
                </div>
              </slot>
            </div>
            <div class="col-7">
              <slot name="content">
                <div class="numbers">
                  <p class="card-category">
                    {{ smallTitle }}
                  </p>
                  <p class="card-title" :class="{loading: loading}">
                    {{ title }}
                  </p>
                  <p v-if="titleUsd" class="card-title" :class="{loading: loading}">
                    {{ titleUsd }}
                  </p>
                  <p v-if="titleUsdt" class="card-title" :class="{loading: loading}">
                    {{ titleUsdt }}
                  </p>
                </div>
              </slot>
            </div>
          </div>
        </slot>
      </div>
      <div class="card-footer">
        <hr>
        <slot name="footer"/>
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'StatsCard',
  props: {
    type: {
      type: String,
      description: 'Card text type (primary|info|danger|default|warning|success)'
    },
    title: {
      type: String,
      description: 'Card title'
    },
    titleUsd: {
      type: String,
      description: 'Card title'
    },
    titleUsdt: {
      type: String,
      description: 'Card title'
    },
    smallTitle: {
      type: String,
      description: 'Card small title'
    },
    icon: {
      type: String,
      description: 'Card icon'
    },
    loading: Boolean,
    className: String,
  }
}

</script>
<style scoped>
.card-content {
  min-height: 85px
}
</style>
