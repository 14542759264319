<template>
  <div class="card card-plain">
    <div id="headingOne" role="tab" class="card-header">
      <a
        data-toggle="collapse"
        data-parent="#accordion"
        :href="`#${itemId}`"
        :aria-expanded="active"
        :aria-controls="`content-${itemId}`"
        @click.prevent="activate">
        <slot name="title">
          {{ title }}
        </slot>
        <i class="nc-icon nc-minimal-down"/>
      </a>
    </div>
    <!--    <collapse-transition :duration="animationDuration">-->
    <transition :duration="animationDuration">
      <div
        v-show="active"
        :id="`content-${itemId}`"
        role="tabpanel"
        :aria-labelledby="title"
        class="collapsed">
        <div class="card-body">
          <slot/>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>

  export default {
    name: 'CollapseItem',
    inject: {
      animationDuration: {
        default: 250
      },
      multipleActive: {
        default: false
      },
      addItem: {
        default: () => ({})
      },
      removeItem: {
        default: () => ({})
      },
      deactivateAll: {
        default: () => ({})
      }
    },
    props: {
      title: {
        type: String,
        default: '',
        description: 'Collapse item title (text)'
      },
      id: String
    },
    data() {
      return {
        active: false
      }
    },
    computed: {
      itemId() {
        return this.id || this.title
      }
    },
    methods: {
      activate() {
        let wasActive = this.active;
        if(!this.multipleActive) {
          this.deactivateAll();
        }
        this.active = !wasActive;
      }
    },
    mounted() {
      this.addItem(this)
    },
    unmounted() {
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
      this.removeItem(this)
    }
  }
</script>
<style>
</style>
