import { defineAsyncComponent } from 'vue'

export default {
  simpleTask: {
    allowedBtns: ['add', 'filter', 'search'],
    allowedFilters: ['type', 'status'],
    allowedCheckbox: {
      name: '&filter[created_by]',
      title: 'Мои задачи',
    },
    typeModal: 'simple',
    tooltip: 'Создать новую задачу'
  },
  simpleTaskModeration: {
    allowedBtns: ['filter', 'search'],
    allowedFilters: ['statusCheck'],
    searchTypes: 'по ID паблишера, ID задачи, названию задачи'
  },
  promoCode: {
    allowedBtns: ['add', 'search'],
    typeModal: 'promoCode',
    tooltip: 'Создать новый промокод',
    searchTypes: 'по ID промокода или коду'
  },
  referralSystem: {
    allowedBtns: ['search'],
    searchTypes: 'по ID, телефону, email'
  },
  appNoDevelopNoPublish: {
    allowedBtns: ['add', 'filter', 'search'],
    allowedFilters: ['appNoDevelopNoPublish'],
    typeModal: 'applicationCollapse',
    tooltip: 'Создать приложение без публикации и разработки',
    searchTypes: 'по ID, Application package ID, названию'
  },
  appDevelopPublish: {
    allowedBtns: ['add', 'filter', 'search'],
    allowedFilters: ['appDevelopPublish'],
    typeModal: 'appDevelopPublish',
    searchTypes: 'по ID, Application package ID, названию',
    customTools: [
      defineAsyncComponent(() => import('@/components/Pages/Application/creator/SubtaskStatusFilterPane'))
    ]
  },
  archivedApplications: {
    allowedBtns: ['filter', 'search'],
    allowedFilters: ['archivedApplications'],
    typeModal: 'archivedApplications',
    searchTypes: 'по ID, Application package ID, названию',
  },
  publishersList: {
    allowedBtns: ['filter', 'search'],
    allowedFilters: ['userStatusConsole'],
    searchTypes: 'по ID, контактным данным',
    customButtons: [
      {
        name: 'ip-match',
        icon: 'fa fa-laptop',
        tooltip: 'Совпадения по IP',
        width: 350,
        popover: defineAsyncComponent(() => import('@/components/Pages/Users/app_manager/IpMatchesPanel'))
      }
    ]
  },
  appList: {
    allowedBtns: ['filter', 'search'],
    allowedFilters: ['marathon'],
    searchTypes: 'по ID, названию, Application ID',
    /* TODO: скрыть до дальнейших указаний
       customTools: [
         () => import('@/components/Pages/Application/app_manager/UpdateAll.vue')
       ] */
  },
  externalUsersList: {
    allowedBtns: ['filter', 'search'],
    allowedFilters: ['userStatusConsole', 'userRoles'],
    searchTypes: 'по ID, контактным данным'
  },
  tasks: {
    allowedBtns: ['filter', 'search'],
    allowedFilters: ['categoryApps', 'statusUpdateTask', 'typeTask'],
    allowedCheckbox: {
      name: 'filter[user][id]',
      title: 'Мои задачи',
    },
    searchTypes: 'по ID, названию, Application Package ID',
    customTools: [
      //TODO Рефакторинг: кнопку добавления оставить в компоненте Toolbar, а содержимое модалки перенести в новую модалку
      defineAsyncComponent(() => import('@/components/Pages/Tasks/app_manager/AddUpdateTask.vue'))
    ]
  },
  expenses: {
    allowedBtns: ['filter'],
    allowedFilters: ['userRoles']
  },
  simpleTaskAdmin: {
    allowedBtns: ['add', 'search'],
    typeModal: 'simple',
    tooltip: 'Создать задачу на создание Gmail аккаунтов',
  },
  simpleTaskCreAso: {
    allowedBtns: ["add", "filter", "search"],
    allowedFilters: ["statusCreAso"],
    searchTypes: "по ID задачи, названию задачи",
    typeModal: "simpleCreAso",
    tooltip: "Создать новую задачу",
  },
  simpleTasksModerationGmail: {
    allowedBtns: ['filter', 'search'],
    allowedFilters: ['statusCheck'],
    searchTypes: 'по ID паблишера'
  },
};
