export const HTTP_REGEXP = /^https?:\/\/.*/
export const AMOUNT_REGEXP = /^\d+\.?\d{0,2}$/
export const VERSION_NUMBER_REGEXP = /^(\d+\.)*\d+$/
export const APP_NAME_REGEXP = /^[a-zA-Z\d]*[a-zA-Z]+[a-zA-Z\d]*_\d{6}$/
export const PRODUCT_ID_REGEXP = /^[a-z\d._]+$/
export const PHONE_REGEXP = /^\+\d{10,16}$/
export const DESKTOP_APP_NAME_REGEXP = /[^a-zA-Zа-яёА-ЯЁ\d.\-\s]/
export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"а-яА-ЯёЁ]+(\.[^<>()\[\]\\.,;:\s@"а-яА-Я]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const ONLY_LATIN_REGEX = /^[a-zA-Z]+$/
export const ONLY_LATIN_AND_DIGITS_REGEXP = /^[a-zA-Z\d]+$/g
export const EXCEPT_LATIN_AND_DIGITS_REGEXP = /[^a-zA-Z\d]+/g
export const CITY_REGEX = /^[a-zA-Zа-яёА-ЯЁ\-\s]*$/
export const ADDRESS_REGEX = /^[a-zA-Zа-яёА-ЯЁ\d\-\/\s]*$/
export const PROG_LANG_FRAMEWORK_NAME_ANTIREGEX = /[^a-zA-Z\d\-\s]/g
export const UUID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/g
