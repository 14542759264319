<template>
  <li
    :class="{
      active: isActive,
      'user-menu': isUserNameItem
    }"
    class="sidebar-item">
    <template v-if="menu">
      <component
        :is="hasChildren ? 'a' : 'router-link'"
        :data-toggle="hasChildren ? 'collapse' : ''"
        class="sidebar-item__main-link"
        :class="{'user-name-item': isUserNameItem && user.image}"
        :to="hasChildren ? '' : link.path"
        @click.prevent="collapseMenu">
        <div
          v-if="isUserNameItem && user.image"
          class="user-avatar-wrapper">
          <img
            :src="avatarUrl"
            class="user-avatar"
            alt="">
        </div>
        <i v-else :class="link.icon"/>
        <p>
          <span
            class="sidebar-item__title-text"
            :title="isUserNameItem ? itemTitle : ''">
            {{ isUserNameItem ? itemTitle : $t(itemTitle) }}
          </span>
          <badge v-if="badgeData" tag="div" class="sidebar-item__badge">
            {{ badgeData }}
          </badge>
          <b v-if="hasChildren" class="caret" :class="{rotated: !collapsed}"/>
        </p>
      </component>
      <transition-expand v-if="hasChildren" axis="y">
        <div v-show="!collapsed" class="collapse-menu">
          <ul class="nav">
            <sidebar-item
              v-for="subLink in link.children"
              :key="subLink.name"
              :link="subLink"/>
          </ul>
        </div>
      </transition-expand>
    </template>
    <router-link
      v-else
      :to="linkItem"
      class="sidebar-item__link-text"
      :class="{active: isActive}"
      :href="linkItem"
      @click.native.stop="onItemClick">
      <template #default>
        <span v-if="wideScreen" class="sidebar-mini-icon">{{ miniIcon }}</span>
        <span class="sidebar-normal">{{ $t(link.name) }}</span>
        <badge v-if="badgeData" tag="div" class="sidebar-item__badge">
          {{ badgeData }}
        </badge>
      </template>
    </router-link>
  </li>
</template>
<script>
import { Badge } from 'src/components/UIComponents'
import { mapGetters } from 'vuex'
import { getFileUrl } from '@/utils/helpers'

export default {
  components: {
    Badge
  },
  inject: {
    mq: {
      from: 'mq'
    },
    autoClose: {
      from: 'autoClose',
      default: false
    }
  },
  props: {
    menu: {
      type: Boolean,
      default: false,
      description: 'Whether item is a menu containing multiple items'
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: '',
          path: '',
          children: []
        }
      },
      description: 'Link object'
    }
  },
  data() {
    return {
      collapsed: true
    }
  },
  computed: {
    ...mapGetters(['user', 'activeRoleName']),
    avatarUrl() {
      return getFileUrl(this.user.image)
    },
    hasChildren() {
      return this.menu && !!this.link.children && !!this.link.children.length
    },
    isActive() {
      if (!this.$route) return false
      if (this.hasChildren) {
        const fulfilPath = (pathWithParams) => {
          if (!Object.keys(this.$route.params).length) return pathWithParams
          let fulfilledPath = pathWithParams
          for (const param in this.$route.params) {
            const value = this.$route.params[param]
            fulfilledPath = fulfilledPath.replace(`:${param}`, value)
          }
          return fulfilledPath
        }
        return this.link.children.some(item => this.$route.path.startsWith(fulfilPath(item.path)))
      }
      return this.$route.name === this.link.name
    },
    miniIcon() {
      const name = this.$t(this.link.name)
      return name.substring(0, 1)
    },
    isUserNameItem() {
      return this.link.name === 'User name'
    },
    itemTitle() {
      return this.isUserNameItem ? this.user.first_name : this.link.name
    },
    badgeData() {
      const field = this.link.badgeDataStore
      if (!field) return
      const value = this.$store.getters[field]
      if (!value) return
      return `+${value}`
    },
    wideScreen() {
      return ['md', 'lg', 'xl'].includes(this.mq.current)
    },
    linkItem() {
      if (this.link.path.includes(':role')) return this.link.path.replace(':role', `${this.activeRoleName}`)
      return this.link.path
    }
  },
  methods: {
    collapseMenu() {
      this.collapsed = !this.collapsed
    },
    onItemClick() {
      if (this.link.name === 'Sign out') {
        this.logout()
        return
      }
      if (this.autoClose) {
        this.$sidebar.displaySidebar(false)
      }
    },
    async logout() {
      const locale = this.$i18n.locale
      await this.$store.dispatch('logout')
      await this.$router.push(`/${locale}/login`)
    }
  },
  mounted() {
    if (this.isActive && this.menu) {
      this.collapsed = false
    }
  }
}
</script>
<style scoped>
.caret.rotated {
  transform: rotate(180deg);
}
</style>
