import { getUserConsoleStatus, prepareUserDataV3, transformBalance } from '@/utils/helpers'
import FormDataV3 from '@/utils/formDataApiV3'
import { UrlBuilderApiV3 } from '@/utils/urlBuilderApiV3'
import _i18n from '@/i18n/i18n'

const i18n = _i18n.global

export default {
  state: {
    userList: [],
    userListPageCount: null,
    userInfo: null,
    publisherList: [],
    ipMatches: [],
    ipMatchesPageCount: null,
    isInternal: 0
  },

  getters: {
    userList: state => state.userList,
    userListPageCount: state => state.userListPageCount,
    userInfo: state => state.userInfo,
    publisherList: state => state.publisherList,
    ipMatches: state => state.ipMatches,
    ipMatchesPageCount: state => state.ipMatchesPageCount,
    isInternal: state => state.isInternal
  },

  mutations: {
    setUserList(state, value) {
      state.userList = value.map(item => {
        if (item.console && item.console.status_id) {
          item.user_console_status = getUserConsoleStatus(item.console.status_id)
        }
        return item
      })
    },
    setUserListPageCount(state, value) {
      state.userListPageCount = value
    },
    setUserInfo(state, value) {
      state.userInfo = value
    },
    setPublisherList(state, value) {
      state.publisherList = value
    },
    setIpMatches(state, value) {
      state.ipMatches = value
    },
    setIpMatchesPageCount(state, value) {
      state.ipMatchesPageCount = value
    },
    clearUserList(state) {
      state.userList = []
      state.userListPageCount = null
    },
    changeInternal(state, value) {
      state.isInternal = value
    }
  },

  actions: {
    async downloadUserList({ state, commit, rootGetters }, payload) {
      // Получение списка внешних пользователей

      const { page, searchUser, all, withConsole, isCreAso } = payload
      const globalSearch = rootGetters['listSearch']
      const filters = rootGetters['selectedFilters']
      const search = globalSearch || searchUser
      const ABORT_KEY = 'userList'
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })
      let customFilters = [
        `filter[is_internal]=${state.isInternal}`
      ].join('&')
      if (rootGetters.activeRoleName === 'app_manager' && !all) customFilters += '&filter[roles][name]=uploader'
      if (isCreAso) {
        const role = rootGetters['modalWindow/props'].role
        customFilters += `&filter[roles][name]=${role}`
      }
      if (search) customFilters += `&filter[search]=${search}`
      if (filters) customFilters += `&${filters}`
      const includeParams = [
        'roles',
        'wallets'
      ]
      if (withConsole) includeParams.push('console_status')
      const aggregates = [
        'login_details',
        'is_suspicious'
      ].join(',')
      const urlBuilder = new UrlBuilderApiV3()
      const finalUrl = urlBuilder.setEndpoint('users')
        .addFilters(customFilters)
        .addInclude(includeParams)
        .addAggregate(aggregates)
        .setPage(page)
        .build()
      try {
        const response = await axiosV3.get(finalUrl, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
        let userList = response.data
        const metaData = response.meta.page
        commit('setUserList', userList)
        commit('setUserListPageCount', metaData.last_page)
        commit('deleteAbortController', ABORT_KEY)
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError(e)
      }
    },

    async uploadEditedUser({ commit, dispatch, rootGetters }, data) {
      // Редактирование данных внешнего пользователя

      const userId = rootGetters.selectedUser.id
      const formData = prepareUserDataV3(data, userId)
      try {
        const response = await axiosV3.patch(`users/${userId}?include=wallets,roles,console_status&aggregates=login_details`, formData)
        const user = {...response.data, ...response.meta}
        if (user.console_status?.id) {
          user.user_console_status = getUserConsoleStatus(user.console_status.id)
        }
        user.infoForCard = true
        commit('setSelectedUser', user)
        dispatch('updateUserInList', user)
        this.$app.$notifySuccess('Данные пользователя успешно обновлены')
        return true
      } catch (e) {
        console.info(e)
        if (e.handled) return
        this.$app.$notifyError(e)
      }
    },
    async downloadUserData({ commit, rootGetters }, userId) {
      const ABORT_KEY = 'userData'
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })
      try {
        const urlBuilder = new UrlBuilderApiV3()
        const inclusions = [
          'roles',
          'console_status',
          'wallets',
          'programming_languages',
          'frameworks'
        ].join(',')
        let url = urlBuilder.setEndpoint(`users/${userId}`)
          .addInclude(inclusions)
          .addAggregate('login_details')
          .build()
        const response = await axiosV3.get(url, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
        const user = response.data
        if (user.console?.status_id) {
          user.user_console_status = getUserConsoleStatus(user.console.status_id)
        }
        const userFormData = {
          ...user,
          role: user.roles && user.roles.length ? user.roles[0] : '',
          infoForCard: true
        }
        commit('setSelectedUser', userFormData)
        commit('deleteAbortController', ABORT_KEY)
        return true
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('Не удалось получить данные о пользователе. Попробуйте позже')
        return false
      }
    },

    clearUserData({ commit }) {
      commit('setSelectedUser', null)
    },

    async increaseBalance({ commit, dispatch, rootGetters }, payload) {
      const { userId, amount } = payload
      const initUser = rootGetters.selectedUser
      let response
      let user
      try {
        const url = new UrlBuilderApiV3()
          .setEndpoint(`/users/${userId}/balance`)
          .addInclude('wallet')
          .build()

        const data = new FormDataV3()

        data.addParam('type', 'users')
        data.addParam('id', `${userId}`)

        data.addMeta('amount', amount)

        response = await axiosV3.post(url, data)

        const wallets = initUser.wallets.map(item => {
          //TODO api V3: убрать преобразование типов после полногот перехода на api v3
          if (+item.id === +response.data.wallet.id) {
            item.balance = response.data.wallet.balance
          }
          return item
        })

        user = {
          ...initUser,
          wallets
        }
        commit('setSelectedUser', user)
        dispatch('updateUserInList', user)
      } catch (e) {
        console.info(e)
        this.$app.$notifyError(e)
      }
    },

    async decreaseBalance({ commit, dispatch, rootGetters }, payload) {
      const { userId, amount } = payload
      const initUser = rootGetters.selectedUser
      let response
      try {
        const url = new UrlBuilderApiV3()
          .setEndpoint(`/users/${userId}/balance`)
          .addInclude('wallet')
          .build()

        const data = new FormDataV3()

        data.addParam('type', 'users')
        data.addParam('id', `${userId}`)

        data.addMeta('amount', -amount)

        response = await axiosV3.post(url, data)
        let user
        const wallets = initUser.wallets.map(item => {
          if (+item.id === +response.data.wallet.id) {
            item.balance = response.data.wallet.balance
          }
          return item
        })
        user = {...initUser, wallets}

        commit('setSelectedUser', user)
        dispatch('updateUserInList', user)
      } catch (e) {
        console.info(e)
        this.$app.$notifyError(e)
      }
    },

    updateUserInList({ state, commit, rootGetters }, updatedUser) {
      const userList = state.userList.map(user => {
        if (String(user.id) === String(updatedUser.id)) {
          updatedUser.balance = transformBalance(rootGetters.selectedUser, 'table')
          return updatedUser
        }
        return user
      })
      commit('setUserList', userList)
    },

    async resetUserPassword(ctx, id) {
      try {
        const response = await axiosV3.post(`/users/${id}/reset-password`)
        const msg = i18n.t('ResetPasswdMsg', {phone: response.data.phone})
        if (msg) {
          this.$app.$notifySuccess('Password reset')
          this.$app.$notifySuccess(msg)
        }
      } catch (e) {
        console.info(e)
        this.$app.$notifyError(e)
        return null
      }
    },

    async downloadPublisherList({ commit, rootGetters }, search) {
      const ABORT_KEY = 'publisherList'
      const controller = new AbortController()
      const storeId = rootGetters.selectedApplication.store_id
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })
      const urlBuilder = new UrlBuilderApiV3()
      urlBuilder.setEndpoint('users')
      const customFilters = [
        'filter[roles][name]=uploader',
        `filter[search]=${search}`
      ]
      if (storeId) customFilters.push(`filter[stores][id]=${storeId}`)
      const filters = customFilters.join('&')
      const finalUrl = urlBuilder.addFilters(filters).build()
      try {
        const response = await axiosV3.get(finalUrl, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
        const publishers = response.data
        commit('setPublisherList', publishers)
        commit('deleteAbortController', ABORT_KEY)
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError(e)
      }
    },

    async loadIpMatches({ commit }, payload) {
      try {
        const { page } = payload
        const response = await axiosV3.get(`ip-counts?filter[roles][name]=uploader&page[number]=${page}`)
        const ipMatchesList = response.data
        const lastPage = response.meta.page.last_page
        commit('setIpMatches', ipMatchesList)
        commit('setIpMatchesPageCount', lastPage)
      } catch (e) {
        console.info(e)
        this.$app.$notifyError('Не удалось обновить список совпадений по IP. Попробуйте позже.')
      }
    },

    clearIpMatches({ commit }) {
      commit('setIpMatches', [])
    },

    async downloadUserCheckResults({ rootGetters, commit }, onlyDuplicates = false) {
      const ABORT_KEY = 'userCheck'
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })
      try {
        let id
        try {
          if (rootGetters.selectedUser) {
            id = rootGetters.selectedUser.id
          } else {
            id = rootGetters.selectedTask.uploader_id || rootGetters.selectedTask.task.uploader.user_id
          }
        } catch {
          return
        }
        if (!id) return
        const urlBuilder = new UrlBuilderApiV3()
        const inclusions = [
          'suspected_users',
          'suspected_by_users',
        ].join(',')
        let url = urlBuilder.setEndpoint('user-analyzers')
          .addFilters(`filter[user][id]=${id}`)
          .addInclude(inclusions)
          .build()
        const responseV3 = await axiosV3.get(url, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
        commit('deleteAbortController', ABORT_KEY)
        try {
          if (!responseV3.data.length) return
          const duplicatePortfolio = responseV3.data.find(check => check.system === 'url_portfolio')
          const duplicateUsers = responseV3.data[1].suspected_by_users.concat(responseV3.data[1].suspected_users)
          const uniqDuplicateUsers = duplicateUsers.filter((obj, index) => {
            return index === duplicateUsers.findIndex(o => obj.id === o.id && obj.name === o.name);
          })
          if (!uniqDuplicateUsers.length) return
          let result = {
            name: duplicatePortfolio.name,
            showDuplicates: true,
            success: duplicatePortfolio.success,
            data: []
          };
          if (onlyDuplicates) {
            uniqDuplicateUsers.forEach(item => {
              result.data.push(item.id)
            })
            return result
          }
          result.showDuplicates = duplicatePortfolio.kind !== 'multi_account'
          if (result.showDuplicates) {
            uniqDuplicateUsers.forEach(item => {
              result.data.push(item.id)
            })
          } else {
            result.data = duplicatePortfolio.context
          }
          return result
        } catch {
        }
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('Не удалось получить данные о совпадениях с другими паблишерами. Попробуйте позже.')
      }
    }
  }
}
