export default {
  ru: {
    requiredNoName: 'Обязательно для заполнения',
    insufficientFunds: 'Недостаточно средств',
    greaterZero: 'Нельзя использовать 0 в начале',
    multipleOf10: 'Сумма должна быть кратна 10',
    latinAndDigits: 'Поле может содержать только цифры и латинские буквы',
    required: ctx => {
      const { field } = ctx
      if (field === 'Лимит') return 'Укажите лимит'
      if (field.startsWith('Время на выполнение задачи')) return 'Установите время на выполнение'
      if (field === 'Язык разработки') return 'Выберите язык разработки'
      return `Поле "${field}" обязательно для заполнения`
    },
    email: 'Поле "{field}" должно быть действительным электронным адресом',
    url: 'Поле "{field}" содержит ссылку в некорректном формате',
    regex: 'Поле "{field}" имеет некорректный формат',
    max: 'Поле "{field}" не может быть длиннее 0:{length} символов',
    max_value: ctx => {
      const {field, rule: {params}} = ctx
      if (field === 'Время на выполнение задачи (максимум 20 дней)') return 'Время не должно превышать максимум'
      return `Поле "${field}" должно быть ${params[0]} или менее`
    },
    min_value: ctx => {
      const {field, rule: {params}} = ctx
      if (field.startsWith('Время на выполнение задачи')) return 'Время должно быть не менее 1 часа'
      return `Поле "${field}" должно быть ${params[0]} или более`
    },
    min: 'Поле "{field}" не может быть менее 0:{length} символов',
    firstChar: 'Значение должно начинаться c "{params}"',
    isAppVersion: 'Поле "{field}" имеет ошибочный формат',
    isAppDesktopName: 'Поле "{field}" имеет ошибочный формат',
    confirmed:'Поле "{field}" не совпадает с полем "1:{params}"',
    formatPasswd: 'Поле "{field}" не соответствует требованиям'
  },
  en: {
    requiredNoName: 'Required to fill',
    insufficientFunds: 'Insufficient funds',
    greaterZero: 'You cannot use 0 at the beginning',
    multipleOf10: 'The amount must be a multiple of 10',
    latinAndDigits: 'The field can contain only numbers and latin letters',
    required: 'The "{field}" field is required',
    email: 'The {field} field must be a valid email',
    url: 'The "{field}" field is not a valid URL',
    regex: 'The "{field}" field format is invalid',
    max: 'The "{field}" field may not be greater than 0:{length} characters',
    max_value: 'The "{field}" field must be 0:{max} or less',
    min_value: 'The "{field}" field must be 0:{min} or more',
    min: 'The "{field}" field must be at least 0:{length} characters',
    firstChar: 'The value must start with "{params}"',
    isAppVersion: 'The "{field}" field format is invalid',
    isAppDesktopName: 'The "{field}" field format is invalid',
    confirmed:'The "{field}" field does not confirm with "1:{params}" field',
    formatPasswd: 'The "{field}" field does not meet the requirements'
  }
}
