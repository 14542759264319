import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3";

export default {
  updateUnityTaskData({ state, commit }, payload) {
    // TODO: Перенести в мутации
    const { frameworkName, model, version } = payload
    const data = { ...state.updatedUnityData }
    const newData = {
      ...data,
      [frameworkName]: {
        ...model,
        version
      }
    }
    commit('setUpdatedUnityData', newData)
  },

  async downloadComments({ commit, rootGetters }) {
    const taskId = rootGetters.selectedTask.development_task_id
    const urlBuilder = new UrlBuilderApiV3()
    const url = urlBuilder
      .setEndpoint('comments')
      .addFilters(`filter[subtask][id]=${taskId}`)
      .build()
    try {

      const result = await axiosV3.get(url)
      commit('setComments', result.data.response)
    } catch (e) {
      console.info(e)
      this.$app.$notifyError(e)
    }
  }
}
