<template>
  <div class="login-page">
    <!--    <app-navbar/>-->
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <router-view/>
          </div>
        </div>
        <div class="full-page-background" style="background-image: url(/static/img/background/background-5.jpg)"/>
        <div class="full-page-background background-freelancer" style="background-image: url(/static/img/background/background-element.png)"/>
      </div>
      <img class="logo" src="/static/img/getwork_logo/full_dark.svg" alt="">
    </div>
  </div>
</template>

<script>
// import AppNavbar from 'src/components/Layout/Navigation/AppNavbar.vue'

export default {
    name: 'LoginLayout',
/*
    components: {
      AppNavbar,
    },
*/
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
    },
  }
</script>

<style lang="scss">

</style>
