import * as Sentry from '@sentry/vue'


export default function initSentry(app, router) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    trackComponents: true,
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACE_RATE,
    environment: process.env.VUE_APP_SENTRY_ENV,
    beforeSend(event) {
      if (event.message?.match(/code 401/)) return
      return event
    },
    enabled: process.env.VUE_APP_SENTRY_ENV !== 'local'
  })
}
