<template>
  <div :class="['notifications-area__item', {'empty-prefix': !prefix.length}]">
    <div class="notifications-area__item-top">
      <div class="notifications-area__item-badge">
        <badge v-if="notice.tag" type="info" :color="badgeData.color">
          {{ $t(badgeData.title) + taskTypeTitle }}
        </badge>
      </div>
      <div class="notifications-area__item-date">
        {{ formattedDate }}
      </div>
    </div>
    <div class="notifications-area__prefix">
      <span>{{ prefix }}</span>
    </div>
    <div
      v-if="activeRoleName === 'creator' && notice.tag === 'tasks'"
      class="notifications-area__subprefix">
      <i class="fa fa-lg fa-gamepad"/>
      <block-copy
        :text="task.application?.name || task.task?.application?.name || task?.name || task?.title"/>
    </div>
    <div class="notifications-area__item-msg">
      {{ message }}
    </div>
    <div class="notifications-area__item-toolbar">
      <div v-if="closable" class="notifications-area__item-remove-btn" @click="removeNotice">
        <i class="nc-icon nc-simple-remove"/>
      </div>
      <template v-if="showLinkOnCard">
        <p-button
          v-if="historyMode"
          outline
          link
          class="notifications-area__item-link-btn history-mode"
          @click="handleDetailsBtnClick">
          <span>{{ $t('Open task') }}</span>
        </p-button>
        <p-button
          v-else
          round
          icon
          outline
          type="success"
          class="notifications-area__item-link-btn"
          title="Открыть задачу"
          @click="handleDetailsBtnClick">
          <i class="nc-icon nc-minimal-right"/>
        </p-button>
      </template>
    </div>
  </div>
</template>

<script>
import Badge from '../../UIComponents/Badge'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BlockCopy from '@/components/UIComponents/Custom/BlockCopy'
import { USER_ROLE } from '@/utils/constants'

export default {
  name: 'NotificationCard',
  components: {
    BlockCopy,
    Badge
  },
  props: {
    notice: Object,
    closable: {
      type: Boolean,
      default: true
    },
    showLink: Boolean,
    historyMode: Boolean
  },
  computed: {
    ...mapGetters(['activeRoleName', 'notificationHistory', 'selectedApplication']),
    badgeData() {
      switch (this.notice.tag) {
        case 'tasks':
          return {
            title: 'Tasks'
          }
        case 'tasksVerification':
          return {
            title: 'Verification',
            color: '#1AA05B'
          }
        case 'tasksModeration':
          return {
            title: 'Moderation',
            color: '#F68402'
          }
        case 'comment':
          return {
            title: 'Comments',
            color: '#a01a6b'
          }
        default:
          return {
            title: 'Tasks'
          }
      }
    },
    taskTypeTitle() {
      if (this.notice.tag !== 'tasks' || !this.task?.type_title || this.activeRoleName !== USER_ROLE.creator) return ''
      return ` - ${this.task.type_title}`
    },
    formattedDate() {
      const date = this.notice.created_at || this.notice.date || (this.notice.comment && this.notice.comment.created_at)
      return date ? new Date(date).toLocaleString() : ''
    },
    task() {
      return [
        this.notice.task,
        this.notice.appManager,
        (this.notice.notification && this.notice.notification.notifiable),
        this.notice.commentInfo?.subtask,
        this.notice.notifiable
        ].find(i => i)
    },
    prefix() {
      if (['tasks', 'taskModeration'].includes(this.notice.tag)) {
        return this.task.title || this.task?.name || this.notice.notifiable?.application?.name
      }
      if (this.notice.tag === 'comment') return this.notice.commentInfo?.subtask?.title || this.notice.notifiable.title
      return ''
    },
    message() {
      const msg = this.notice.message
      switch (this.notice.tag) {
        case 'comment':
          const author = this.notice.author || this.notice.user || this.notice.commentInfo?.user
          return author?.name || author?.first_name
            ? `${this.$t('New comment from user')} ${author.name || author.first_name}`
            : msg
        default:
          return msg
      }
    },
    showLinkOnCard() {
      return this.showLink && ![USER_ROLE.tester, USER_ROLE.app_manager].includes(this.task?.type)
    },
    simpleTask() {
      return ['manager_simple', 'user_simple'].includes(this.task.type)
    }
  },
  methods: {
    ...mapMutations(['setNeedShowTaskCard', 'setSelectedTaskPreview']),
    ...mapMutations({
      openModal: 'modalWindow/openModal'
    }),
    ...mapActions(['downloadTaskData', 'downloadApplication', 'downloadCheckoutResult', 'removeNotification', 'removeNotificationByTask']),
    ...mapActions({
      downloadSimpleTask: 'app_manager/downloadSimpleTask'
    }),
    removeNotice() {
      if (!this.notice.id) return
      this.removeNotification(this.notice.id)
    },
    async handleDetailsBtnClick() {
      if (!this.task) return
      const taskId = this.task.id
      this.removeNotificationByTask(taskId)
      let appId = this.task?.application?.id || this.task?.task?.application?.id
      if (this.activeRoleName === USER_ROLE.creator && !this.simpleTask) {
        this.openModal({
          type: 'task',
          mode: 'edit',
          preload: async () => {
            const payload = this.notice.notifiable?.id
              ? {checkId: `/${this.notice.notifiable?.id}`}
              : {id: this.notice.task?.application?.id}
            if (this.notice.kind === 'App\\Notifications\\CheckOutResult') await this.downloadCheckoutResult(payload)
            const taskData = this.downloadTaskData(taskId)
            const appData = this.downloadApplication(appId)
            const result = await Promise.allSettled([taskData,appData])
            return result.every(item => !!item.value)
          }
        })
      } else {
        if (this.simpleTask) {
          this.openModal({
            type: 'simpleCreAso',
            mode: 'view',
            preload: async () => {
              return this.downloadSimpleTask({id: taskId, limit: 1, creAso: true})
            }
          })
          return
        }
        const previewData = {
          id: taskId,
          title: this.task.title
        }
        if (this.activeRoleName === USER_ROLE.creator && this.task.task.application) {
          previewData.appName = this.task.task.application.name
        }
        if (this.task.status) {
          const { name, title } = this.task.status
          previewData.status = { name, title }
        }
        this.setSelectedTaskPreview(previewData)
        this.setNeedShowTaskCard(true)
        await Promise.allSettled([
          this.downloadTaskData(taskId),
          this.downloadApplication(appId)
        ])
      }
      this.$emit('open', this.notice.id)
    }
  }
}
</script>

<style scoped>

</style>
