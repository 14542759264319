import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import {
  FAILED_FINGERPRINT_ID,
  LOGIN_ROUTE_FROM_HOSTNAME_MAPPER, SIDEBAR_ROUTES_NAMES,
  TITLE_FIELD_HISTORY
} from '@/utils/constants'
import _i18n from '@/i18n/i18n'
import moment from 'moment'
import store from '@/store'
import { Reader } from '@transcend-io/conflux'
import FormDataV3 from '@/utils/formDataApiV3'
import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3";

const i18n = _i18n.global

const BASE_URL = process.env.VUE_APP_CDN_URL
const PUBLISH_CHECKLIST = [
  'name',
  'quality_type_id',
  'tag_id',
  'category_id',
  'type_application_id',
  'policy_url',
  'language_default',
  'language[',
  'version',
  'apk_production_file',
  'restriction_id',
  'answer[',
  'country_show_prices[',
  'age_limit[',
  'uploader[',
  'data_safety_file',
  'has_ad'
]
const UPDATE_CHECKLIST = [
  'language_default',
  'language[',
  'apk_production_file',
  'data_safety_file',
  'uploader[',
  'purchase[',
  'country_show_prices['
]
const UPDATE_ASO_CHECKLIST = [
  'language['
]
const APP_CHECKLIST = [
  'name',
  'category_id',
  'store_id',
  'type_application_id',
  'quality_type_id',
  'tag_id',
  'policy_url',
  'language_default',
  'language[',
  'prototype_link',
  'files[prototypes][creator][',
  'version',
  'apk_production_file',
  'programming_language_id',
  'source_code_url',
  'source_file',
  'keystore_file',
  'keystore_password',
  'sign_key_password',
  'sign_key_alias',
  'ads',
  'version_number',
  'assembly_number',
  'app_manager[priority]',
  'uploader[priority2]',
  'framework_id',
  'gitlab_project_id',
  'query',
  'data_safety_file'
]
const NO_DEVELOP_CHECKLIST = [
  'category_id',
  'type_application_id',
  'store_id',
  'policy_url',
  'restriction_id',
  'answer[',
  'country_show_prices[',
  'age_limit[',
  'uploader[',
  'version_number',
  'assembly_number',
  'store_id',
  'query',
  'data_safety_file',
  'allow_next_publication',
  'has_ad'
]
const WITH_DEVELOP_CHECKLIST = [
  'name',
  'development_at',
  'version',
  'policy_url',
  'category_id',
  'tag_id',
  'type_application_id',
  'store_id',
  'quality_type_id',
  'framework_id',
  'programmer[programming_language][',
  'language_default',
  'language[',
  'version_number',
  'assembly_number',
  'app_manager[priority]',
  'query',
  'apk_production_file',
  'keystore_file',
  'source_file',
  'keystore_password',
  'sign_key_password',
  'sign_key_alias',
  'data_safety_file',
  'allow_next_publication'
]
const UNITY_EXPORT_APP_CHECKLIST = [
  'name',
  'policy_url',
  'development_at',
  'quality_type_id',
  'tag_id',
  'type_application_id',
  'category_id',
  'language_default',
  'language',
  'version',
  'version_number',
  'assembly_number',
  '[app_manager][priority]',
  'programming_language',
  'query',
  'apk_production_file',
  'keystore_file',
  'source_file',
  'keystore_password',
  'sign_key_password',
  'sign_key_alias'
]
const LANGUAGE_CHECKLIST = [
  'title',
  'name',
  'store_name',
  'desktop_name',
  'short_description',
  'description',
  'use_default_screens',
  'phone_screenshot',
  'table_screenshot',
  'screen',
  'icon'
]
const SUBTASK_CHECKLIST = [
  'title',
  'priority',
  'description',
  'task_time',
  'cost',
  'currency',
  'task_country_show',
  'task_files',
  'is_internal'
]
const APP_MANAGER_SUBTASK_CHECKLIST = [
  'language'
]
const IMAGE_EXTENSIONS = [
  "jpeg",
  "jpg",
  "png",
  "gif",
  "webp",
  "tiff",
  "bmp",
  "heif",
  "svg",
  "apng",
  "avif"
];

export function cutBaseUrl(url) {
  if (!url) return ''
  return url.replace(BASE_URL + '/', '')
}

/*
export function cutFirstSlash(url) {
  if (!url) return ''
  return url[0] === '/' ? url.slice(1) : url
}
*/

export function addFirstSlash(url) {
  if (!url) return ''
  return url[0] === '/' ? url : `/${url}`
}

export function mapFiles(urlArray) {
  if (!urlArray) return []
  const emptyArr = [
    !urlArray.length,
    !urlArray[0],
    _.isString(urlArray[0]) && !urlArray[0].length,
    typeof urlArray[0] === 'boolean'
  ].some(i => i)
  if (emptyArr) return []
  return urlArray.map((item, index) => {
    if (_.isObjectLike(item)) {
      return item
    }
    return {
      uid: index + Date.now(),
      name: item.split('/')
        .reverse()[0],
      url: getFileUrl(item),
      label: getFileUrl(item),
      status: 'success'
    }
  })
}

function appendLanguage(language, defaultLang, data, prefix = 'language', excludeDefaultCheckbox = true) {
  language.map((langItem, langIndex) => {
    for (const field of Object.keys(langItem)) {

      if (field === 'use_default_screens') {
        if (excludeDefaultCheckbox) continue
        const value = langItem[field] ? '1' : '0'
        data.set(`${prefix}[${langIndex}][use_default_screens]`, value)
        continue
      }
      const imgFields = ['phone_screenshot', 'table_screenshot', 'screen', 'icon']

      const imgSource = imgFields.includes(field) && langItem.use_default_screens ? defaultLang : langItem
      if (imgSource[field] instanceof Array) {
        if (!imgSource[field].length || imgSource[field].every(i => !i)) {
          data.set(`${prefix}[${langIndex}][${field}][0]`, '')
        } else {
          imgSource[field].filter(i => i).map((img, idx) => {
            const result = img.url ? cutBaseUrl(img.url) : img
            data.set(`${prefix}[${langIndex}][${field}][${idx}]`, result)
          })
        }
      } else {
        let result
        if (!imgSource[field]) {
          result = ''
        } else {
          result = imgSource[field].url ? cutBaseUrl(imgSource[field].url) : imgSource[field]
        }
        data.set(`${prefix}[${langIndex}][${field}]`, result)
      }
    }
  })
}

export function preparePublishTaskData(model) {
  const data = new FormData
  const defaultLang = model.language ? model.language.find(item => item.name === model.language_default) : undefined
  const getSetter = (field, value) => {
    const setAnswer = () => {
      const nullableValue = value === 'null' || !value
      const nullableArrayValue = _.isArray(value) && (value.length === 0 || !value.some(item => !!item))
      if (nullableValue || nullableArrayValue) {
        data.set(`${field}[0]`, '')
        return
      }
      value.map((item, index) => {
        data.set(`${field}[${index}]`, item)
      })
    }
    const setArray = () => {
      if (!value) return
      if (value === 'null') {
        data.set(`${field}`, value)
        return
      }
      value.map((item, index) => {
        data.set(`${field}[${index}]`, item)
      })
    }
    const setLanguage = () => appendLanguage(model.language, defaultLang, data)
    const setUploaderArray = () => {
      if (!_.isArray(value)) return
      value.map((item, index) => {
        data.set(`uploader[${field}][${index}]`, item)
      })
    }
    const setPublishDate = () => {
      if (!value) {
        data.set(`uploader[${field}]`, '')
        return
      }
      const date = typeof value === 'object' ? value : new Date(value)
      data.set(`uploader[${field}]`, moment(date)
        .format('YYYY-MM-DD'))
    }
    const setBoolean = () => data.set(`uploader[${field}]`, value ? '1' : '0')
    const setApkFile = () => {
      let apkResult
      if (!value) {
        apkResult = ''
      } else {
        apkResult = value.url ? cutBaseUrl(value.url) : value
      }
      data.set(field, apkResult)
    }
    const setCost = () => {
      if (value === '') return
      data.set(`uploader[${field}]`, value)
    }
    const setUploaderField = () => data.set(`uploader[${field}]`, value)
    const setValueOrEmptyString = () => data.set(field, value || '')
    const setDefault = () => data.set(field, value)
    const mapper = {
      answer: setAnswer,
      age_limit: setArray,
      country_show_prices: setArray,
      language: setLanguage,
      task_country_show: setUploaderArray,
      task_os_show: setUploaderArray,
      publish_at: setPublishDate,
      is_urgent: setBoolean,
      allow_next_publication: setBoolean,
      apk_production_file: setApkFile,
      user_id: setValueOrEmptyString,
      data_safety_file: setValueOrEmptyString,
      cost: setCost,
      priority: setUploaderField,
      task_time: setUploaderField
    }
    return mapper[field] || setDefault
  }
  for (const [field, value] of Object.entries(model)) {
    const setter = getSetter(field, value)
    setter()
  }
  return data
}

export function prepareUpdateTaskData(model) {
  const data = new FormData
  const defaultLang = model.language ? model.language.find(item => item.name === model.language_default) : undefined
  const getSetter = (field, value) => {
    const setAppId = () => data.set('application[id]', value)
    const setLanguage = () => appendLanguage(model.language, defaultLang, data)
    const setUploaderField = () => data.set(`uploader[${field}]`, value)
    const setPurchaseField = () => data.set(`purchase[${field}]`, value)
    const setPublishDate = () => {
      const date = typeof value === 'object' ? value : new Date(value)
      data.set(`uploader[${field}]`, moment(date)
        .format('YYYY-MM-DD'))
    }
    const setApkFile = () => {
      data.set(field, value)
    }
    const setArray = () => {
      if (!value) return
      if (value === 'null') {
        data.set(`${field}`, value)
        return
      }
      value.map((item, index) => {
        data.set(`${field}[${index}]`, item)
      })
    }
    const setDefault = () => data.set(field, value)
    const mapper = {
      app: setAppId,
      language: setLanguage,
      task_time: setUploaderField,
      cost: setUploaderField,
      publish_at: setPublishDate,
      apk_production_file: setApkFile,
      template_name: setPurchaseField,
      product_id: setPurchaseField,
      product_name: setPurchaseField,
      product_description: setPurchaseField,
      country_show_prices: setArray,
    }
    return mapper[field] || setDefault
  }
  for (const [field, value] of Object.entries(model)) {
    if (field === 'updateType') continue
    const setter = getSetter(field, value)
    setter()
  }
  return data
}

export function compareFormData(initData, data, taskType) {
  const checkList = {
    publish: PUBLISH_CHECKLIST,
    update: UPDATE_CHECKLIST,
    app: APP_CHECKLIST,
    no_develop: NO_DEVELOP_CHECKLIST,
    with_develop: WITH_DEVELOP_CHECKLIST,
    unity_export_application: UNITY_EXPORT_APP_CHECKLIST,
    update_aso: UPDATE_ASO_CHECKLIST
  }[taskType]
  const screenshotArrays = [
    'phone_screenshot',
    'table_screenshot',
    'screen'
  ]
  let result = new FormData
  const checkArrayMethod = fName => {
    const fillList = (data, name) => {
      const list = []
      const isExcludedName = name => {
        const EXCLUDED = ['[id]', '[application_id]']
        if (['app', 'update'].includes(taskType)) {
          EXCLUDED.push('[desktop_name]')
        }
        if (taskType !== 'with_develop') {
          EXCLUDED.push('[use_default_screens]')
        }
        for (const item of EXCLUDED) {
          if (name.includes(item)) return true
        }
        return false
      }
      for (const item of data.entries()) {
        if (item[0].startsWith(name)) {
          if (name === 'language[' && isExcludedName(item[0])) continue
          list.push({
            name: item[0], value: item[1]
          })
        }
      }
      return list
    }
    const dataList = fillList(data, fName)
    const initDataList = fillList(initData, fName)
    const saveDataList = () => {
      for (const item of dataList) {
        result.set(item.name, item.value)
      }
    }
    if (dataList.length === initDataList.length || taskType === 'with_develop') {
      if (fName === 'language[') {
        const maxDataListIndexValue = dataList && dataList[dataList.length - 1].name.match(/[0-9]+/g)
        const maxInitDataListIndexValue = initDataList && initDataList[initDataList.length - 1].name.match(/[0-9]+/g)
        if (Number(maxDataListIndexValue) !== Number(maxInitDataListIndexValue)) {
          saveDataList()
          return
        }
      }
      for (const item of dataList) {
        let needSave = false
        const initItem = initDataList.find(_item => _item.name === item.name)
        if (item.name.includes('task_time')) {
          needSave = Math.abs(Number(initItem.value) - Number(item.value)) >= 60
        } else {
          needSave = !initItem || item.value !== initItem.value
        }
        if (needSave) {
          saveDataList()
          break
        }
      }
    } else {
      saveDataList()
    }
  }
  const getFieldExist = (fName, fData) => {
    let exist = false
    for (const item of fData.keys()) {
      if (item.includes(fName)) {
        exist = true
        break
      }
    }
    return exist
  }
  const hasDifferentOnArrayFields = (name, data, initData) => {
    const getValues = formData => {
      const entries = formData.entries()
      return Array.from(entries).filter(item => item[0].includes(name)).map(item => item[1])
    }
    const dataValues = getValues(data)
    const initDataValues = getValues(initData)
    return !![
      ..._.difference(dataValues, initDataValues),
      ..._.difference(initDataValues, dataValues)
    ].length
  }
  for (const fName of checkList) {
    const fieldExist = getFieldExist(fName, data)
    if (!fieldExist) continue
    if (taskType === 'unity_export_application') {
      switch (fName) {
        case 'language':
          for (let appIndex = 0; appIndex < 3; appIndex++) {
            let addLangs = false
            const prefix = `application[${appIndex}][language]`
            let langIndex = 0
            while (true) {
              let hasField = false
              for (const field of LANGUAGE_CHECKLIST) {
                const name = `${prefix}[${langIndex}][${field}]`
                if (screenshotArrays.includes(field)) {
                  if (getFieldExist(name, data) || getFieldExist(name, initData)) {
                    hasField = true
                    if (hasDifferentOnArrayFields(name, data, initData)) {
                      addLangs = true
                    }
                  }
                } else {
                  if (data.has(name) || initData.has(name)) {
                    hasField = true
                    if (data.get(name) !== initData.get(name) || !data.get(name)) {
                      addLangs = true
                    }
                  }
                }
              }
              if (!hasField || addLangs) break
              langIndex++
            }
            if (!addLangs) continue
            langIndex = 0
            while (true) {
              let hasField = false
              for (const field of LANGUAGE_CHECKLIST) {
                const name = `${prefix}[${langIndex}][${field}]`
                if (data.has(name)) {
                  hasField = true
                  result.set(name, data.get(name))
                } else {
                  /* добавление массива изображений при редактировании некоторых полей в Локализации
                  изображения остаются нетронутыми */
                  for (const i of data.entries()) {
                    if (i[0].startsWith(name)) result.set(i[0], i[1])
                  }
                }
              }
              if (!hasField) break
              result.set(`application[${appIndex}][id]`, initData.get(`application[${appIndex}][id]`))
              langIndex++
            }
          }
          break
        default:
          for (let i = 0; i < 3; i++) {
            const _name = fName.match(/\[?app_manager]?\[priority]/) ? `application[${i}]${fName}` : `application[${i}][${fName}]`
            if (fName === 'programming_language') {
              checkArrayMethod(`application[${i}][programming_language]`)
            } else if (data.has(_name) && data.get(_name) !== initData.get(_name)) {
              result.set(_name, data.get(_name))
              result.set(`application[${i}][id]`, initData.get(`application[${i}][id]`))
            }
          }
      }
      if ([...result].length) result.set('application[0][id]', initData.get('application[0][id]'))
    } else {
      const match = fName.match(/\[/)
      if (match) {
        switch (fName) {
          case 'answer[':
            if (data.has('answer[0]')) {
              checkArrayMethod(fName)
            } else if (initData.get('answer[0]') || initData.get('answer') || data.get('answer')) {
              result.set('answer[0]', '')
            }
            break
          case 'files[prototypes][creator][':
            if (data.has('files[prototypes][creator][0]')) {
              checkArrayMethod(fName)
            } else if (initData.has('files[prototypes][creator][0]')) {
              result.set('files[prototypes][creator][0]', '')
            }
            break
          default:
            checkArrayMethod(fName)
        }
      } else {
        if ((data.has(fName) && data.get(fName) !== initData.get(fName)) || fName === 'store_id') {
          result.set(fName, data.get(fName))
        }
      }
    }
  }
  if (taskType === 'with_develop') {
    const temp = {}
    for (const item of result.entries()) {
      const key = item[0]
      temp[key] = item[1]
    }
    result = new FormData()
    for (const field in temp) {
      if (field.startsWith('language[')) {
        const name = field.replace('language', 'application[language]')
        result.set(name, temp[field])
      } else if (field === 'app_manager[priority]' || field.startsWith('programmer[') || field.startsWith('programmer[programming_language][')) {
        if (field === 'app_manager[priority]') result.set('uploader[priority2]', temp[field])
        result.set(field, temp[field])
      } else {
        result.set(`application[${field}]`, temp[field])
      }
    }
  }
  return [...result].length ? result : null
}

export function prepareUserInfo(user) {
  const withdrawalWallet = user.wallets.find(item => item.slug === 'withdrawal')

  const userInfo = {
    balance: withdrawalWallet ? withdrawalWallet.balance : 0,
    ip: user.metadata ? user.metadata.ip : '-',
    user_agent: user.metadata ? user.metadata.user_agent : '-',
    image: user.image
  }
  const infoFields = ['id', 'first_name', 'email', 'phone', 'social_account', 'is_block', 'country', 'created_at']
  for (const field of infoFields) {
    if (!user[field] && user[field] !== false) {
      userInfo[field] = '-'
    } else {
      userInfo[field] = user[field]
    }
  }
  return userInfo
}

export function prepareDataForAppWithoutDev(model) {
  const data = new FormData
  const defaultLang = model.language.find(item => item.name === model.language_default)
  for (const [field, value] of Object.entries(model)) {
    switch (field) {
      case 'ads':
        const number = value ? '1' : '0'
        data.set('ads', number)
        break
      case 'additional_files':
        if (value && value.length) {
          value.map((item, index) => {
            data.set(`files[prototypes][creator][${index}]`, item)
          })
        } else {
          data.set(`files[prototypes][creator][0]`, '')
        }
        break
      case 'language':
        appendLanguage(model.language, defaultLang, data)
        break
      case 'priority':
        data.set(`app_manager[${field}]`, value)
        data.set(`uploader[${field}2]`, value)
        break
      case 'programming_language':
        if (!model.programming_language_id) {
          data.set('programming_language_id', value)
        }
        break
      case 'task':
        const mainTask = value.find(item => item.type.name === 'no_develop')
        if (!mainTask) break
        const appManagerPriority = mainTask.app_manager.priority
        if (!appManagerPriority) break
        data.set('app_manager[priority]', appManagerPriority)
        data.set('uploader[priority2]', appManagerPriority)
        break
      case 'gitlab_project_id':
        if (value) data.set(field, value)
        break
      case 'source_code_url':
        if (value) data.set(field, value)
        break
      default:
        data.set(field, value)
        break
    }
  }
  return data
}

export function prepareDataForNewAppWithDevelop(model, initModel) {
  const isEdit = !!initModel
  const data = new FormData
  for (const [field, modelValue] of Object.entries(model)) {
    switch (field) {
      case 'application':
        for (const [subField, value] of Object.entries(model['application'])) {
          let defaultLang
          switch (subField) {
            case 'language':
              defaultLang = value.find(item => item.name === modelValue.language_default)
              appendLanguage(value, defaultLang, data, 'application[language]', false)
              break
            case 'development_at':
              let date
              if (typeof value === 'object') {
                date = value
              } else {
                date = new Date(value)
              }
              data.set('application[development_at]', moment(date)
                .format('YYYY-MM-DD'))
              break
            case 'priority':
              data.set('app_manager[priority]', value)
              data.set('uploader[priority2]', value)
              break
            case 'desktop_name':
              defaultLang = initModel.language.find(item => item.name === initModel.language_default)
              defaultLang.desktop_name = value
              appendLanguage(initModel.language, defaultLang, data, 'application[language]', false)
              break
            default:
              data.set(`${field}[${subField}]`, value ?? '')
          }
        }
        break
      default:
        for (const [subField, value] of Object.entries(modelValue)) {
          switch (subField) {
            case 'framework_id':
              data.set(`application[${subField}]`, value)
              break
            case 'task_files':
              if (!value) break
              if (value.length) {
                value.map((file, idx) => {
                  data.set(`${field}[${subField}][${idx}]`, file)
                })
              } else if (isEdit) {
                data.set(`${field}[${subField}][0]`, '')
              }
              break
            case 'task_country_show':
            case 'programming_language':
              if (!value || !value.length) break
              value.map((id, index) => {
                data.set(`${field}[${subField}][${index}]`, id)
              })
              break
            case 'settings':
              if (field === 'designer') {
                for (const [settingField, settingValue] of Object.entries(value)) {
                  data.set(`${field}[${subField}][${settingField}]`, settingValue ? settingValue : '1x1')
                }
              }
              break
            default:
              if (value === undefined) break
              data.set(`${field}[${subField}]`, value)
          }
        }
    }
  }
  return data
}

export function prepareDataForAppWithDevelop(model) {
  const data = new FormData
  for (const [field, value] of Object.entries(model)) {
    switch (field) {
      case 'language':
        const defaultLang = value.find(item => item.name === model.language_default)
        appendLanguage(value, defaultLang, data, 'language', false)
        break
      case 'development_at':
        let date
        if (typeof value === 'object') {
          date = value
        } else {
          date = new Date(value)
        }
        data.set(`development_at`, moment(date)
          .format('YYYY-MM-DD'))
        break
      case 'priority':
        data.set(`app_manager[${field}]`, value)
        data.set(`uploader[${field}2]`, value)
        break
      case 'programming_language':
        const isObject = _.isObjectLike(value) && !_.isArray(value)
        if (!value || isObject) break
        value.map((id, index) => {
          data.set(`programmer[programming_language][${index}]`, id)
        })
        break
      case 'task':
        const mainTask = value.find(item => item.type.name === 'with_develop')
        if (!mainTask) break
        const appManagerPriority = mainTask.app_manager.priority
        if (!appManagerPriority) break
        data.set('app_manager[priority]', appManagerPriority)
        data.set('app_manager[priority2]', appManagerPriority)
        break
      default:
        data.set(field, value ?? '')
    }
  }
  return data
}

export function prepareDataForUnityExportApp(model, frameworks, isEdit = false, initModel = null, types = null) {
  const data = new FormData
  const preparedProgLangData = (langModel, prefix, app) => {
    for (const [langField, langValue] of Object.entries(langModel)) {
      let defaultLang
      switch (langField) {
        case 'language':
          defaultLang = langValue.find(item => item.name === langModel.language_default)
          appendLanguage(langValue, defaultLang, data, `${prefix}[${langField}]`, false)
          break
        case 'development_at':
          let date
          if (typeof langValue === 'object') {
            date = langValue
          } else {
            date = new Date(langValue)
          }
          data.set(`${prefix}[${langField}]`, moment(date)
            .format('YYYY-MM-DD'))
          break
        case 'ui_designer':
        case 'design':
        case 'designer_export':
        case 'development':
        case 'development_export_tasks':
          const subPrefix = {
            ui_designer: 'ui_designer',
            design: 'design',
            designer_export: 'design',
            development: 'development',
            development_export_tasks: 'development'
          }[langField]
          for (const [subField, value] of Object.entries(langValue)) {
            switch (subField) {
              case 'task_files':
                if (!value) break
                if (value.length) {
                  value.map((file, idx) => {
                    data.set(`${prefix}[${subPrefix}][${subField}][${idx}]`, file)
                  })
                } else if (isEdit) {
                  data.set(`${prefix}[${subPrefix}][${subField}][0]`, '')
                }
                break
              case 'task_country_show':
                if (!value || !value.length) break
                value.map((id, index) => {
                  data.set(`${prefix}[${subPrefix}][${subField}][${index}]`, id)
                })
                break
              case 'programming_language':
                break
              default:
                if (value === undefined) break
                data.set(`${prefix}[${subPrefix}][${subField}]`, value)
            }
          }
          break
        case 'priority':
          data.set(`${prefix}[app_manager][${langField}]`, langValue)
          break
        case 'desktop_name':
          defaultLang = app.language.find(item => item.name === app.language_default)
          defaultLang.desktop_name = langValue
          appendLanguage(app.language, defaultLang, data, `${prefix}[language]`, false)
          break
        case 'programming_language':
          if (langModel.programming_languages) break
        case 'programming_languages':
          if (!langValue) break
          langValue.map((lang, index) => {
            data.set(`${prefix}[programming_language][${index}]`, lang.id || lang)
          })
          break
        case 'task':
          const mainTask = langValue.find(item => item.type.name === 'unity_export_application')
          if (!mainTask) break
          const appManagerPriority = mainTask.app_manager.priority
          if (!appManagerPriority) break
          data.set(`${prefix}[app_manager][priority]`, appManagerPriority)
          break
        default:
          data.set(`${prefix}[${langField}]`, langValue)
      }
    }
  }
  for (const [fwName, value] of Object.entries(model)) {
    let development_at, framework
    const frameworkId = types ? types.frameworks_for_native.find(item => item.name === fwName).id : null
    const getApp = () => {
      if (!frameworkId || !initModel) return null
      const apps = initModel.children ? [initModel, ...initModel.children] : [initModel]
      return apps.find(app => app.framework_id === frameworkId)
    }
    const app = getApp()
    switch (fwName) {
      case 'unity':
        preparedProgLangData(value, 'application[0]', app)
        if (isEdit) break
        data.set('application[0][framework_id]', `${frameworks.find(item => item.name === 'unity').id}`)
        data.set('application[0][parent]', '1')
        value.development.programming_language.map((langId, index) => {
          data.set(`application[0][programming_language][${index}]`, langId)
        })
        break
      case 'unity_export':
        preparedProgLangData(value, 'application[1]', app)
        if (isEdit) {
          const development_at = data.get('application[0][development_at]')
          if (development_at) {
            data.set('application[1][development_at]', development_at)
          }
          break
        }
        framework = frameworks.find(item => item.name === 'unity_export')
        data.set('application[1][framework_id]', framework.id)
        development_at = data.get('application[0][development_at]')
        data.set('application[1][development_at]', development_at)
        data.set('application[1][programming_language][0]', framework.programming_languages[0].id)
        break
      case 'webgl':
        preparedProgLangData(value, 'application[2]', app)
        if (isEdit) {
          const development_at = data.get('application[0][development_at]')
          if (development_at) {
            data.set('application[2][development_at]', development_at)
          }
          break
        }
        framework = frameworks.find(item => item.name === 'webgl')
        data.set('application[2][framework_id]', framework.id)
        development_at = data.get('application[0][development_at]')
        data.set('application[2][development_at]', development_at)
        data.set('application[2][programming_language][0]', framework.programming_languages[0].id)
        break
    }
  }

  return data
}

export function checkForValidateError(error) {
  let messageObj
  try {
    messageObj = error.response.data.error.errors
    if (!messageObj) throw new Error
  } catch (e) {
    messageObj = error.response.data.error || null
  }
  if (!messageObj) return
  if (_.isString(messageObj)) return [messageObj]
  let result = []
  for (const value of Object.values(messageObj)) {
    if (!value || !value.length) continue
    if (_.isArray(value)) {
      result = result.concat(value)
    } else {
      result.push(value)
    }
  }
  if (error.response.config.url === 'v2/admin/email-send') result = createHFE(error.response, result)
  return result
}

export function checkAssignToUser(initModel, model) {
  const initUserId = initModel.user_id
  const userId = model.user_id
  return userId && initUserId !== userId ? userId : null
}

export function applyCurrencyFormat(amount) {
  const parts = amount.split('.')
  if (parts.length === 1) {
    return `${amount}.00`
  }
  if (parts.length === 2 && !parts[1]) {
    return `${parts[0]}.00`
  }
  if (parts[1].length === 1) {
    return `${amount}0`
  }
  return amount
}

export function checkFileFormat(file, types) {
  // Проверка, что файл соответствует одному из типов

  let errMsg
  const format = file.name.split('.').reverse()[0]
  const formatValid = types.map(type => type.toLowerCase()).includes(format.toLowerCase())
  if (!formatValid) {
    const typesString = types.join(', ').toUpperCase()
    errMsg = i18n.t('File type error', { name: shortenFileName(file.name, 20), typesString })
  }
  return { formatValid, errMsg }
}

export function checkFileSize(file, max) {
  // Проверка, что размер файла не превышает max МБ

  let errMsg
  const limit = max * 1000 * 1000
  const sizeValid = file.size <= limit
  if (!sizeValid) {
    errMsg = i18n.t('Wrong file size', { max: max.toFixed(0) })
  }
  return { sizeValid, errMsg }
}

export function prepareExternalUserData(model, hostName, rootGetters) {
  const data = new FormData()
  data.set('phone', rootGetters.codeCountry + model.phone)
  data.set('first_name', model.name)
  data.set('password', model.password)
  data.set('password_confirmation', model.confirm)
  data.set('social_account', model.social.startsWith('http') ? model.social : `https://${model.social}`)
  data.set('email', model.email)
  data.set('name', model.name)
  data.set('last_name', '')
  if (['designer', 'uxuidesigner'].includes(hostName)) {
    data.set('url_portfolio', model.portfolio.startsWith('http') ? model.portfolio : `https://${model.portfolio}`)
  }
  if (hostName === 'developer') {
    model.frameworks.map((item, index) => {
      data.set(`framework[${index}]`, item)
    })
    model.progLangs.map((item, index) => {
      data.set(`programming_language[${index}]`, item)
    })
  }
  return data
}

export function prepareUserData(model) {
  const data = new FormData()
  const fieldNames = [
    'phone',
    'first_name',
    'social_account',
    'email',
    'last_name',
    'role',
    'is_block',
    'task_block',
    'frameworks',
    'programming_languages',
    'ignore_user_analyser',
    'user_console_status_id',
    'allow_next_publication'
  ]
  const modelHasField = name => Object.keys(model).includes(name)
  for (const name of fieldNames) {
    if (!modelHasField(name)) continue
    if (name === 'frameworks') {
      model.frameworks.map((item, index) => {
        data.set(`framework[${index}]`, item)
      })
      continue
    }
    if (name === 'programming_languages') {
      model.programming_languages.map((item, index) => {
        data.set(`programming_language[${index}]`, item)
      })
      continue
    }
    data.set(name, typeof model[name] === 'boolean' ? `${Number(model[name])}` : model[name])
  }
  return data
}

// TODO: вернуть один метод prepareUserData, после полного перехода на api v3
export function prepareUserDataV3(model, userId) {
  const data = new FormDataV3()
  data.addParam('id', String(userId))
  data.addParam('type', 'users')
  const fieldNames = [
    'phone',
    'first_name',
    'social_account',
    'email',
    'last_name',
    'role',
    'is_block',
    'is_task_block',
    'ignore_user_analyser',
    'user_console_status_id',
    'allow_next_publication',
    'allow_google_account_verification'
  ]
  const modelHasField = name => Object.keys(model).includes(name)
  for (let name of fieldNames) {
    if (!modelHasField(name)) continue
    if (name === 'user_console_status_id') {
      data.addRelation('console_status', {data: {id: String(model[name]), type: 'user-console-statuses'}})
      continue
    }
    data.addAttr(name, model[name])
  }
  return data
}

export function prepareDataForUpdateAssemblyAsoApp(model, app) {
  const data = new FormData
  const defaultLang = app.language.find(item => item.name === app.language_default)
  for (const [field, value] of Object.entries(model)) {
    switch (field) {
      case 'designer':
      case 'programmer':
        for (const [key, item] of Object.entries(value)) {
          switch (key) {
            case 'title':
            case 'priority':
            case 'description':
            case 'cost':
            case 'currency':
            case 'is_internal':
            case 'task_time':
              data.set(`${field}[${key}]`, item)
              break
            case 'integration':
              if (item) {
                item.map((item, index) => {
                  data.set(`application[${key}][${index}]`, item)
                })
              }
              break
            case 'ual':
            case 'appmetrica':
            case 'one_signal':
            case 'version_number':
            case 'assembly_number':
              if (item) {
                data.set(`application[${key}]`, item)
              } else {
                data.set(`application[${key}]`, '')
              }
              break
            case 'task_files':
            case 'task_country_show':
              if (item) {
                item.map((item, index) => {
                  data.set(`${field}[${key}][${index}]`, item)
                })
              } else {
                data.set(`${field}[${key}][0]`, '')
              }
              break
            case 'language':
              appendLanguage(item, defaultLang, data)
              break
            default:
              data.set(key, item)
              break
          }
        }
        break
      case 'settings':
        for (const [key, item] of Object.entries(value)) {
          switch (key) {
            case 'publish_at':
              const date = new Date(item)
              data.set(`${key}`, moment(date)
                .format('YYYY-MM-DD'))
          }
        }
    }
  }
  return data
}

export function prepareDataForUpdateAsoImage(model) {
  const data = new FormData
  const defaultLang = model.language.find(item => item.name === model.language_default)
  for (const [field, value] of Object.entries(model)) {
    switch (field) {
      case 'language':
        appendLanguage(value, defaultLang, data)
        break
      case 'publish_at':
        const date = new Date(value)
        data.set(`${field}`, moment(date)
          .format('YYYY-MM-DD'))
    }
  }
  return data
}

export function getUrlPartByRole(hostname) {
  return LOGIN_ROUTE_FROM_HOSTNAME_MAPPER[hostname] || ''
}


export function getTimeValue(initValue, max) {
  const _value = `${parseInt(initValue)}`
  if (!initValue || (parseInt(initValue) < 0)) {
    return '00'
  }
  if (_value.length === 1) {
    return `0${_value}`
  }
  if (max && parseInt(initValue) > max) {
    return `${max}`
  }
  return _value
}


export function getFileUrl(src) {
  if (!src) return
  return process.env.VUE_APP_CDN_URL + addFirstSlash(src)
}


/*
export async function getFileUrlFromBase64(src) {
  const response = await fetch(src)
  const blob = await response.blob()
  return URL.createObjectURL(blob)
}
*/


export async function getFPVisitorId(options) {
  try {
    const fp = await FingerprintJS.load({
      apiKey: process.env.VUE_APP_FP_API_KEY,
      endpoint: process.env.VUE_APP_FP_ENDPOINT
    })
    const result = await fp.get(options)
    return result.visitorId
  } catch (e) {
    console.info('Не удалось получить FPVisitorId:', e)
    return FAILED_FINGERPRINT_ID
  }
}


export function prepareDataForSubtask(model, subtaskType) {
  const data = new FormData
  const checkList = {
    designer: SUBTASK_CHECKLIST, programmer: SUBTASK_CHECKLIST, app_manager: APP_MANAGER_SUBTASK_CHECKLIST
  }[subtaskType]
  const arrayFields = ['task_files', 'task_country_show', 'integration']
  const freelancerFields = ['cost', 'currency', 'task_country_show']
  const dateFields = ['publish_at']
  for (const field of checkList) {
    if (!Object.keys(model)
      .includes(field)) continue
    if (freelancerFields.includes(field) && Number(model.is_internal)) continue
    if (arrayFields.includes(field)) {
      if (model[field] && !!model[field].length) {
        model[field].map((item, index) => {
          data.set(`${subtaskType}[${field}][${index}]`, item)
        })
      } else {
        data.set(`${subtaskType}[${field}][0]`, '')
      }
      continue
    }
    if (dateFields.includes(field)) {
      continue
    }
    data.set(`${subtaskType}[${field}]`, String(model[field]))
  }
  if (model.application && !_.isEmpty(model.application)) {
    for (const [field, value] of Object.entries(model.application)) {
      if (field === 'language_default') {
        data.set('language_default', value)
        continue
      }
      if (arrayFields.includes(field)) {
        value.map((item, index) => {
          data.set(`application[${field}][${index}]`, item)
        })
        continue
      }
      if (field === 'language') {
        const languageDefault = value.find(item => item.name === model.application.language_default)
        appendLanguage(value, languageDefault, data, undefined, false)
        continue
      }
      data.set(`application[${field}]`, `${value}`)
    }
  }
  return data
}

export const PaymentCurrency = Object.freeze({
    RUB: 'rub',
    USD: 'usd',
    USDT: 'usdt',
  })

export function getPaymentLimits(currency, paymentType, countryCode) {
  if (!currency) return
  const limits = store.getters.types.payout_limit
  if (!limits || !limits.length) return
  let min, max
  const getLimitValueByName = name => Number(limits.find(item => item.name === name).value)
  const getRubLimits = () => {
    if (!paymentType) return
    const getPhoneRubLimits = () => {
      min = getLimitValueByName('payout_limit_qiwi_mc_ru_rub_min')
      max = getLimitValueByName('payout_limit_qiwi_mc_ru_rub_max')
    }
    const getBankCardRubLimits = () => {
      if (!countryCode) return
      const getRusRubLimits = () => {
        min = getLimitValueByName('payout_limit_capitalist_card_ru_rub_min')
        max = getLimitValueByName('payout_limit_capitalist_card_ru_rub_max')
      }
      const getUkrRubLimits = () => {
        min = getLimitValueByName('payout_limit_capitalist_card_ua_rub_min')
        max = getLimitValueByName('payout_limit_capitalist_card_ua_rub_max')
      }
      const getKazRubLimits = () => {
        min = getLimitValueByName('payout_limit_capitalist_card_kz_rub_min')
        max = getLimitValueByName('payout_limit_capitalist_card_kz_rub_max')
      }
      const getRubLimitsByCountryCode = {
        ru: getRusRubLimits, ua: getUkrRubLimits, kz: getKazRubLimits
      }[countryCode]
      getRubLimitsByCountryCode()
    }
    const getQiwiRubLimits = () => {
      min = getLimitValueByName('payout_limit_qiwi_qiwi_ru_rub_min')
      max = getLimitValueByName('payout_limit_qiwi_qiwi_ru_rub_max')
    }
    const getRubLimitsForPaymentType = {
      mc: getPhoneRubLimits, card_payout: getBankCardRubLimits, qiwi: getQiwiRubLimits
    }[paymentType]
    getRubLimitsForPaymentType()
  }
  const getUsdLimits = () => {
    const getBankCardUsdLimits = () => {
      if (!countryCode) return
      const getRusUsdLimits = () => {
        min = getLimitValueByName('payout_limit_capitalist_card_ru_usd_min')
        max = getLimitValueByName('payout_limit_capitalist_card_ru_usd_max')
      }
      const getUkrUsdLimits = () => {
        min = getLimitValueByName('payout_limit_capitalist_card_ua_usd_min')
        max = getLimitValueByName('payout_limit_capitalist_card_ua_usd_max')
      }
      const getKazUsdLimits = () => {
        min = getLimitValueByName('payout_limit_capitalist_card_kz_usd_min')
        max = getLimitValueByName('payout_limit_capitalist_card_kz_usd_max')
      }
      const getDefaultUsdLimits = () => {
        min = getLimitValueByName('payout_limit_capitalist_card_all_usd_min')
        max = getLimitValueByName('payout_limit_capitalist_card_all_usd_max')
      }
      const getUsdLimitsForCountryCode = {
        ru: getRusUsdLimits, ua: getUkrUsdLimits, kz: getKazUsdLimits
      }[countryCode] || getDefaultUsdLimits
      getUsdLimitsForCountryCode()
    }
    const getPaytmUsdLimits = () => {
      min = getLimitValueByName('payout_limit_capitalist_paytm_all_usd_min')
      max = getLimitValueByName('payout_limit_capitalist_paytm_all_usd_max')
    }
    const getUsdLimitsForPaymentType = {
      card_payout: getBankCardUsdLimits, paytm: getPaytmUsdLimits
    }[paymentType]
    getUsdLimitsForPaymentType()
  }
  const getUsdtLimits = () => {
    const getUsdtTrc20UsdLimits = () => {
      min = getLimitValueByName('payout_limit_capitalist_usdttrc20_all_usdt_min')
      max = getLimitValueByName('payout_limit_capitalist_usdttrc20_all_usdt_max')
    }
    const getUsdtLimitsForPaymentType = {
      usdttrc20: getUsdtTrc20UsdLimits
    }[paymentType]
    if (!getUsdtLimitsForPaymentType) return
    getUsdtLimitsForPaymentType()
  }
  const getLimitsByCurrency = {
    rub: getRubLimits, usd: getUsdLimits, usdt: getUsdtLimits
  }[currency]
  getLimitsByCurrency()
  return { min, max }
}

export function shortenFileName(fileName, max) {
  if (fileName.length <= max) return fileName
  const parts = fileName.split('.')
  const name = parts[0]
  const format = parts[1]
  const shortName = name.slice(0, max - format.length - 3)
  return `${shortName}...${format}`
}

export function checkNotificationsAndSetBadge(taskList, notifications, tag) {
  const badgedTasksId = notifications
    .filter(i => i.tag === tag)
    .map(i => i.taskId)
  if (!badgedTasksId.length) return taskList
  const badge = {
    verification: 'new'
  }[tag]
  if (!badge) return taskList
  return taskList.map(task => {
    badgedTasksId.includes(task.id) && (task.badge = badge)
    return task
  })
}

export function updateTableHeight(cssTableClass) {
  const tableBody = document.querySelector(`.${cssTableClass} .el-table__body`)
  if (tableBody && tableBody.offsetHeight > document.body.clientHeight - 298) {
    return String(document.body.clientHeight - 240)
  } else {
    return ''
  }
}

export function addCustomScroll(cssTargetClass) {
  const tableBody = document.querySelector(`.${cssTargetClass} .el-table__body-wrapper`)
  tableBody.classList.add('custom-scroll')
}

export function isModeratedTask() {
  const statusKeys = ['completed', 'failed']
  const status = store.getters['selectedTask'].status.name
  return statusKeys.includes(status)
}

export function getUuidV4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    let r = Math.random() * 16 | 0
    // eslint-disable-next-line no-bitwise,no-mixed-operators
    let v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export function compareStringArrays(initArray, array) {
  /*
    Сравнивает два массива строк.
    Возвращает false, если содержимое совпадает, и true - если есть различия (в том числе в последовательности)
  */

  if (initArray.length !== array.length) return true
  const toString = arr => arr.join('')
  return toString(initArray) !== toString(array)
}

export function prepareLanguage(model, defaultLangName) {
  // Преобразует у объекта поле language - выбрасывает ненужные поля, заполняет графику из локали по умолчанию

  const defaultLang = model.language.find(l => l.name === defaultLangName)
  model.language = model.language.map(l => {
    let {
      title,
      name,
      store_name,
      short_description,
      description,
      phone_screenshot,
      table_screenshot,
      screen,
      icon
    } = l
    const isDefault = name === model.language_default
    if (!isDefault && l.use_default_screens) {
      phone_screenshot = defaultLang.phone_screenshot
      table_screenshot = defaultLang.table_screenshot
      screen = defaultLang.screen
      icon = defaultLang.icon
    }
    return {
      title,
      name,
      store_name,
      short_description,
      description,
      phone_screenshot,
      table_screenshot,
      screen,
      icon
    }
  })
}

export function transformObjectToFormData(model) {
  /*
    Преобразует объект в FormData (в том числе объекты с вложенными массивами и объектами)
    Возвращает новый экземпляр FormData
  */

  const data = new FormData()
  const setStringField = (key, value) => {
    data.set(key, value)
  }
  const setNumberField = (key, value) => {
    data.set(key, String(value))
  }
  const setArrayField = (key, value) => {
    value.map((item, index) => {
      setField(`${key}[${index}]`, item)
    })
  }
  const setObjectField = (key, value) => {
    for (const [field, item] of Object.entries(value)) {
      setField(`${key}[${field}]`, item)
    }
  }
  const setField = (key, value) => {
    const setters = [
      {
        condition: value => _.isString(value),
        method: setStringField
      },
      {
        condition: value => _.isNumber(value),
        method: setNumberField
      },
      {
        condition: value => _.isArray(value),
        method: setArrayField
      },
      {
        condition: value => _.isPlainObject(value),
        method: setObjectField
      }
    ]
    const setter = setters.find(i => i.condition(value))
    if (!setter) return
    setter.method(key, value)
  }

  for (const [key, value] of Object.entries(model)) {
    setField(key, value)
  }

  return data
}

export function renameFields(data, options) {
  /*
    Изменяет ключи, взятые из data: FormData, в соответствии с параметром
    options: { oldKey1: 'newKey1', oldKey2: 'newKey2',.. }
    и возвращает result: FormData с новыми ключами и старыми значениями
  */

  const result = new FormData()
  for (const [key, value] of data.entries()) {
    const match = key.match(/\[\d+]$/)
    const cutKey = match ? key.replace(/\[\d+]$/, '') : key
    const newKey = options[cutKey] ? options[cutKey] + match[0] : key
    result.set(newKey, value)
  }
  return result
}

export function getShareAppLink(type, id, name, flags, source, args, publicLinkId) {
  const baseUrl = process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')
  const linkType = {
    Public: 'public-share',
    Internal: 'share'
  }[type]
  if (!linkType) return
  const linkArgs = [`id=${publicLinkId}`]
  if (type === 'Public') {
    if (args) linkArgs.push(args)
    if (flags) linkArgs.push(`f=${flags}`)
    if (source) linkArgs.push(`s=${source}`)
  }
  const strLinkArgs = linkArgs.length ? linkArgs.join('&') : ''
  return `${baseUrl}/application/${linkType}?app=${id}&n=${name}&${strLinkArgs}`
}

export async function getInternalLinksForApp(id, oldLink) {
  let internalUrlLink = `applications/${id}/zip/internal`
  if (!oldLink) {
    internalUrlLink = new UrlBuilderApiV3()
      .setEndpoint(`application-links/${id}`)
      .addAggregate('links')
      .build()
  }
  try {
    const response = oldLink
      ? await axios.get(internalUrlLink)
      : await axiosV3.get(internalUrlLink)
    const links = oldLink
      ? response.data.response
      : response.data.meta.links
    const otherKey = oldLink ? 'other' : 'all'
    return {
      source: links.source === undefined ? 'error' : links.source,
      other: links[otherKey] === undefined ? 'error' : links[otherKey],
      info: links.info === undefined ? 'error' : links.info
    }
  } catch (e) {
    console.info('Ошибка в методе getInternalLinksForApp:', e)
    store.$app.$notifyError('An error occurred while searching for files. Try again later.')
    return { source: 'error', other: 'error' }
  }
}

export async function getLinkForPublicAppLink(id, { flags, source }) {
  const data = new FormDataV3()
  data.addParam('type', 'application-links')
  flags && data.addAttr('flags', flags)
  source && data.addAttr('source', Boolean(source))
  data.addRelation('application', {data: {id: String(id), type: 'applications'}})
  const ABORT_KEY = 'publicLink'
  const controller = new AbortController()
  if (store.getters.abortControllers[ABORT_KEY]) {
    store.getters.abortControllers[ABORT_KEY].abort()
  }
  store.commit('setAbortController', { controller, name: ABORT_KEY })
  const url = new UrlBuilderApiV3()
    .setEndpoint('application-links')
    .addAggregate('links')
    .build()
  try {
    const { data: { meta, id } } = await axiosV3.post(url, data, {
      signal: store.getters.abortControllers[ABORT_KEY].signal
    })
    store.commit('deleteAbortController', ABORT_KEY)
    return {link: meta.links.manual, id}
  } catch (e) {
    if (e.handled || e.code === 'ERR_CANCELED') return
    console.info('Ошибка в методе getLinkForPublicAppLink:', e)
    store.$app.$notifyError('При создании ссылки произошла ошибка. Попробуйте позже.')
    return null
  }
}

export async function getLinkForInternalAppLink(id) {
  const data = new FormDataV3()
  data.addParam('type', 'application-links')
  data.addAttr('is_internal', true)
  data.addAttr('source', true)
  data.addRelation('application', {data: {id: String(id), type: 'applications'}})
  const ABORT_KEY = 'internalLink'
  const controller = new AbortController()
  if (store.getters.abortControllers[ABORT_KEY]) {
    store.getters.abortControllers[ABORT_KEY].abort()
  }
  store.commit('setAbortController', { controller, name: ABORT_KEY })
  const url = new UrlBuilderApiV3()
    .setEndpoint('application-links')
    .addAggregate('links')
    .build()
  try {
    const { data: { id } } = await axiosV3.post(url, data, {
      signal: store.getters.abortControllers[ABORT_KEY].signal
    })
    store.commit('deleteAbortController', ABORT_KEY)
    return {id}
  } catch (e) {
    if (e.handled || e.code === 'ERR_CANCELED') return
    console.info('Ошибка в методе getLinkForPublicAppLink:', e)
    store.$app.$notifyError('При создании ссылки произошла ошибка. Попробуйте позже.')
    return null
  }
}

export async function getPublicLinkForApp({ app, id, signature }) {
  let url = `applications/${app}/zip/public?id=${id}&signature=${signature}`
  if (!signature) {
    url = new UrlBuilderApiV3()
      .setEndpoint(`application-links/${id}`)
      .addAggregate('links')
      .build()
  }
  try {
    const response = signature
      ? await axios.get(url)
      : await axiosV3.get(url)
    const links = signature
      ? response.data.response
      : response.data.meta.links
    return signature
      ? {
          source: links.source === undefined ? 'error' : links.source,
          public: links.url === undefined ? 'error' : links.url
        }
      : {
          source: !links.source ? 'error' : links.source,
          public: !links.manual ? null : links.manual
        }
  } catch (e) {
    console.info('Ошибка в методе getPublicLinkForApp:', e)
    store.$app.$notifyError('An error occurred while searching for files. Try again later.')
    return { source: 'error', public: 'error' }
  }
}

export function cutDomain(url) {
  if (!url || !url.startsWith('http')) return url
  const linkWithoutProtocol = url.split('//')[1]
  return linkWithoutProtocol.split('/').filter((item, idx) => !!idx).join('/')
}

export function getUserConsoleStatus(id) {
  const consoleStatus = store.getters.types.user_console_status
  return consoleStatus.find(status => String(status.id) === String(id))
}

export async function checkArchive(payload) {
  const {file, ext} = payload
  const regexp = new RegExp(`\\.${ext}`)
  const reader = new Reader(file)
  let files = []
  for await (const entry of reader) {
    if (entry.name.match(regexp) && !entry.name.includes('__MACOSX')) {
      files.push(`${file.name}/${entry.name}`)
    }
  }
  return files
}

function createHFE(response, msgArr) {
  const singleMistake = msgArr.length === 1
  let result = []
  for (let event of response.config.data.entries()) {
    if (!event[0].includes('events')) continue
    const regexp = /(\[)(\d+)(])/
    const transformKeyEvent = event[0].replace(regexp, '.$2')
    let msgErr = msgArr.find(msg => msg.includes(transformKeyEvent))
    if (msgErr) result.push(event[1])
  }
  return [`Нет шаблон${singleMistake ? 'а' : 'ов'} для событи${singleMistake ? 'я' : 'й'} ${result.join(', ')}`]
}

export function sortData(data) {
  return data.sort(function (a, b) {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    return 0;
  });
}

export function setFieldToTypesInLS(fieldName, value) {
  const localTypes = localStorage.getItem('types')
  if (!localTypes) return
  const parsedTypes = JSON.parse(localTypes)
  parsedTypes[fieldName] = value
  localStorage.setItem('types', JSON.stringify(parsedTypes))
}

export function capitalizeFirstChar (value) {
  return value[0].toUpperCase() + value.slice(1)
}

export function openLinkInNewTab (link) {
  let element = document.createElement('a')
  element.setAttribute('href', link)
  element.setAttribute('target', '_blank')
  element.setAttribute('rel', 'noopener noreferrer')
  element.click()
  element = null
}

export function sortHistory (direction) {
  const historyList = store.getters['app_manager/historyChanges']
  const directionIndex = direction === 'bottom' ? -1 : 1
  historyList.sort((a, b) => sortChanges(a, b, directionIndex))
}

function sortChanges(a, b, directionIndex) {
  if (moment(a.created_at).diff(moment(b.created_at)) === 0) {
    return ((a.id - b.id) * directionIndex)
  }
  return ((moment(a.created_at) - moment(b.created_at)) * directionIndex)
}

export function transformChanges(historyList) {
  return historyList.reduce((prev, item) => {
    return [...prev, ...createArr(item)]
  }, [])
}

function createArr(changes) {
  let result = []
  if (changes.event !== 'updated' && !changes.new.type) return [{...changes, id: changes.id}]
  if (!changes.new) return []
  for (const [key, value] of Object.entries(changes.new)) {
    const skipChange = [
      ['version', 'updated_at'].includes(key),
      !Object.keys(TITLE_FIELD_HISTORY).includes(key)
    ].some(i => i)
    if (skipChange) continue
    delete changes.new
    const obj = {
      id: changes.id,
      ...changes,
      title: key,
      value
    }
    const isEmptyOld = !obj.old ? false : obj.old[key]
    if (!isEmptyOld) {
      obj.event = 'created'
      obj.new = {
        [key]: value
      }
    }
    result.push(obj)
  }
  return result
}

export function getElementFromTypes(field, id) {
  const types = store.getters.types
  return types[field].find(item => item.id === id)
}

export const transformBalance = (selectedUser, displayType = 'modal') => {
  const getWallet = nameCurrency => selectedUser.wallets.find(item => item.name === nameCurrency)
  const withdrawal = getWallet('withdrawal')
  if (withdrawal) {
    return withdrawal.balance
  }
  return getWallet('RUB').balance
}

export async function searchTask (payload, searchById = true) {
  const {id, title} = payload
  const searchParam = searchById ? id : title
  let response = await axios.get(`app_manager/tasks?search=${searchParam}`)
  const listTasks = response.data.response.data
  const targetTask = listTasks.find(task => task.id === Number(id) && task.title === title)
  if (!targetTask) {
    return searchTask(payload, false)
  } else {
    return targetTask
  }
}

export function checkAllowedRoute(actualRoute, listResponseId, key) {
  if (!listResponseId) return
  const transformRouteName = actualRoute.replaceAll('_', ' ')
  const allowRoutesNames = typeof SIDEBAR_ROUTES_NAMES[key] === 'string'
    ? [SIDEBAR_ROUTES_NAMES[key]]
    : Object.values(SIDEBAR_ROUTES_NAMES[key])
      .map(name => name.replaceAll(' ', '_'))
  return allowRoutesNames.includes(transformRouteName) || allowRoutesNames.includes(listResponseId.replace(/\d/g, ''))
}
export function trimText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substr(0, maxLength) + '...';
      }
      return text;
    }
export function isImageUrl(url) {
    const urlExtension = url.split('.').pop().toLowerCase();
    return IMAGE_EXTENSIONS.includes(urlExtension);
}

export function getStoreLogo(app) {
  const gpLogo = '/static/img/google_play_logo.png'
  const asLogo = '/static/img/app_store_logo.png'
  return app.store.name === 'google_play' ? gpLogo : asLogo
}

export function getNotificationTag(notice) {
      if (notice.tag !== 'comment') return notice.tag
      const type = notice.commentInfo?.subtask?.type
      switch (true) {
        case (type === 'user_simple'):
          return 'simpleTaskCreAso'
        case (['designer', 'ui_designer', 'programmer'].includes(type)):
          return 'tasks'
        default:
          return notice.tag
  }
}

export function parseQueryStringToObject(queryString) {
  let obj = {};
  queryString.split('&').forEach(function(part) {
    let [key, value] = part.split('=');
    let keys = key.split(/\[|\]/).filter(Boolean);
    keys.reduce((o, k, i) => {
      o[k] = keys.length === i + 1 ? decodeURIComponent(value) : o[k] || {};
      return o[k];
    }, obj);
  });
  return obj;
}
