import actions from '@/store/creator/actions'
import getters from '@/store/creator/getters'
import mutations from '@/store/creator/mutations'

const state = {
  applications: [],
  applicationsWithTasks: [],
  applicationsListPageCount: undefined,
  needReloadAppList: false,
  addingAppType: undefined,
  isOtherCreator: false,
  subtaskStats: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
