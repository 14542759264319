import { CREATOR_FAST_STATUSES, DEFAULT_CREATOR_FAST_STATUSES, FIELDS_WITH_SUBTASK } from '@/utils/constants'

export default {
  setApplications(state, value) {
    state.applications = value
  },
  setApplicationsListPageCount(state, value) {
    state.applicationsListPageCount = value
  },
  setApplicationsWithTasks(state, value) {
    state.applicationsWithTasks = value
  },
  updateTaskStatusesForApp(state, payload) {
    const { appId, taskStatuses } = payload
    const app = state.applications.find(item => item.id === appId)
    if (!app.task) return
    for (const taskStatus of taskStatuses) {
      const { taskId, statusName } = taskStatus
      const task = app.task.find(item => item.id === taskId)
      if (!task) continue
      task.status_name = statusName
    }
  },
  setNeedReloadAppList(state, value) {
    state.needReloadAppList = value
  },
  setAddingAppType(state, value) {
    state.addingAppType = value
  },
  setOtherCreator(state, value) {
    state.isOtherCreator = value
  },
  setSubtaskStats(state, value) {
    if (!value) {
      state.subtaskStats = []
      return
    }
    const localFastStatuses = localStorage.getItem('creatorFastStatuses')
    const fastStatuses = localFastStatuses ? JSON.parse(localFastStatuses) : DEFAULT_CREATOR_FAST_STATUSES

    state.subtaskStats = value
      .filter(item => CREATOR_FAST_STATUSES.includes(item.name))
      .map(stat => {
        stat.fast = fastStatuses.includes(stat.name)
        return stat
      })
  },
  toggleFastStatus(state, name) {
    const foundStatus = state.subtaskStats.find(item => item.name === name)
    const value = !foundStatus.fast
    const localFastStatuses = localStorage.getItem('creatorFastStatuses')
    let fastStatuses = localFastStatuses ? JSON.parse(localFastStatuses) : DEFAULT_CREATOR_FAST_STATUSES
    if (value) {
      fastStatuses.push(name)
    } else {
      fastStatuses = fastStatuses.filter(item => item !== name)
    }
    localStorage.setItem('creatorFastStatuses', JSON.stringify(fastStatuses))
    foundStatus.fast = value
  },
  updateTaskStatus(state, payload) {
    let foundSubtask
    const { appId, mainTaskId, mainTaskStatusId, subtaskId, subtaskStatus } = payload
    const foundAppWithTasks = state.applicationsWithTasks.find(app => app.id === appId)
    const foundMainTask = foundAppWithTasks.task.find(item => item.id === mainTaskId)

    foundMainTask.status_id = mainTaskStatusId
    for (const field of FIELDS_WITH_SUBTASK) {
      if (foundAppWithTasks.task[0][field].id !== subtaskId) continue
      foundSubtask = foundAppWithTasks.task[0][field]
      break
    }
    foundSubtask.status = { ...subtaskStatus }
  }
}
