<template>
  <div :id="wizardId" class="wizard-container">
    <div class="card card-wizard active" :class="[wizardClasses, {'card-transparent': plain}]">
      <form @submit.prevent>
        <!--        You can switch " data-color="primary" "  with one of the next bright colors: "green", "orange", "red", "blue"       -->
        <div class="card-header text-center" :class="{shadow: shadowHeader}">
          <slot v-if="showHeader && !$slots.header" name="header">
            <h6 v-if="title" class="card-title">
              {{ title }}
            </h6>
            <h6 v-if="subTitle" class="description">
              {{ subTitle }}
            </h6>
          </slot>

          <div class="wizard-navigation">
            <ul class="nav nav-pills" role="tablist">
              <li
                v-for="(tab, index) in tabs"
                :id="`step-${tab.tabId}`"
                :key="tab.title"
                :ref="`tab-${index}`"
                role="tab"
                :tabindex="tab.checked ? 0 : ''"
                :aria-controls="tab.tabId"
                :aria-disabled="tab.active"
                :aria-selected="tab.active"
                :class="['nav-item', 'wizard-tab-link', {active: tab.active}]"
                :style="linkWidth">
                <a
                  class="nav-link"
                  :class="[
                    {'disabled-wizard-link': !tab.checked && !allTabsEnabled},
                    {checked: tab.checked || allTabsEnabled}]"
                  data-toggle="tab"
                  @click="navigateToTab(index)">
                  <tab-item-content :tab="tab"/>
                </a>
              </li>
            </ul>
            <div
              v-if="activeTab && showActiveTab"
              class="moving-tab"
              :class="{'error-link': activeTab.hasError}"
              :style="movingTabStyles">
              <tab-item-content :tab="activeTab" :moving-tab="true"/>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <slot
              :active-index="activeTabIndex"
              :active-tab="activeTab"/>
          </div>
          <resize-observer @notify="setShadows"/>
        </div>

        <div class="card-bottom" :class="{shadow: shadowFooter}"/>

        <div class="card-footer">
          <slot
            name="footer"
            :next-tab="nextTab"
            :prev-tab="prevTab">
            <!-- TODO: сделать для всех мест 3 кнопки где используется только 1 вариант, как для условия isCreatorCreateApp -->
            <slot v-if="isCreatorCreateApp" name="createApp">
              <p-button
                wide
                class="btn-previous"
                :disabled="activeTabIndex === 0"
                @click.native="prevTab">
                <i class="fa fa-chevron-left" aria-hidden="true"/>
              </p-button>
              <p-button
                wide
                class="btn-next"
                :disabled="activeTabIndex === tabCount -1"
                @click.native="nextTab">
                <i class="fa fa-chevron-right"/>
              </p-button>
              <p-button
                wide
                type="info"
                class="btn-final"
                :disabled="finishBtnDisable"
                @click.native="$emit('complete')">
                {{ finishButtonText }}
              </p-button>
            </slot>
            <slot v-else name="defaultBtn">
              <div class="pull-right">
                <slot name="nextBtn">
                  <p-button
                    v-if="activeTabIndex < tabCount -1"
                    wide
                    class="btn-next"
                    @click.native="nextTab">
                    {{ nextButtonText }}
                  </p-button>
                  <p-button
                    v-else-if="!hideFinishButton"
                    wide
                    type="info"
                    :disabled="finishBtnDisable"
                    @click.native="nextTab">
                    {{ finishButtonText }}
                  </p-button>
                </slot>
              </div>

              <div class="pull-left">
                <p-button
                  v-if="activeTabIndex > 0"
                  wide
                  class="btn-previous"
                  @click.native="prevTab">
                  {{ prevButtonText }}
                </p-button>
              </div>
            </slot>
            <slot name="additionalOptions"/>
          </slot>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { throttle } from 'src/utils/throttle'
import { h } from 'vue'

function randomString(maxChars = 7) {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  let text = ''

  for (let i = 0; i < maxChars; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))

  return text
}

export default {
  name: 'SimpleWizard',
  components: {
    TabItemContent: {
      props: ['tab', 'movingTab'],
      render() {
        return h('span', [this.tab.$slots.label() || this.tab.label])
      }
    }
  },
  provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab
    }
  },
  inject: ['mq'],
  props: {
    wizardClasses: {
      type: [String, Object, Array],
      description: 'Wizard card classes'
    },
    plain: {
      type: Boolean,
      default: false,
      description: 'Whether wizard should be on plain background'
    },
    showHeader: {
      type: Boolean,
      default: true,
      description: 'Whether Wizard header should be displayed'
    },
    startIndex: {
      type: Number,
      default: 0,
      description: 'Wizard start index (activated tab to start with)'
    },
    title: {
      type: String,
      default: '',
      description: 'Wizard title'
    },
    subTitle: {
      type: String,
      default: '',
      description: 'Wizard sub title'
    },
    prevButtonText: {
      type: String,
      default: 'Назад',
      description: 'Previous button text'
    },
    nextButtonText: {
      type: String,
      default: 'Дальше',
      description: 'Next button text'
    },
    finishButtonText: {
      type: String,
      default: 'Создать',
      description: 'Finish button text'
    },
    vertical: {
      type: Boolean,
      description: 'Whether wizard tabs should be vertical'
    },
    allTabsEnabled: {
      type: Boolean,
      default: false,
      description: 'Render wizard which has all tabs available'
    },
    finishBtnDisable: {
      type: Boolean,
      default: false
    },
    hideFinishButton: {
      type: Boolean,
      default: false
    },
    handleSendBtn: {
      type: Function,
      default: () => ({})
    },
    isCreatorCreateApp: {
      type: Boolean,
      default: false
    },
    frozen: {
      type: Boolean,
      default: false,
      description: 'If set to true, current step can\'t be changed'
    }
  },
  data() {
    return {
      tabs: [],
      activeTabIndex: 0,
      tabLinkWidth: 0,
      tabLinkHeight: 50,
      wizardId: randomString(),
      shadowHeader: false,
      shadowFooter: false
    }
  },
  computed: {
    tabCount() {
      return this.tabs.length
    },
    linkWidth() {
      let width = 100
      if (this.tabCount > 0) {
        width = 100 / this.tabCount
      }
      if (this.vertical) {
        width = 100
      }
      return { width: `${width}%` }
    },
    activeTab() {
      return this.tabs[this.activeTabIndex]
    },
    movingTabStyles() {
      let translateXValue = this.tabLinkWidth * this.activeTabIndex
      let translateYValue = 0
      if (this.vertical) {
        translateYValue = this.tabLinkHeight * this.activeTabIndex
        translateXValue = 0
      }

      let styles = {
        transform: `translate3d(${translateXValue}px, ${translateYValue}px, 0px)`
      }

      if (this.tabLinkWidth !== 0) {
        styles.width = `${this.tabLinkWidth}px`
      }
      return styles
    },
    showActiveTab() {
      return ['lg', 'xl'].includes(this.mq.current)
    }
  },
  watch: {
    activeTabIndex(newValue, oldValue) {
      if (newValue !== oldValue) {
        let oldTab = this.tabs[oldValue]
        let newTab = this.tabs[newValue]
        oldTab.active = false
        newTab.active = true

        if (!newTab.checked) {
          newTab.checked = true
        }
        this.$nextTick(this.setShadows)
        this.$emit('tab-change', oldTab, newTab)
        this.$emit('update:startIndex', newValue)
      }
    },
    errors: {
      deep: true,
      handler(value) {
        this.$emit('has-errors', !!value.items.length)
      }
    }
  },
  methods: {
    addTab(tab) {
      const getLabel = node => node.children.label ? node.children.label()[0].children : ''
      const defaultSlots = this.$slots.default().map(item => {
        if (typeof item.type === 'object') return item
        if (typeof item.type === 'symbol' && !!item.children.length) return item.children
      }).flat()
      const index = defaultSlots.findIndex(item => {
        return getLabel(item) === getLabel(tab.$.vnode)
      })
      let tabTitle = tab.title || ''
      tab.tabId = `${tabTitle.replace(/\s+/g, '')}${index}`
      if (!this.activeTab && index === 0) {
        tab.active = true
        tab.checked = true
      }
      if (this.activeTab === tab.name) {
        tab.active = true
        tab.checked = true
      }
      if (this.allTabsEnabled) {
        tab.checked = true
      }
      this.onResize()
      this.tabs.splice(index, 0, tab)
    },
    removeTab(tab) {
      const tabs = this.tabs
      const index = tabs.indexOf(tab)
      if (index > -1) {
        tabs.splice(index, 1)
      }
    },
    validate(tab) {
      let tabToValidate = tab || this.activeTab
      let beforeChange = tabToValidate.beforeChange
      if (beforeChange) {
        return Promise.resolve(beforeChange())
          .then(res => {
            this.activeTab.hasError = !res
            return res
          })
          .catch(() => {
            this.activeTab.hasError = true
          })
      } else {
        return Promise.resolve(true)
      }
    },
    async validateAll() {
      const validTabs = await this.navigateToTab(this.tabCount - 1)
      const validLastTab = await this.validate(this.tabCount - 1)
      return validTabs && validLastTab
    },
    async nextTab() {
      if (this.frozen) {
        this.$notifyInfo('Не завершено редактирование защищенного поля')
        return false
      }
      let isValid = await this.validate()
      if (isValid && this.activeTabIndex === this.tabCount - 1 && !this.isCreatorCreateApp) {
        this.$emit('complete')
      }
      if (isValid && this.activeTabIndex < this.tabCount - 1) {
        this.activeTabIndex++
      }
      return isValid
    },
    prevTab() {
      if (this.frozen) {
        this.$notifyInfo('Не завершено редактирование защищенного поля')
        return false
      }
      this.activeTabIndex--
    },
    async navigateToTab(index) {
      if (this.frozen) {
        this.$notifyInfo('Не завершено редактирование защищенного поля')
        return false
      }
      if (this.tabs[index].checked) {
        let valid
        // recursively validate each tab
        if (index > this.activeTabIndex) {
          valid = await this.nextTab()
          if (valid) {
            valid = await this.navigateToTab(index)
          }
          return valid
        } else {
          this.activeTabIndex = index
        }
        valid = await this.validate()
        return valid
      }
    },
    onResize() {
      let tabLinks = document.querySelectorAll(`#${this.wizardId} .wizard-tab-link`)
      if (tabLinks.length > 0 && this.tabCount > 0) {
        let { clientWidth, clientHeight } = tabLinks[0]
        this.tabLinkWidth = clientWidth
        this.tabLinkHeight = clientHeight
      }
    },
    setShadows() {
      const cardBody = document.querySelector(`#${this.wizardId} .card-body`)
      this.shadowHeader = cardBody.scrollTop > 0
      this.shadowFooter = cardBody.scrollTop + cardBody.offsetHeight < cardBody.scrollHeight
    }
  },
  mounted() {
    this.activeTabIndex = this.startIndex
    this.$nextTick(() => {
      this.tabs[this.activeTabIndex].active = true
      this.tabs[this.activeTabIndex].checked = true
      this.onResize()
      this.setShadows()
    })
    window.addEventListener('resize', () => {
      throttle(this.onResize, 40)
    }, false)
    const cardBody = document.querySelector(`#${this.wizardId} .card-body`)
    cardBody.classList.add('custom-scroll')
    cardBody.onscroll = this.setShadows
  }
}
</script>
<style lang="scss" scoped>

/**
  Extra niceties. Display error tabs and disable navigation unvisited tabs
 */
.wizard-navigation .nav-link {
  &.active,
  &.checked {
    cursor: pointer;
  }
}

.disabled-wizard-link {
  cursor: not-allowed;
}

.moving-tab span {
  font-size: 89%;
  font-weight: 400;
}

.wizard-tab-link span {
  font-size: 89%;
  font-weight: 400;
}

span.el-input__count {
  margin-bottom: 10px;
}
</style>
