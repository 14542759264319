export default {
  attractionCodes: state => state.attractionCodes,
  attractionCodesPageCount: state => state.attractionCodesPageCount,
  emailLayouts: state => state.emailLayouts,
  emailLayoutsId: state => state.emailLayoutsId,
  emailLayoutsPageCount: state => state.emailLayoutsPageCount,
  selectedEmailLayout: state => state.selectedEmailLayout,
  emailOptions: state => state.emailOptions,
  waitingResponse: state => state.waitingResponse,
  isShowModal: state => state.isShowModal,
  taskExpensesList: state => state.taskExpensesList,
  expensesList: state => state.expensesList,
  programmingLanguagesList: state => state.programmingLanguagesList,
  frameworksList: state => state.frameworksList,
  deletionReasonsList: state => state.deletionReasonsList,
  deletionReasonsListPageCount: state => state.deletionReasonsListPageCount,
  payoutLimits: state => state.payoutLimits,
  internalUserList: state => state.internalUserList,
  internalUserListPageCount: state => state.internalUserListPageCount,
  emailLocalizations: state => state.emailLocalizations,
  emailLocalizationsPageCount: state => state.emailLocalizationsPageCount,
  selectedEmailLocalization: state => state.selectedEmailLocalization,
  smsLocalizations: state => state.smsLocalizations,
  smsLocalizationsPageCount: state => state.smsLocalizationsPageCount,
  selectedLocalization: state => state.selectedLocalization,
  smsOptions: state => state.smsOptions,
  loading: state => state.loading,
  passportVerification: state => state.passportVerification,
  allowTaskPublic: state => state.allowTaskPublic,
  enableGmailTask: state => state.enableGmailTask,
  enableSharingAccount: state => state.enableSharingAccount,
  smsMailing: state => state.smsMailing,
  emailMailing: state => state.emailMailing,
  consoleCommands: state => state.consoleCommands,
  marathonActivation: state => state.marathonActivation
}
