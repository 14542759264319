<template>
  <div class="external-layout custom-scroll">
    <div v-if="smallScreen" class="external-layout__logo">
      <img src="/static/img/getwork_logo/full_dark.svg" alt="">
      <language-switcher/>
    </div>
    <external-navbar v-else/>
    <div class="content-wrap custom-scroll">
      <div class="content">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component"/>
          </transition>
        </router-view>
      </div>
      <external-footer/>
    </div>
  </div>
</template>

<script>
import ExternalNavbar from "@/components/Layout/Navigation/ExternalNavbar"
import LanguageSwitcher from "@/components/UIComponents/Custom/languageSwitcher"
import ExternalFooter from '@/components/Layout/ExternalFooter'

export default {
  name: "ExternalLayout",
  components: {
    ExternalFooter,
    ExternalNavbar,
    LanguageSwitcher,
  },
  inject: ["mq"],
  computed: {
    smallScreen() {
      return ['xs', 'sm'].includes(this.mq.current)
    }
  },
  async beforeMount() {
    this.$store.dispatch('startIntercomChat')
  },
}
</script>

<style scoped>

</style>
