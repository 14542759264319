import Echo from 'laravel-echo'

window.io = require('socket.io-client')

export default {
  install(app) {
    const connectSockets = () => {
      if (typeof io !== 'undefined' && localStorage.token) {
        app.config.globalProperties.$echo = new Echo({
          broadcaster: 'socket.io',
          host: process.env.VUE_APP_SOCKET_URL,
          authEndpoint: process.env.VUE_APP_SOCKET_URL + '/broadcasting/auth',
          auth: {
            headers: {
              'Authorization': 'Bearer ' + localStorage.token,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
        })
        clearInterval(interval)
      }
    }
    const interval = setInterval(connectSockets, 1000);
  }
}
