import {
  compareFormData,
  prepareDataForSubtask,
  prepareDataForUpdateAsoImage,
  prepareDataForUpdateAssemblyAsoApp,
  prepareUpdateTaskData
} from '@/utils/helpers'
import moment from 'moment'

export default {
  async createUpdateTextTask(ctx, payload) {
    const {model, initModel} = payload
    const _data = prepareUpdateTaskData(model)
    const initData = prepareUpdateTaskData(initModel)
    const data = compareFormData(initData, _data, 'update_aso')
    if (!data) {
      this.$app.$notifyError('Для создания обновления необходимо изменить данные')
      return
    }
    data.set('application[id]', initModel.id)
    data.set('language_default', model.language_default)
    data.set('publish_at', moment(model.publish_at).format('YYYY-MM-DD'))
    try {
      await axios.post('aso_manager/update/aso_text/app', data)
      return true
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('При создании задачи произошла ошибка. Попробуйте позже.')
    }
  },
  async createUpdateAssemblyTask({ rootGetters }, payload) {
    const {model} = payload
    const app = rootGetters.selectedApplication
    const data = prepareDataForUpdateAssemblyAsoApp(model, app)
    data.set('application[id]', rootGetters.selectedApplication.id)
    try {
      await axios.post('aso_manager/update/aso_assembly_image/app', data)
      return true
    } catch (e) {
      console.log(e)
      this.$app.$notifyError('При создании задачи произошла ошибка. Попробуйте позже.')
    }
  },
  async updateAppRequest({ rootGetters }, query) {
    const appId = rootGetters.selectedApplication.id
    const data = new FormData()
    data.append('query', query)
    try {
      await axios.put(`aso_manager/attached/applications/${appId}`, data)
      return true
    } catch (e) {
      console.log(e)
      this.$app.$notifyError('При обновлении приложения произошла ошибка. Попробуйте позже.')
    }
  },
  async updateApplication({ rootGetters }, payload) {
    const {model, returnTask} = payload
    const task = rootGetters.selectedTask
    const mainTask = rootGetters.selectedMainTask
    const app = rootGetters.selectedApplication
    if (model) {
      const subtaskType = {
        designer_adv: 'designer',
        development_integration: 'programmer',
        app_manager_update: 'app_manager',
      }[task.type]
      const mainTaskType = mainTask.type.name
      const updateUrl = {
        update_aso_text: 'aso_text',
        update_aso_image: 'aso_image',
        update_aso_assembly_image: 'aso_assembly_image',
      }[mainTaskType]
      const setLanguageToModel = () => {
        const newApplication = {...model.application}
        const desktopName = model.application.desktop_name
        const language = app.language.map(item => {
          if (item.name === app.language_default.toLowerCase()) {
            item.desktop_name = desktopName
          }
          return item
        })
        delete newApplication.desktop_name
        newApplication.language = language
        newApplication.language_default = app.language_default
        return {
          ...model,
          application: newApplication
        }
      }
      const _model = model.application.desktop_name ? setLanguageToModel() : model
      const data = prepareDataForSubtask(_model, subtaskType)
      data.set('task_id', mainTask.id)
      try {
        await axios.put(`aso_manager/update/${updateUrl}/app/${app.id}`, data)
        this.$app.$notifySuccess('Приложение успешно обновлено')
      } catch (e) {
        this.$app.$notifyError('При сохранении произошла ошибка. Попробуйте позже или обратитесь в техподдержку.')
        console.log(e)
        return false
      }
    }
    if (returnTask) {
      const returnUrl = {
        current: 'return',
        other: 'restore-to-all',
      }[returnTask]
      const errorMsg = {
        current: 'Не удалось вернуть задачу.',
        other: 'Не удалось опубликовать задачу заново.',
      }[returnTask]
      try {
        await axios.put(`${returnUrl}/tasks/${task.id}`)
      } catch (e) {
        this.$app.$notifyError(`${errorMsg} Попробуйте позже или обратитесь в техподдержку.`)
        console.log(e)
        return false
      }
    }
    return true
  },

  async createUpdateImageTask({ rootGetters }, payload) {
    const {model} = payload
    const data = prepareDataForUpdateAsoImage(model)
    const appId = rootGetters.selectedApplication.id
    data.set('application[id]', appId)
    data.set('language_default', model.language_default)
    try {
      await axios.post(`aso_manager/update/aso_image/app`, data)
      return true
    } catch (e) {
      console.log(e)
      this.$app.$notifyError('При создании задачи произошла ошибка. Попробуйте позже.')
    }
  },
}
