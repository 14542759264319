export default {
  applications: state => state.applications,
  applicationsWithTasks: state => state.applicationsWithTasks,
  applicationsListPageCount: state => state.applicationsListPageCount,
  needReloadAppList: state => state.needReloadAppList,
  selectedTask: state => state.selectedTask,
  selectedMainTask: state => state.selectedMainTask,
  addingAppType: state => state.addingAppType,
  isOtherCreator: state => state.isOtherCreator,
  subtaskStats: state => state.subtaskStats,
  fastSubtaskStats: state => state.subtaskStats.filter(item => item.fast),
  subtaskStatusFilter: state => {
    const filter = {
      name: 'subtaskStatus',
      title: 'Статус подзадач'
    }
    filter.options = [
      {
        default: true,
        label: 'Все статусы',
        value: ''
      },
      ...state.subtaskStats.map(item => ({
        label: item.title,
        value: `filter[tasks][subtasks][status][name]=${item.name}`
      }))
    ]
    return filter
  }
}
