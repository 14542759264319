import { filterConfig } from '@/dictionary/filterConfig.js'
import {APP_MANAGER_SIMPLE_TASK_STATUSES,
  APP_MANAGER_SIMPLE_TASK_STATUSES_CREASO,
  AVAILABLE_APP_MANAGER_TASK_TYPES,
  APP_MANAGER_TASK_TYPE,
  UNUSED_APP_MANAGER_TASK_STATUSES } from '@/utils/constants'
import { sortData } from '@/utils/helpers'

export default {
  state: {
    listResponseId: undefined,
    listSearch: undefined,
    listFilters: undefined,
    allowedFilterNames: undefined,
    filterCheckbox: undefined
  },

  getters: {
    listResponseId: state => state.listResponseId,
    listSearch: state => state.listSearch,
    allowedFilters: state => {
      if (!state.listFilters || !state.allowedFilterNames) return []
      return state.listFilters.filter(item => state.allowedFilterNames.includes(item.name))
    },
    selectedFilters: state => {
      if (!state.listFilters) return ''
      const selectedFilters = state.listFilters.filter(item => {
        const allowedByName = state.allowedFilterNames ? state.allowedFilterNames.includes(item.name) : false
        const isAllowed = item.supplemented || allowedByName
        const hasValue = !!item.value
        return isAllowed && hasValue
      })
      return selectedFilters.map(item => item.value).join('&')
    },
    displayedFilters: state => {
      if (!state.listFilters) return []
      const displayedFilters = state.listFilters.filter(item => {
        const allowedByName = state.allowedFilterNames ? state.allowedFilterNames.includes(item.name) : false
        const isAllowed = item.supplemented || allowedByName
        const isDefault = item.options.find(option => option.value === item.value).default
        return isAllowed && !isDefault
      })
      return displayedFilters.map(item => ({
        title: item.title,
        label: item.options.find(option => option.value === item.value).label
      }))
    },
    subtaskFilterValue: state => {
      if (!state.listFilters) return
      const filter = state.listFilters.find(item => item.name === 'subtaskStatus')
      if (!filter) return
      return filter.value
    },
    filterCheckbox: state => state.filterCheckbox,
  },

  mutations: {
    updateListResponseId(state, prefix = '') {
      state.listResponseId = prefix + Date.now()
    },
    initListFilters(state) {
      state.listFilters = filterConfig.map(filter => {
        filter.value = filter.options.find(option => option.default).value
        return filter
      })
    },
    updateFilterOptions(state, payload) {
      const { name, options } = payload
      const filter = state.listFilters.find(item => item.name === name)
      if (!filter) return
      filter.options = [
        ...filter.options,
        ...options
      ]
      filter.isDynamic = false
    },
    setAllowedFilterNames(state, value) {
      state.allowedFilterNames = value
    },
    setListSearch(state, value) {
      state.listSearch = value
      state.listResponseId = Date.now()
    },
    clearListSettings(state) {
      state.listSearch = undefined
      state.listResponseId = undefined
    },
    resetAllParameters(state) {
      if (state.listFilters) {
        state.listFilters.forEach(filter => {
          filter.value = filter.options.find(option => option.default).value
        })
      }
      state.listSearch = undefined
      state.filterCheckbox = undefined
      state.listResponseId = undefined
    },
    resetFilters(state) {
      state.listFilters.forEach(filter => {
        if (filter.supplemented) return
        filter.value = filter.options.find(option => option.default).value
      })
      state.filterCheckbox = undefined
      state.listResponseId = undefined
    },
    supplementFilter(state, filter) {
      if (!filter) return
      filter.supplemented = true
      filter.value = filter.options.find(option => option.default).value
      state.listFilters.push(filter)
    },
    setFilterValue(state, { name, label }) {
      const filter = state.listFilters.find(i => i.name === name)
      if (!filter) return
      const option = filter.options.find(i => i.label === label)
      if (!option) return
      filter.value = option.value
      state.listResponseId = Date.now()
    },
    resetFilter(state, name) {
      const filter = state.listFilters.find(i => i.name === name)
      if (!filter) return
      filter.value = filter.options.find(option => option.default).value
      state.listResponseId = undefined
    },
    setFilterCheckbox(state, value) {
      state.filterCheckbox = value
    }
  },

  actions: {
    fillFilterOptions({ state, rootState, commit }) {
      let options = []
      if (!rootState.types) return
      if (!state.listFilters) commit('initListFilters')
      state.listFilters
        .filter(item => item.isDynamic)
        .forEach(filter => {
          const getStatusOptions = (typeFilter) => {
            const listStatuses = typeFilter === 'statusCreAso'
              ? APP_MANAGER_SIMPLE_TASK_STATUSES_CREASO
              : APP_MANAGER_SIMPLE_TASK_STATUSES
            const simpleTaskStatuses = listStatuses.map(name => {
              const label = rootState.types.status.find(i => i.name === name).title.replace('.', '')
              return {
                value: typeFilter === 'statusCreAso' ? name : `filter[status][name]=${name}`,
                label
              }
            })
            return sortData(simpleTaskStatuses)
          }
          const getStatusConsoleOptions = () => {
            const statusesConsole = rootState.types.user_console_status.map(status => {
              return {
                value: `filter[console_status][id]=${status.id}`,
                label: status.title.replace('.', '')
              }
            })
            return sortData(statusesConsole)
          }
          const getMarathonOptions = () => {
            const marathonStatuses = rootState.types.marathon_status.map(status => {
              return {
                value: `filter[marathon][id]=${status.id}`,
                label: status.title.replace('.', '')
              }
            })
            return sortData(marathonStatuses)
          }
          const getTypeTaskOptions = () => {
            const typesTask = AVAILABLE_APP_MANAGER_TASK_TYPES.map(name => {
              const status = rootState.types.type_task.find(i => i.name === name)
              const label = status.title.replace('.', '')
              let statusesList = ''
              switch (status.name) {
                case APP_MANAGER_TASK_TYPE.create_application:
                  statusesList = [APP_MANAGER_TASK_TYPE.create_application,
                                  APP_MANAGER_TASK_TYPE.no_develop,
                                  APP_MANAGER_TASK_TYPE.with_develop,
                                  APP_MANAGER_TASK_TYPE.unity_export_application].join(',')
                  break;
                case APP_MANAGER_TASK_TYPE.update_application:
                  statusesList = [APP_MANAGER_TASK_TYPE.update_app_assembly,
                                  APP_MANAGER_TASK_TYPE.update_app_aso_image,
                                  APP_MANAGER_TASK_TYPE.update_app_aso_image_assembly,
                                  APP_MANAGER_TASK_TYPE.update_aso_text,
                                  APP_MANAGER_TASK_TYPE.update_aso_image,
                                  APP_MANAGER_TASK_TYPE.update_aso_assembly_image,
                                  APP_MANAGER_TASK_TYPE.update_assembly_add_purchases,
                                  ].join(',')
                  break;
                default:
                  statusesList = status.name
                  break;
              }
              return {
                value: `filter[task][type][name]=${statusesList}`,
                label
              }
            })
            return sortData(typesTask)
          }
          const getStatusUpdateTaskOptions = () => {
            const statusesUpdateTask = rootState.types.status.reduce((prev, status) => {
              if (UNUSED_APP_MANAGER_TASK_STATUSES.includes(status.name)) return prev
              if (prev.some(element => element.label === status.title)) {
                let index = prev.findIndex(el => el.label === status.title)
                prev[index].value += `,${status.id}`
              } else {
                prev.push({
                  value: `filter[status][id]=${status.id}`,
                  label: status.title.replace('.', ''),
                  statusIndex: 0
                })
              }
              return prev
            }, [])
            return sortData(statusesUpdateTask)
          }
          const getCategoryAppsOptions = () => {
            const categoryApps = rootState.types.tags.map(status => {
              return {
                value: `filter[application][tag][id]=${status.id}`,
                label: status.title.replace('.', '')
              }
            })
            return sortData(categoryApps)
          }
          const getter = {
            status: () => getStatusOptions(filter.name),
            statusCreAso: () => getStatusOptions(filter.name),
            userStatusConsole: getStatusConsoleOptions,
            marathon: getMarathonOptions,
            typeTask: getTypeTaskOptions,
            statusUpdateTask: getStatusUpdateTaskOptions,
            categoryApps: getCategoryAppsOptions
          }[filter.name]

          if (getter) {
            try {
              options = getter()
            } catch (e) {
              console.info(e)
            }
          }
          commit('updateFilterOptions', { name: filter.name, options })
        })
    }
  }
}
