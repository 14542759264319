import { setFieldToTypesInLS } from '@/utils/helpers'

export default {
  setAttractionCodes(state, value) {
    state.attractionCodes = value
  },
  setAttractionCodesPageCount(state, value) {
    state.attractionCodesPageCount = value
  },
  updateAttractCodeSettingsById(state, payload) {
    const { id, settings } = payload
    const record = state.attractionCodes.find(item => item.id === id)
    if (!record) return
    for (const [key, value] of Object.entries(settings)) {
      record[key] = value
    }
  },
  setEmailLayouts(state, value) {
    state.emailLayouts = value
  },
  setEmailLayoutsPageCount(state, value) {
    state.emailLayoutsPageCount = value
  },
  setSelectedEmailLayout(state, value) {
    state.selectedEmailLayout = value
  },
  updateEmailLayoutById(state, payload) {
    const { id, emailLayoutSettings, isCreateLayout } = payload
    if (isCreateLayout) {
      emailLayoutSettings.id = id
      delete emailLayoutSettings.event_alias
      state.emailLayouts.unshift(emailLayoutSettings)
      return
    }
    const record = state.emailLayouts.find(item => item.id === id)
    if (!record) return
    for (const [key, value] of Object.entries(emailLayoutSettings)) {
      record[key] = value
    }
  },
  setWaitingResponse(state, value) {
    state.waitingResponse = value
  },
  setIsShowModal(state, value) {
    state.isShowModal = value
  },
  removeEmailLayout(state, value) {
    state.emailLayouts = state.emailLayouts.filter((layout) => {
      if (layout.id !== value) return layout
    })
  },
  setTaskExpensesList(state, value) {
    state.taskExpensesList = value
  },
  setExpensesList(state, value) {
    state.expensesList = value
  },
  setProgrammingLanguagesList(state, value) {
    state.programmingLanguagesList = value
  },
  setFrameworksList(state, value) {
    state.frameworksList = value
  },
  setDeletionReasonsList(state, value) {
    state.deletionReasonsList = value
  },
  setDeletionReasonsListPageCount(state, value) {
    state.deletionReasonsListPageCount = value
  },
  setPayoutLimits(state, value) {
    state.payoutLimits = value
  },
  setInternalUserList(state, value) {
    state.internalUserList = value
  },
  setInternalUserListPageCount(state, value) {
    state.internalUserListPageCount = value
  },
  setLocalizations(state, payload) {
    const { localizations, type } = payload
    state[type] = localizations
  },
  setLocalizationsPageCount(state, payload) {
    const { last_page, type } = payload
    state[type] = last_page
  },
  setSelectedLocalization(state, localization) {
    state.selectedLocalization = localization
  },
  setLocalizationOptions(state, payload) {
    const { options, type } = payload
    state[type] = options
  },
  removeLocalization(state, payload) {
    const { id, type } = payload
    state[type] = state[type].filter((localization) => {
      if (localization.id !== id) return localization
    })
  },
  updateLocalizeSettingsById(state, payload) {
    const { id, localizeSettings, type } = payload
    const record = state[type].find(item => item.id === id)
    if (!record) return
    for (const [key, value] of Object.entries(localizeSettings)) {
      record[key] = value
    }
  },
  removeEmailLocalization(state, value) {
    state.emailLocalizations = state.emailLocalizations.filter((localization) => {
      if (localization.id !== value) return localization
    })
  },
  setEmailLayoutsId(state, emailLayouts) {
    emailLayouts.forEach(layout => {
      state.emailLayoutsId.push(layout.id)
    })
    state.emailLayoutsId.sort((a, b) => a - b)
  },
  clearEmailLayouts(state) {
    state.emailLayouts = []
    state.emailLayoutsPageCount = undefined
    state.loading = false
  },
  clearEmailLocalizations(state) {
    state.emailLocalizations = []
    state.emailLocalizationsPageCount = undefined
    state.emailLayoutsId = []
  },
  clearSmsLocalizations(state) {
    state.smsLocalizations = []
    state.smsLocalizationsPageCount = undefined
  },
  setLoading(state, value) {
    state.loading = value
  },
  setPassportVerifyStatus(state, status) {
    setFieldToTypesInLS('need_upload_passport', status)
    state.passportVerification.value = status
  },
  setPermissionStatus(state, payload) {
    const {titlePermission, flag, id} = payload
    state[titlePermission].value = flag
    state[titlePermission].id = id
  },
  changeDisabledTrigger(state, payload) {
    const { isDisabled, type } = payload
    state[type].isDisabled = isDisabled
  },
  clearAttractionCodes(state) {
    state.attractionCodes = []
    state.attractionCodesPageCount = undefined
  },
  setConsoleCommands(state, value) {
    state.consoleCommands = value
  },
  setMailingList(state, payload) {
    const {type, selected, list} = payload
    state[`${type}Mailing`] = {
      selected,
      list
    }
  },
  setCountTasks(state, payload) {
    const { countTasks, type } = payload
    state[type].countTasks = countTasks
  },
  updateMailing(state, payload) {
    const {type, service, value} = payload
    state[`${type}Mailing`].selected[service] = value
  },
}
