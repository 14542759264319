<template>
  <transition-fade appear :duration="animationDuration">
    <div
      v-show="show"
      class="modal fade"
      :class="[$attrs.class, {
        'show d-block': show && !isFlex,
        'show d-flex': show && isFlex,
        'd-none': !show,
        'modal-mini': type === 'mini'
      }]"
      tabindex="-1"
      role="dialog"
      :aria-hidden="!show"
      @click.self="clickOutside">
      <transition-slide appear>
        <div
          class="modal-dialog"
          :class="[{'modal-notice': type === 'notice'}, modalClasses]">
          <div class="modal-content">
            <div
              v-if="showHeader"
              ref="header"
              class="modal-header"
              :class="headerClasses">
              <slot name="close-button">
                <button
                  v-if="showClose"
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  :aria-hidden="!show"
                  @click="closeModal">
                  <i class="nc-icon nc-simple-remove"/>
                </button>
              </slot>
              <slot name="header"/>
            </div>

            <div class="modal-body custom-scroll" :class="bodyClasses">
              <slot/>
            </div>

            <div v-if="showFooter" class="modal-footer" :class="footerClasses">
              <slot name="footer"/>
            </div>
          </div>
        </div>
      </transition-slide>
    </div>
  </transition-fade>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    show: Boolean,
    showHeader: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'notice', 'mini'].includes(value)
      },
      description: 'Modal type (notice|mini|"") '
    },
    modalClasses: {
      type: [Object, String],
      default: '',
      description: 'Modal dialog css classes'
    },
    headerClasses: {
      type: [Object, String],
      default: '',
      description: 'Modal Header css classes'
    },
    bodyClasses: {
      type: [Object, String],
      default: '',
      description: 'Modal Body css classes'
    },
    footerClasses: {
      type: [Object, String],
      default: '',
      description: 'Modal Footer css classes'
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration'
    },
    closeByOutsideClick: {
      type: Boolean,
      default: false,
      description: 'Close modal by click outside'
    },
    showOnBeforeMount: {
      type: Boolean,
      default: false,
      description: 'Add class "modal-open" to body on beforeMount'
    },
    isFlex: {
      type: Boolean,
      default: false,
      description: 'Add class "d-flex" to modal box'
    },
    changeBodyClassList: {
      type: Boolean,
      default: true,
      description: 'Toggle class "modal-open" at body'
    }
  },
  watch: {
    show(val) {
      let documentClasses = document.body.classList
      if (this.changeBodyClassList) {
        if (val) {
          documentClasses.add('modal-open')
        } else {
          documentClasses.remove('modal-open')
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
      this.$emit('close')
    },
    clickOutside() {
      if (this.closeByOutsideClick) {
        this.closeModal()
      }
    }
  },
  beforeMount() {
    if (this.showOnBeforeMount && this.changeBodyClassList) {
      document.body.classList.add('modal-open')
    }
  },
  beforeUnmount() {
    if (this.changeBodyClassList) {
      document.body.classList.remove('modal-open')
    }
  }
}
</script>
<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3)
}
</style>
