import Sidebar from './SideBar.vue'
import SidebarItem from './SidebarItem.vue'
import store from '@/store'

const SidebarStore = {
  showSidebar: false,
  sidebarLinks: [],
  isMinimized: false,
  displaySidebar(value) {
    this.showSidebar = value
    store.commit('setShowSidebar', value)
  },
  toggleMinimize() {
    document.body.classList.toggle('sidebar-mini')
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event('resize'))
    }, 180)

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize)
    }, 1000)

    this.isMinimized = !this.isMinimized
  },
  getStartPage(role) {
    const defaultPath = '/dashboard'
    for (const link of this.sidebarLinks) {
      if (link.meta && link.meta.startPageFor && link.meta.startPageFor.includes(role)) {
        return link.path
      }
      if (link.children && link.children.length > 0) {
        for (const subLink of link.children) {
          if (subLink.meta.startPageFor && subLink.meta.startPageFor.includes(role)) {
            return subLink.path
          }
        }
      }
    }
    return defaultPath
  }
}

const SidebarPlugin = {

  install(app, options) {
    if (options && options.sidebarLinks) {
      SidebarStore.sidebarLinks = options.sidebarLinks
    }
    app.mixin({
      data() {
        return {
          sidebarStore: SidebarStore
        }
      }
    })

    app.config.globalProperties.$sidebar = SidebarStore

    app.component('SideBar', Sidebar)
    app.component('SidebarItem', SidebarItem)
  }
}

export default SidebarPlugin
