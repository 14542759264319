import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
  taskList: [],
  taskListPageCount: null,
  applications: [],
  applicationsForUpdate: [],
  applicationsListPageCount: null,
  deletedAppsList: null,
  deletedAppsPageCount: null,
  showUserModal: false,
  chosenAgeRestrictions: {},
  ageRestrictionsValidated: false,
  historyChanges: [],
  historyReference: [],
  totalHistoryPages: undefined,
  currentHistoryPage: 0
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
