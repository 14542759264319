<template>
  <div class="language-switcher">
    <fg-input
      class="input-lang-switcher">
      <el-select
        v-model="currentLocale"
        placeholder=""
        popper-class="input-lang-switcher__popper external-layout-popper">
        <el-option
          v-for="option in locales"
          :key="option"
          :value="option"
          :label="option"/>
      </el-select>
    </fg-input>
  </div>
</template>

<script>
import {AVAILABLE_LOCALES} from '@/utils/constants'
import {ElSelect, ElOption} from 'element-plus'

export default {
  name: "LanguageSwitcher",
  components: {
    ElOption,
    ElSelect,
  },
  data() {
    return {
      locales: AVAILABLE_LOCALES,
      currentLocale: null,
    }
  },
  watch: {
    currentLocale(value) {
      const path = this.$route.path.split('/').reverse()[0]
      this.$i18n.locale = value
      if (['login', 'register'].includes(path)) {
        const routeLocale = this.$route.path.split('/').reverse()[1]
        if (value === routeLocale) return
        this.$router.push(`/${value}/${path}`)
      }
    }
  },
  beforeMount() {
    this.currentLocale = this.$i18n.locale
  }
}
</script>

<style scoped>

</style>
