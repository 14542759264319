export default {
  state: {
    smsAeroBalance: null,
    luminatiBalance: null,
    qiwiBalance: null,
    capitalistBalance: null,
    smscBalance: null,
    ucallerBalance: null,
    newtelBalance: null,
    onlineSimBalance: null,
    sim5Balance: null,
  },

  getters: {
    smsAeroBalance: (state) => state.smsAeroBalance,
    luminatiBalance: (state) => state.luminatiBalance,
    qiwiBalance: (state) => state.qiwiBalance,
    capitalistBalance: (state) => state.capitalistBalance,
    smscBalance: (state) => state.smscBalance,
    ucallerBalance: (state) => state.ucallerBalance,
    newtelBalance: (state) => state.newtelBalance,
    onlineSimBalance: (state) => state.onlineSimBalance,
    sim5Balance: (state) => state.sim5Balance,
  },

  mutations: {
    setSmsAeroBalance(state, value) {
      state.smsAeroBalance = value;
    },
    setLuminatiBalance(state, value) {
      state.luminatiBalance = value;
    },
    setQiwiBalance(state, value) {
      state.qiwiBalance = value;
    },
    setCapitalistBalance(state, value) {
      state.capitalistBalance = value;
    },
    setSmscBalance(state, value) {
      state.smscBalance = value;
    },
    setUcallerBalance(state, value) {
      state.ucallerBalance = value;
    },
    setNewtelBalance(state, value) {
      state.newtelBalance = value;
    },
    setOnlineSimBalance(state, value) {
      state.onlineSimBalance = value;
    },
    setSim5Balance(state, value) {
      state.sim5Balance = value;
    },
  },

  actions: {
    async updateSmsAero({ commit, rootGetters }) {
      const ABORT_KEY = "smsAeroBalance";
      const controller = new AbortController();
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort();
      }
      commit("setAbortController", { controller, name: ABORT_KEY });
      try {
        const response = await axiosV3.get("/service-balances/smsaero", {
          signal: rootGetters.abortControllers[ABORT_KEY].signal,
        });
        const balance = response.data.balance.RUB;
        commit("setSmsAeroBalance", balance);
        commit("deleteAbortController", ABORT_KEY);
      } catch (e) {
        console.info(e);
        if (e.handled || e.code === "ERR_CANCELED") return;
        this.$app.$notifyError(
          "Не удалось обновить баланс SMS Aero. Попробуйте позже."
        );
      }
    },

    async updateLuminati({ commit, rootGetters }) {
      const ABORT_KEY = "luminatiBalance";
      const controller = new AbortController();
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort();
      }
      commit("setAbortController", { controller, name: ABORT_KEY });
      try {
        const response = await axiosV3.get("/service-balances/luminati", {
          signal: rootGetters.abortControllers[ABORT_KEY].signal,
        });
        const balance = response.data.balance.USD;
        const currentMonth = response.data.spent_this_month;
        commit("setLuminatiBalance", {
          balance,
          currentMonth,
        });
        commit("deleteAbortController", ABORT_KEY);
      } catch (e) {
        console.info(e);
        if (e.handled || e.code === "ERR_CANCELED") return;
        this.$app.$notifyError(
          "Не удалось обновить баланс Luminati. Попробуйте позже."
        );
      }
    },

    async updateQiwi({ commit, rootGetters }) {
      const ABORT_KEY = "qiwiBalance";
      const controller = new AbortController();
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort();
      }
      commit("setAbortController", { controller, name: ABORT_KEY });
      try {
        const response = await axiosV3.get("/service-balances/qiwi", {
          signal: rootGetters.abortControllers[ABORT_KEY].signal,
        });
        const balance = response.data.balance.RUB;
        commit("setQiwiBalance", balance);
        commit("deleteAbortController", ABORT_KEY);
      } catch (e) {
        console.info(e);
        if (e.handled || e.code === "ERR_CANCELED") return;
        this.$app.$notifyError(
          "Не удалось обновить баланс QIWI. Попробуйте позже."
        );
      }
    },

    async updateCapitalist({ commit, rootGetters }) {
      const ABORT_KEY = "capitalistBalance";
      const controller = new AbortController();
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort();
      }
      commit("setAbortController", { controller, name: ABORT_KEY });
      try {
        const response = await axiosV3.get(
          "/service-balances/capitalist",
          {
            signal: rootGetters.abortControllers[ABORT_KEY].signal,
          }
        );
        const balance = response.data.balance;
        commit("setCapitalistBalance", balance);
        commit("deleteAbortController", ABORT_KEY);
      } catch (e) {
        console.info(e);
        if (e.handled || e.code === "ERR_CANCELED") return;
        this.$app.$notifyError(
          "Не удалось обновить баланс Capitalist. Попробуйте позже."
        );
      }
    },

    async updateNewtel({ commit, rootGetters }) {
      const ABORT_KEY = "newtelBalance";
      const controller = new AbortController();
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort();
      }
      commit("setAbortController", { controller, name: ABORT_KEY });
      try {
        const response = await axiosV3.get("/service-balances/newtel", {
          signal: rootGetters.abortControllers[ABORT_KEY].signal,
        });
        const balance = response.data.balance.RUB;
        commit("setNewtelBalance", balance);
        commit("deleteAbortController", ABORT_KEY);
      } catch (e) {
        console.info(e);
        if (e.handled || e.code === "ERR_CANCELED") return;
        this.$app.$notifyError(
          "Не удалось обновить баланс New-Tel. Попробуйте позже."
        );
      }
    },

    async updateSmsc({ commit, rootGetters }) {
      const ABORT_KEY = "smscBalance";
      const controller = new AbortController();
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort();
      }
      commit("setAbortController", { controller, name: ABORT_KEY });
      try {
        const response = await axiosV3.get("/service-balances/smscru", {
          signal: rootGetters.abortControllers[ABORT_KEY].signal,
        });
        const balance = response.data.balance.RUB;
        commit("setSmscBalance", balance);
        commit("deleteAbortController", ABORT_KEY);
      } catch (e) {
        console.info(e);
        if (e.handled || e.code === "ERR_CANCELED") return;
        this.$app.$notifyError(
          "Не удалось обновить баланс SMS Центр. Попробуйте позже."
        );
      }
    },

    async updateUcaller({ commit, rootGetters }) {
      const ABORT_KEY = "ucallerBalance";
      const controller = new AbortController();
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort();
      }
      commit("setAbortController", { controller, name: ABORT_KEY });
      try {
        const response = await axiosV3.get("/service-balances/ucaller", {
          signal: rootGetters.abortControllers[ABORT_KEY].signal,
        });
        const balance = response.data.balance.RUB;
        commit("setUcallerBalance", balance);
        commit("deleteAbortController", ABORT_KEY);
      } catch (e) {
        console.info(e);
        if (e.handled || e.code === "ERR_CANCELED") return;
        this.$app.$notifyError(
          "Не удалось обновить баланс uCaller. Попробуйте позже."
        );
      }
    },

    async updateOnlineSim({ commit, rootGetters }) {
      const ABORT_KEY = "onlineSim";
      const controller = new AbortController();
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort();
      }
      commit("setAbortController", { controller, name: ABORT_KEY });
      try {
        const response = await axiosV3.get("/service-balances/onlinesim", {
          signal: rootGetters.abortControllers[ABORT_KEY].signal,
        });
        const balance = response.data.balance.RUB;
        commit("setOnlineSimBalance", balance);
        commit("deleteAbortController", ABORT_KEY);
      } catch (e) {
        console.info(e);
        if (e.handled || e.code === "ERR_CANCELED") return;
        this.$app.$notifyError(
          "Не удалось обновить баланс onlineSim. Попробуйте позже."
        );
      }
    },

    async updateSim5({ commit, rootGetters }) {
      const ABORT_KEY = "5sim";
      const controller = new AbortController();
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort();
      }
      commit("setAbortController", { controller, name: ABORT_KEY });
      try {
        const response = await axiosV3.get("/service-balances/5sim", {
          signal: rootGetters.abortControllers[ABORT_KEY].signal,
        });
        const balance = response.data.balance.RUB;
        commit("setSim5Balance", balance);
        commit("deleteAbortController", ABORT_KEY);
      } catch (e) {
        console.info(e);
        if (e.handled || e.code === "ERR_CANCELED") return;
        this.$app.$notifyError(
          "Не удалось обновить баланс 5sim. Попробуйте позже."
        );
      }
    },

    clearDashboardValues({ commit }) {
      commit("setSmsAeroBalance", null);
      commit("setLuminatiBalance", null);
      commit("setQiwiBalance", null);
      commit("setCapitalistBalance", null);
      commit("setNewtelBalance", null);
      commit("setSmscBalance", null);
      commit("setOnlineSimBalance", null);
      commit("setSim5Balance", null);
    },
  },
};
