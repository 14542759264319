<template>
  <div
    class="sidebar"
    :data-color="backgroundColor"
    :data-active-color="activeColor">
    <div v-if="wideScreen" class="logo">
      <router-link
        :to="startPage"
        exact
        class="simple-text logo-mini"
        tag="a">
        <div class="logo-img">
          <img src="/static/img/getwork_logo/short.png" alt="">
        </div>
      </router-link>
      <router-link :to="startPage" class="simple-text logo-normal" tag="a">
        Get Work
      </router-link>
    </div>
    <div ref="sidebarScrollArea" class="sidebar-wrapper">
      <ul class="nav">
        <sidebar-item
          v-for="(link, index) in sidebarLinks"
          :key="link.name + index"
          menu
          :link="link"/>
      </ul>
    </div>
  </div>
</template>
<script>
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { mapGetters } from 'vuex'

export default {
  provide() {
    return {
      autoClose: this.autoClose
    }
  },
  inject: ["mq"],
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        let acceptedValues = ['white', 'brown', 'black']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Sidebar background color (white|brown|black)'
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Sidebar active text color (primary|info|success|warning|danger)'
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    sidebarLinks: []
  }),
  computed: {
    ...mapGetters(['user']),
    startPage() {
      if (!this.user) return
      return this.$sidebar.getStartPage(this.user.roles[0].name)
    },
    role() {
      return this.user?.roles[0]?.name
    },
    wideScreen() {
      return ['md', 'lg', 'xl'].includes(this.mq.current)
    }
  },
  watch: {
    role(value, oldValue) {
      if (!oldValue) return
      if (value !== oldValue) this.changeSidebarLinks()
    },
  },
  methods: {
    showItem(item) {
      if (!this.user) return
      const internalUser = this.user.is_internal
      if (internalUser && item.meta && item.meta.externalOnly) return false
      const getRoles = () => {
        if (item.meta && item.meta.roles) return item.meta.roles
        if (item.children) {
          return item.children.reduce((acc, child) => acc.concat(child.meta.roles), [])
        }
        return []
      }
      const itemRoles = getRoles()
      return this.user.roles.some(userRole => itemRoles.includes(userRole.name))
    },
    async initScrollBarAsync() {
      let isWindows = navigator.platform.startsWith('Win')
      if (!isWindows) return
      const PerfectScroll = await import('perfect-scrollbar')
      PerfectScroll.initialize(this.$refs.sidebarScrollArea)
    },
    changeSidebarLinks() {
      this.sidebarLinks = this.$sidebar.sidebarLinks
        .filter(this.showItem)
        .map(link => ({
          ...link,
          children: link.children ? link.children.filter(this.showItem) : undefined
        }))
    }
  },
  beforeMount() {
    this.changeSidebarLinks()
  },
  mounted() {
    this.initScrollBarAsync()
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.displaySidebar(false)
    }
  }
}

</script>
<style>
</style>
