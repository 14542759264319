import getters from './getters'
import mutations from './mutations'
import actions from './actions'


const getInitPassportVerification = () => {
  if (!localStorage.getItem('types')) return
  const types = JSON.parse(localStorage.getItem('types'))
  return types.need_upload_passport
}

const state = {
  attractionCodes: [],
  attractionCodesPageCount: undefined,
  emailLayouts: [],
  emailLayoutsId: [],
  emailLayoutsPageCount: undefined,
  selectedEmailLayout: undefined,
  emailOptions: {},
  waitingResponse: false,
  isShowModal: false,
  taskExpensesList: [],
  expensesList: null,
  programmingLanguagesList: [],
  frameworksList: [],
  deletionReasonsList: [],
  deletionReasonsListPageCount: null,
  payoutLimits: [],
  internalUserList: [],
  internalUserListPageCount: null,
  emailLocalizations: [],
  emailLocalizationsPageCount: undefined,
  smsLocalizations: [],
  smsLocalizationsPageCount: undefined,
  selectedLocalization: undefined,
  smsOptions: {},
  loading: false,
  passportVerification: {
    title: 'Верификация паспорта',
    value: getInitPassportVerification(),
    isDisabled: false
  },
  allowTaskPublic: {
    title: 'Повторные публикации',
    value: undefined,
    iconVue: 'Setting', // отображение иконки Element Plus
    hint: 'При выключении все не взятые в работу задачи на публикацию будут доступны только паблишерам с новыми аккаунтами',
    isDisabled: false,
    url: 'allow_next_publication',
    errMainContent: 'настройки повторной выдачи задач'
  },
  enableGmailTask: {
    id: undefined,
    title: 'Создание Gmail аккаунта',
    value: undefined,
    hint: 'При выключении, шаги по созданию Gmail аккаунта в задаче верификации будут выключены у всех паблишеров',
    isDisabled: false,
    icon: 'fa fa-google', // отображение иконки FontAwesome 4
    url: 'allow_google_account_verification',
    typeApi: 'v3',
    errMainContent: 'настройки выключения шагов по зданию Gmail аккаунтов'
  },
  enableSharingAccount: {
    id: undefined,
    title: 'Создание общего доступа',
    value: undefined,
    hint: 'При выключении, шаги по созданию общего доступа к аккаунту в задаче на публикацию будут выключены у всех паблишеров',
    isDisabled: false,
    image: '/static/img/icons/sharing_account.png', // отображение картинки
    url: 'allow_google_account_sharing',
    typeApi: 'v3',
    errMainContent: 'настройки выключения общего доступа к аккаунту'
  },
  smsMailing: {},
  emailMailing: {},
  consoleCommands: undefined,
  marathonActivation: {
    countTasks: 0,
    isDisabled: false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
