import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3"
import { getNotificationTag } from "../utils/helpers"

export default {
  state: {
    tasksBadge: 0,
    simpleTasksBadge: 0,
    tasksListBadge: 0,
    tasksModerationListBadge: 0,
    tasksVerificationListBadge: 0,
    simpleModerationListBadge: 0,
    simpleModerationGmailListBadge: 0,
    simpleTaskCreAsoListBadge: 0,
    notificationList: [],
    notificationHistory: [],
    needReloadTaskList: false,
    needReloadAppList: false
  },

  getters: {
    tasksBadge: state => state.tasksBadge,
    simpleTasksBadge: state => state.simpleTasksBadge,
    tasksListBadge: state => state.tasksListBadge,
    tasksModerationListBadge: state => state.tasksModerationListBadge,
    tasksVerificationListBadge: state => state.tasksVerificationListBadge,
    simpleModerationListBadge: state => state.simpleModerationListBadge,
    simpleModerationGmailListBadge: state => state.simpleModerationGmailListBadge,
    simpleTaskCreAsoListBadge: state => state.simpleTaskCreAsoListBadge,
    notificationList: state => state.notificationList,
    notificationHistory: state => state.notificationHistory,
    needReloadTaskList: state => state.needReloadTaskList,
    needReloadAppList: state => state.needReloadAppList
  },

  mutations: {
    setBadge(state, payload) {
      const {value, typeBadge} = payload
      state[`${typeBadge}Badge`] = value
    },
    setListBadge(state, payload) {
      const {value, titleBadge} = payload
      state[`${titleBadge}ListBadge`] = value
    },
    setNotificationList(state, value) {
      state.notificationList = value
    },
    setNotificationHistory(state, value) {
      state.notificationHistory = value
    },
    setNeedReloadTaskList(state, value) {
      state.needReloadTaskList = value
    },
    setNeedReloadAppList(state, value) {
      state.needReloadAppList = value
    }
  },

  actions: {
    increaseListBadgeData({ state, commit, dispatch }, titleBadge) {
      const initValue = state[`${titleBadge}ListBadge`]
      const value = initValue + 1
      const typeBadge = ['simpleModeration', 'simpleModerationGmail'].includes(titleBadge) ? 'simpleTasks' : 'tasks'
      commit('setListBadge', {value, titleBadge})
      dispatch('increaseBadgeData', typeBadge)
    },
    increaseBadgeData({ state, commit }, typeBadge) {
      const initValue = state[`${typeBadge}Badge`]
      const value = initValue + 1
      commit('setBadge', {value, typeBadge})
    },
    decreaseListBadgeData({ state, commit, dispatch }, titleBadge) {
      const initValue = state[`${titleBadge}ListBadge`]
      const value = initValue > 0 ? initValue - 1 : 0
      const typeBadge = ['simpleModeration', 'simpleModerationGmail'].includes(titleBadge) ? 'simpleTasks' : 'tasks'
      commit('setListBadge', {value, titleBadge})
      dispatch('decreaseBadgeData', typeBadge)
    },
    decreaseBadgeData({ state, commit }, typeBadge) {
      const initValue = state[`${typeBadge}Badge`]
      const value = initValue > 0 ? initValue - 1 : 0
      commit('setBadge', {value, typeBadge})
    },
    resetListBadgeData({ state, commit }, titleBadge) {
      const initTaskListValue = state[`${titleBadge}ListBadge`]
      const initTasksValue = ['simpleModeration', 'simpleModerationGmail'].includes(titleBadge)
        ? state.simpleTasksBadge
        : state.tasksBadge
      if (!initTaskListValue) return
      commit('setListBadge', {value: 0, titleBadge})
      commit('setBadge', {
        value: initTasksValue - initTaskListValue,
        typeBadge: ['simpleModeration', 'simpleModerationGmail'].includes(titleBadge) ? 'simpleTasks' : 'tasks'
      })
    },

    addNotification: async function ({state, commit, dispatch}, notice) {
      if (!notice) return
      const list = [...state.notificationList]

      let new_notice = notice
      /* TODO:  переделать функционал уведомлений, чтобы не было доп. запросов*/
      if (new_notice.notifiable?.type !== 'user_simple') {
        if (new_notice.commentInfo?.subtask?.id) {
          const response = await axiosV3.get(`subtasks/${new_notice.commentInfo.subtask.id}?include=application`)
          new_notice.commentInfo.subtask.application = response.data.application
        }
      }
      // TODO: Вместо unshift должна быть вставка в нужное место по дате
      list.unshift(new_notice)
      commit('setNotificationList', list)

      if (state.notificationHistory.length) {
        dispatch('downloadNotifications')
      }

      const tag = getNotificationTag(notice)
      dispatch('increaseListBadgeData', tag)
      commit('setNeedReloadTaskList', true)
      if (tag === 'tasks') commit('setNeedReloadAppList', true)
    },
    removeNotification({ state, commit, dispatch }, id) {
      const list = state.notificationList.slice()

      const filteredList = list.filter(item => {
        if (item.id === id) {
          const tag = getNotificationTag(item)
          dispatch('decreaseListBadgeData', tag)
        }
        return item.id !== id
      })
      commit('setNotificationList', filteredList)
    },
    removeNotificationByTask({ state, dispatch }, taskId) {
      const foundNotification = state.notificationList.find(i => (i.taskId && i.taskId === +taskId) ||
        (i.task && i.task.id === +taskId) ||
        (i.notification && i.notification.notifiable.id === +taskId) ||
        (i.moderation && i.moderation.id === +taskId)
      )
      if (!foundNotification) return
      dispatch('removeNotification', foundNotification.id)
    },
    clearNotificationList({ commit, dispatch }) {
      commit('setNotificationList', [])
      const typesNotify = ['tasks', 'tasksVerification', 'tasksModeration', 'simpleModeration', 'simpleModerationGmail', 'simpleTaskCreAso']
      typesNotify.forEach(type => {
        dispatch('resetListBadgeData', type)
      })
    },
    resetNeedReloadTaskList({ commit }) {
      commit('setNeedReloadTaskList', false)
    },
    resetNeedReloadAppList({ commit }) {
      commit('setNeedReloadAppList', false)
    },


    //  История уведомлений
    async downloadNotifications({ state, commit, rootGetters, dispatch }, page = 1) {
      const ABORT_KEY = 'notificationList'
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      const userId = rootGetters.user.id
      commit('setAbortController', { controller, name: ABORT_KEY })
      try {
        const response = await axiosV3.get(`notifications?filter[user][id]=${userId}&include=author,notifiable&page[number]=${page}&page[limit]=10`, {
          signal: rootGetters.abortControllers[ABORT_KEY].signal
        })
        let history = response.data || []
        if (!history || _.isEmpty(history)) return
        for await (const item of history) {
          item.tag = {
            'App\\Notifications\\SubTaskStatusChanged': 'tasks',
            'App\\Notifications\\CheckOutResult': 'tasks',
            'App\\Notifications\\NewComment': 'comment',
            'App\\Notifications\\UserSimpleTaskStatusChanged': 'tasks',
            'App\\Notifications\\Broadcast\\ManagerSimpleTaskPublished': 'tasks',
          }[item.kind]
          if (item.kind === 'App\\Notifications\\SubTaskStatusChanged') {
            /* TODO api v3: получать id приложения сразу в списке уведомлений по задачам с помощью бэка
              без дополнительного запроса на бэк */
            const response = await axiosV3.get(`subtasks/${item.notifiable.id}?include=application`, {
              signal: rootGetters.abortControllers[ABORT_KEY].signal
            })
            item.notifiable.application = response.data.application
          }
          // запрос на получение id приложения
          if (item.kind === 'App\\Notifications\\CheckOutResult') {
            /* TODO api v3: получать id приложения сразу в списке уведомлений по задачам с помощью бэка
              без дополнительного запроса на бэк */
            const checkResults = await axiosV3.get(`application-checks/${item.notifiable.id}?include=application`, {
              signal: rootGetters.abortControllers[ABORT_KEY].signal
            })
            const lastCheckResult = checkResults.included.find(check => check.type === 'applications')
            const appData = await dispatch('downloadApplicationV3', lastCheckResult.id)
            item.task = {...appData}
          }
          // запрос на получение id simple_user_children
          if (item.kind === 'App\\Notifications\\Broadcast\\ManagerSimpleTaskPublished') {
            const simpleTasks = await axiosV3.get(`subtasks/${item.notifiable.id}?include=related_user_simple`, {
              signal: rootGetters.abortControllers[ABORT_KEY].signal
            })
            const lastSimpleTask = simpleTasks.included.find(include => include.attributes.type === 'user_simple')
            item.notifiable.id = lastSimpleTask.id
          }
        }
        const localHistory = page === 1 ? [] : [...state.notificationHistory]
        commit('setNotificationHistory', [...localHistory, ...history])
        commit('deleteAbortController', ABORT_KEY, { root: true })
        return true
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('Notification list error')
      }
    },

    clearNotificationsHistory({ commit }) {
      commit('setNotificationHistory', [])
    },

    async getComment(_, notice) {
      let urlBuilder = new UrlBuilderApiV3()
      const includes = [
        'subtask',
        'user',
        'subtask.status'
      ]
      urlBuilder.setEndpoint(`comments/${notice.commentId}`)
      urlBuilder.addInclude(includes)
      const url = urlBuilder.build()
      try {
        const response = await axiosV3.get(url)
        return response.data
      } catch(e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('Task list error')
      }
    }
  }
}
