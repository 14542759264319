import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3";

export default {
  state: {
    referralList: [],
    referralListPageCount: null,
  },

  getters: {
    referralList: state => state.referralList,
    referralListPageCount: state => state.referralListPageCount,
  },

  mutations: {
    setReferralList(state, value) {
      state.referralList = value
    },
    setReferralListPageCount(state, value) {
      state.referralListPageCount = value
    },
  },

  actions: {
    async downloadReferralList({ commit, rootGetters }, payload) {
      const ABORT_KEY = 'referrals'
      const { page, searchUser, sortingArgs } = payload
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })

      const urlBuilder = new UrlBuilderApiV3()
        .setEndpoint('/stats/referral-stats')
        .setPage(page)

       if (sortingArgs) urlBuilder.addSorting(sortingArgs)
       if (searchUser) urlBuilder.addFilters(`filter[id]=${searchUser}`)

      const url = urlBuilder.build()

      try {
        const response = await axiosV3.get(url, {
          signal: rootGetters.abortControllers[ABORT_KEY].signal
        })
        const referralList = response.data
        const lastPage = response.meta.page.last_page
        commit('setReferralList', referralList)
        commit('setReferralListPageCount', lastPage)
        commit('deleteAbortController', ABORT_KEY)
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError(e)
      }
    },

    clearReferrals({ commit }) {
      commit('setReferralList', [])
      commit('setReferralListPageCount', null)
    },
  }
}
