<template>
  <div class="block-copy">
    <div class="block-copy__text">
      <slot name="content">
        <span :title="text">{{ text }}</span>
      </slot>
    </div>
    <el-tooltip
      v-if="text"
      effect="dark"
      :content="$t('Copy to clipboard')"
      :open-delay="500">
      <button
        type="button"
        class="block-copy__btn"
        @click="handleCopyBtnClick">
        <el-icon>
          <CopyDocument/>
        </el-icon>
      </button>
    </el-tooltip>
  </div>
</template>

<script>
import { ElTooltip } from 'element-plus'

export default {
  name: 'BlockCopy',
  components: {
    ElTooltip
  },
  props: {
    text: String
  },
  methods: {
    async handleCopyBtnClick() {
      await navigator.clipboard.writeText(this.text)
      this.$notifyInfo('Copied')
    }
  }
}
</script>

<style scoped>

</style>
