<template>
  <div
    class="form-check"
    :class="[{disabled: disabled}, inlineClass]">
    <label :for="cbId" class="form-check-label">
      <input
        :id="cbId"
        v-model="model"
        class="form-check-input"
        type="checkbox"
        :disabled="disabled"
        @change="handleChange">
      <span class="form-check-sign"/>
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
      <el-tooltip
        v-if="labelTooltip"
        :content="labelTooltip"
        placement="top"
        :popper-class="['input-title-tooltip', poperClass]">
        <el-icon>
          <QuestionFilled/>
        </el-icon>
      </el-tooltip>
    </label>
  </div>
</template>

<script>
import { ElTooltip } from 'element-plus'

  export default {
    name: 'PCheckbox',
    components: {
      ElTooltip
    },
    props: {
      modelValue: {
        type: [Array, Boolean],
        description: 'Whether checkbox is checked'
      },
      disabled: {
        type: Boolean,
        description: 'Whether checkbox is disabled'
      },
      inline: {
        type: Boolean,
        description: 'Whether checkbox is inline'
      },
      labelTooltip: {
        type: String,
        default: ''
      },
      poperClass: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        cbId: '',
        touched: false
      }
    },
    computed: {
      model: {
        get() {
          return this.modelValue
        },
        set(check) {
          if (!this.touched) {
            this.touched = true
          }
          this.$emit('update:model-value', check)
        }
      },
      inlineClass() {
        if (this.inline) {
          return `form-check-inline`
        }
      }
    },
    methods: {
      handleChange() {
        this.$emit('toggle')
      }
    },
    created() {
      this.cbId = Math.random().toString(16).slice(2)
    }
  }
</script>
