import { createApp } from 'vue'
import store from './store'
import createRouter from './router/router'

import { axios, axiosV3 } from '@/axios'

import VueClickAway from 'vue3-click-away'
import i18n from './i18n/i18n'

import { createGtm } from '@gtm-support/vue-gtm'
import getGtmOptions from '@/gtm/gtmConfig'

import { Vue3Mq } from 'vue3-mq'
import getMqOptions from './mq/mq'

import echo from '@/echo'

import validator from './validator'


import App from './App.vue'

import 'core-js/actual'
import 'regenerator-runtime/runtime'

import VueTransitions from '@morev/vue-transitions'
import '@morev/vue-transitions/styles'

import 'vue-resize/dist/vue-resize.css'
import VueResize from 'vue-resize'

import { plugin as vueMetaPlugin } from 'vue-meta'
import VueRouterPrefetch from 'vue-router-prefetch'
import initSentry from './sentry'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import GlobalProperties from './globalProperties'
import SideBar from 'src/components/Layout/Navigation/SidebarPlugin'
import sidebarLinks from 'src/router/sidebarRoutes'
import messagePlugin from 'src/utils/message.plugin'


// library imports
import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'
import './assets/sass/style.scss'

import './registerServiceWorker'


window._ = require('lodash')

const app = createApp(App)
app.use(store)
store.$app = app.config.globalProperties

axios(store)
axiosV3(store)

const router = createRouter(store)
app.use(router)
app.use(VueRouterPrefetch)

initSentry(app, router)

const gtm = createGtm(getGtmOptions(router))
app.use(gtm)

app.use(VueClickAway)
app.use(i18n)
app.use(SideBar, { sidebarLinks })
app.use(GlobalComponents)
app.use(GlobalDirectives)
app.use(GlobalProperties)
app.use(messagePlugin, {
  position: 'bottom-left',
  duration: 5000,
  showClose: false
})
app.use(Vue3Mq, getMqOptions())
app.use(echo)
app.use(validator)
app.use(VueTransitions, {
  componentDefaultProps: {
    TransitionFade: {
      mode: 'out-in',
      duration: 700
    },
    TransitionSlide: {
      offset: [0, -16],
      duration: 300
    }
  }
})

app.use(vueMetaPlugin)
app.use(VueResize)


app.mount('#app')
