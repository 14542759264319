export default {
  state: {
    intercomSettings: {
      app_id: 'rolopgu5'
    },
  },
  getters: {
    intercomSettings:  state => state.intercomSettings,
  },
  mutations: {
    setIntercomSettings(state, settings) {
      state.intercomSettings = settings
    },
    resetIntercomSettings(state) {
      state.intercomSettings = {
        app_id: 'rolopgu5'
      }
    },
  },
  actions: {
    startIntercomChat({state}) {
      window.Intercom('boot', state.intercomSettings)
    },
    updateIntercomSettings({state, commit}, newSettings) {
      window.Intercom('update', newSettings)
      const currentSettings = state.intercomSettings
      commit('setIntercomSettings', {
        ...currentSettings,
        ...newSettings,
      })
    },
    stopIntercomChat({ commit }) {
      window.Intercom('shutdown')
      commit('resetIntercomSettings')
    },
  }
}
