<template>
  <div class="wrapper custom-scroll">
    <side-bar
      v-if="user"
      type="sidebar"/>

    <div v-if="user" class="main-panel" :class="flatClass">
      <top-navbar :class="{shadow: scrolled && !flatClass}">
        <template #toolbar>
          <router-view name="toolbar"/>
        </template>
      </top-navbar>
      <div
        ref="content"
        class="content"
        :class="{'custom-scroll': !flatClass}"
        @scroll="handleScroll">
        <router-view v-slot="{ Component }">
          <transition-fade appear>
            <component :is="Component"/>
          </transition-fade>
        </router-view>
      </div>

      <div class="main-panel__dummy" @click="toggleSidebar"/>

      <modal
        class="task-modal custom-scroll"
        :show="showTaskCard"
        type="notice"
        :show-footer="false"
        :is-flex="true"
        close-by-outside-click
        @close="closeTaskModal">
        <template v-if="showTaskCard" #header>
          <div class="task-modal__header">
            <div v-if="modalHeaderData.status" class="task-modal__status" :class="modalHeaderData.status.name">
              {{ modalHeaderData.status.title }}
            </div>
            <preloader
              v-else
              :row-height="28"
              :width="85"
              :margin-bottom="0"/>
            <div class="task-modal__title">
              <div class="task-modal__title-text">
                <div class="task-modal__main-title">
                  {{ modalHeaderData.title }}
                </div>
                <div class="task-modal__sub-title">
                  <i class="fa fa-lg fa-gamepad"/>
                  <span v-if="appName">{{ appName }}</span>
                  <preloader
                    v-else
                    :row-height="20"
                    :width="200"
                    :margin-bottom="0"/>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="showTaskCard">
          <component
            :is="taskCardComponent()"
            v-if="selectedTask"
            :is-modal="true"
            @close="closeTaskModal"/>
          <div v-else class="task-card-dummy">
            <div v-for="index in 3" :key="`preloader-${index}`">
              <preloader :width="800" :row-height="60"/>
              <preloader
                :width="500"
                :rows="5"
                :indent="15"
                :margin-bottom="80"/>
            </div>
          </div>
        </template>
      </modal>
    </div>

    <modal-window/>
  </div>
</template>
<script>
import TopNavbar from './Navigation/TopNavbar.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SUBTASK_EXECUTORS_ROLES, USER_ROLE } from '@/utils/constants'
import Modal from '@/components/UIComponents/Modal'
import Preloader from '@/components/UIComponents/Custom/Preloader'
import ModalWindow from '@/components/Layout/ModalWindow'
import { defineAsyncComponent } from "vue";

export default {
  name: 'MainLayout',
  components: {
    ModalWindow,
    Preloader,
    Modal,
    TopNavbar
  },
  data() {
    return {
      channel: null,
      showTaskCard: false,
      scrolled: false,
      countNotify: 0 // убрать после фикса бага с дублированием уведомлений
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'intercomSettings',
      'selectedApplication',
      'selectedTask',
      'selectedTaskPreview',
      'needShowTaskCard',
      'activeRoleName',
      'userRoles'
    ]),
    interfaceLang() {
      if (!this.user) return
      return this.user.is_internal ? 'ru' : this.user?.interface_language
    },
    taskCardComponent() {
      return {
        aso_manager: () => {
          if (this.selectedTask.type === 'app_manager_update') {
            return defineAsyncComponent(() => import('@/components/Pages/Application/aso_manager/Modals/AppManagerTaskCard'))
          }
          return defineAsyncComponent(() => import('@/components/Pages/Tasks/aso_manager/UpdateTaskSubtaskCard'))
        }
      }[this.activeRoleName]
    },
    modalHeaderData() {
      return this.selectedTask || this.selectedTaskPreview
    },
    appName() {
      if (!this.showTaskCard) return
      return this.selectedApplication ? this.selectedApplication.name : this.selectedTaskPreview.appName
    },
    flatClass() {
      return this.$route.meta && this.$route.meta.flat ? 'flat-layout' : ''
    }
  },
  watch: {
    interfaceLang(value) {
      if (!value) return
      this.$i18n.locale = value
    },
    needShowTaskCard(value) {
      if (!value) return
      this.showTaskCard = true
      this.setNeedShowTaskCard(false)
    }
  },
  methods: {
    ...mapMutations(['setNeedShowTaskCard']),
    ...mapActions(['downloadApplicationV3', 'clearSelectedTaskData', 'updateUserData', 'resetUserRoles', 'addNotification', 'getComment', 'downloadTaskBySubTaskId']),
    ...mapActions({
      getTasksCount: 'executors/getTasksCount',
      downloadSubtaskStatsForApps: 'creator/downloadSubtaskStatsForApps',
      updateSubtaskStatus: 'creator/updateSubtaskStatus',
      downloadSimpleTask: 'app_manager/downloadSimpleTask'
    }),
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    closeTaskModal() {
      this.showTaskCard = false
      this.clearSelectedTaskData()
    },
    handleScroll() {
      const contentBox = this.$refs.content
      const position = contentBox.scrollTop
      const scrolledDown = contentBox.scrollHeight - contentBox.scrollTop - contentBox.clientHeight < 5
      this.$store.dispatch('setScrolledDown', scrolledDown)
      this.scrolled = position > 0
    },
    async eventCallback(data, payload) {
      this.countNotify += 1
      let [tag, eventName] = payload
      if (tag === 'simpleModeration' && data.tag === 'gmail') tag = 'simpleModerationGmail'

      const additionalMethods = async () => {
        switch (eventName) {
          case 'sub-task-status-changed':
            if ([...SUBTASK_EXECUTORS_ROLES, USER_ROLE.tester].includes(this.activeRoleName)) {
              this.getTasksCount()
              this.updateUserData(this.user.id)
            }
            if (this.activeRoleName === USER_ROLE.creator && this.$route.name === 'Applications list creator') {
              this.downloadSubtaskStatsForApps()
              await this.updateSubtaskStatus(data.notification.notifiable)
              const appName = data.notification.notifiable.task.application.name
              this.$notifyInfo({
                key: 'Subtask status updated',
                params: { appName }
              })
            }
            break
          case 'check-out-result':
            const appData = await this.downloadApplicationV3(data.applicationId)
            if (!data.task) data.task = {...appData}
            data.kind = 'App\\Notifications\\CheckOutResult'
            this.$notifyInfo(data.message)
            break
          case 'new-comment':
            const commentInfo = await this.getComment(data)
            this.addNotification({
              ...data,
              commentInfo,
              id: Number(new Date),
              tag,
              taskId: +commentInfo.subtask.id
            })
            break
          case 'manager-simple-task-published':
            await Promise.resolve();
            data.task = {
              id: data.userTaskId,
              type: 'user_simple'
            }
            break
          case 'user-simple-task-status-changed':
            const simpleTask = await this.downloadSimpleTask({id: data.taskId, limit: 1, creAso: true})
            if (!data.task) data.task = {...simpleTask}
            break
        }
      }

      if (process.env.VUE_APP_ENV !== 'prod') {
        console.log('eventCallback:', new Date())
        console.log(`Произошло событие ${eventName}. Полученные данные:`)
        console.log(data)
        console.log('payload:', payload)
        console.log('-------------------')
      }
      const getConditionAbort = () => {
        const defaultAbort = () => !data.message
        let mapperAbort = {
          comment: () => !(data.comment?.text || data.comment?.files)
        }
        return mapperAbort[tag] || defaultAbort
      }
      if (getConditionAbort()() && eventName !== 'new-comment') return

      await additionalMethods()

      if (eventName === 'new-comment') return

      this.addNotification({
        ...data,
        id: Number(new Date),
        tag
      })
    },
    waitForEcho() {
      return new Promise((resolve) => {
        const checkEcho = setInterval(() => {
          if (this.$echo?.socketId()) {
            clearInterval(checkEcho);
            resolve();
          }
        }, 300);
      });
    },
    setupEcho() {
    this.channel = 'user.' + this.user.id

    this.$echo.private(this.channel)
      .listen('.sub-task-status-changed', data => { this.eventCallback(data, ['tasks', 'sub-task-status-changed']) })
      .listen('.task-for-moderation', data => { this.eventCallback(data, ['tasksModeration', 'task-for-moderation']) })
      .listen('.new-comment', data => { this.eventCallback(data, ['comment', 'new-comment']) })
      .listen('.check-out-result', data => { this.eventCallback(data, ['tasks', 'check-out-result']) })
      .listen('.manager-simple-task-published', data => { this.eventCallback(data, ['simpleTaskCreAso', 'manager-simple-task-published']) })
      .listen('.user-simple-task-status-changed', data => { this.eventCallback(data, ['tasks', 'user-simple-task-status-changed']) })

    this.activeRoleName === 'app_manager' && this.$echo.private('role.app_manager')
      .listen('.manager-verification-task-published', data => { this.eventCallback(data, ['tasksVerification', 'manager-verification-task-published']) })
      .listen('.manager-simple-task-published', data => { this.eventCallback(data, ['simpleModeration', 'manager-simple-task-published']) })

      window['axiosV3'].defaults.headers.common['X-Socket-Id'] = this.$echo.socketId()
    },
  },
  beforeMount() {
    if (this.user && !this.user.is_internal) {
      this.updateUserData(this.user.id)
    }
    this.$i18n.locale = this.interfaceLang
    if (!this.userRoles.length) {
      this.resetUserRoles()
    }
    const isInternal = this.user.is_internal
    if (isInternal) {
      this.$store.dispatch('stopIntercomChat')
    } else {
      this.$store.dispatch('startIntercomChat')
      if (this.user.uuid) {
        this.$store.dispatch('updateIntercomSettings', {
          user_id: this.user.uuid
        })
      }
    }
  },
  async mounted() {
    const contentBox = this.$refs.content
    if (contentBox.scrollHeight - contentBox.scrollTop - contentBox.clientHeight <= 0) {
      this.$store.dispatch('setScrolledDown', true)
    }
    await this.waitForEcho()
    this.setupEcho()
  },
  beforeUnmount() {
    this.$echo.leave('role.app_manager')
    this.$echo.leave(this.channel)
    window['axiosV3'].defaults.headers.common['X-Socket-Id'] = null
  }
}

</script>

<style>

</style>
