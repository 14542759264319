import store from 'src/store'
import TOOLBAR_CONFIG from '@/utils/toolbarConfig'
import { SIDEBAR_ROUTES_NAMES } from '@/utils/constants'

const beforeEnterTaskList = (to, from, next) => {
  if (from.path === '/' || from.path.split('/')
    .some(item => item === 'tasks')) {
    const rolesData = JSON.parse(localStorage.getItem('rolesData'))
    if (!store.getters.userRoles.length && rolesData) {
      store.commit('setUserRoles', rolesData)
    }
  } else {
    store.dispatch('resetUserRoles')
  }
  next()
}

export default [
  {
    name: 'User name',
    icon: 'nc-icon nc-circle-10',
    path: '/',
    meta: {
      auth: true,
    },
    component: () => import('src/components/Layout/MainLayout'),
    children: [
      {
        name: 'Profile',
        path: '/user/profile',
        meta: {
          auth: true,
          roles: ['superadministrator', 'creator', 'uidesigner', 'designer', 'programmer', 'tester', 'app_manager', 'aso_manager']
        },
        component: () => import('@/components/Pages/UserData/UserProfileSwitcher')
      },
      {
        name: 'Accruals',
        path: '/user/deposit',
        meta: { auth: true, roles: ['uidesigner', 'designer', 'programmer'], externalOnly: true },
        component: () => import('@/components/Pages/UserData/Deposit')
      },
      {
        name: 'Withdrawal of funds',
        path: '/user/payout',
        meta: { auth: true, roles: ['uidesigner', 'designer', 'programmer'], externalOnly: true },
        component: () => import('@/components/Pages/UserData/Payout')
      },
      {
        name: 'Sign out',
        path: '/user/exit',
        meta: {
          auth: true,
          roles: ['superadministrator', 'creator', 'uidesigner', 'designer', 'programmer', 'tester', 'app_manager', 'aso_manager']
        },
        component: () => import('src/components/Layout/MainLayout')
      }
    ]
  },
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    path: '/dashboard',
    meta: { layout: 'main', auth: true, roles: ['superadministrator', 'app_manager'] },
    component: () => import('src/components/Pages/Dashboard/common/Dashboard')
  },
  {
    name: 'Applications',
    icon: 'nc-icon nc-tile-56',
    path: '/applications',
    meta: { auth: true },
    component: () => import('src/components/Layout/MainLayout'),
    children: [
      {
        name: SIDEBAR_ROUTES_NAMES.creatorNoDevelopNoPublish,
        path: '/applications/no-develop-no-publish',
        meta: {
          auth: true,
          roles: ['creator'],
          flat: true
        },
        components: {
          default: () => import('@/components/Pages/Application/creator/AppNoDevelopNoPublishListTable'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.appNoDevelopNoPublish
        }
      },
      {
        name: SIDEBAR_ROUTES_NAMES.myApps.creatorMyApp,
        path: '/applications/creator-my',
        meta: {
          auth: true,
          roles: ['creator'],
          startPageFor: ['creator'],
          flat: true
        },
        components: {
          default: () => import('@/components/Pages/Application/Applications'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        badgeDataStore: 'tasksListBadge',
        props: {
          toolbar: TOOLBAR_CONFIG.appDevelopPublish
        }
      },
      {
        name: 'Applications list app-manager',
        path: '/applications/app-manager-my',
        meta: {
          auth: true,
          roles: ['app_manager'],
          flat: true
        },
        components: {
          default: () => import('@/components/Pages/Application/Applications'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.appList
        },
        badgeDataStore: 'tasksListBadge'
      },
      {
        name: 'Applications list aso-manager',
        path: '/applications/aso-manager-my',
        meta: {
          auth: true,
          roles: ['aso_manager'],
          startPageFor: ['aso_manager'],
          flat: true
        },
        components: {
          default: () => import('@/components/Pages/Application/Applications'),
        },
        badgeDataStore: 'tasksListBadge'
      },
      {
        name: 'All applications',
        path: '/applications/all',
        meta: {
          auth: true,
          roles: ['creator', 'aso_manager'],
          flat: true,
        },
        component: () => import('src/components/Pages/Application/AllApplications')
      },
      {
        name: SIDEBAR_ROUTES_NAMES.myApps.creatorArchiveApp,
        path: '/applications/archived',
        meta: {
          auth: true,
          roles: ['creator'],
          flat: true
        },
        components: {
          default: () => import('@/components/Pages/Application/Applications'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.archivedApplications
        }
      },
      {
        name: SIDEBAR_ROUTES_NAMES.simpleTasks.simpleTaskCreAso,
        path: '/simple-tasks/list/:role',
        meta: {
          auth: true,
          roles: ['creator', 'aso_manager'],
          flat: true
        },
        badgeDataStore: 'simpleTaskCreAsoListBadge',
        components: {
          default: () => import('src/components/Pages/Tasks/app_manager/SimpleTaskList'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.simpleTaskCreAso
        }
      },
      {
        name: 'Notifications',
        path: '/applications/notifications',
        meta: { auth: true, roles: ['creator', 'aso_manager'] },
        component: () => import('@/components/Pages/Notifications/Notifications')
      },
      {
        name: 'Deletion reasons',
        path: '/applications/deletion-reasons',
        meta: { auth: true, roles: ['app_manager'] },
        component: () => import('src/components/Pages/Application/DeletionReasons')
      }
    ]
  },
  {
    name: 'Users',
    icon: 'nc-icon nc-single-02',
    path: '/',
    meta: { auth: true },
    component: () => import('src/components/Layout/MainLayout'),
    children: [
      {
        name: 'Uploaders list',
        path: '/users/uploaders-list',
        meta: {
          auth: true,
          roles: ['app_manager'],
          flat: true
        },
        components: {
          default: () => import('@/components/Pages/Users/common/ExternalUserList'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.publishersList
        }
      },
      {
        name: 'Internal users',
        path: '/users/internal-list',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('src/components/Pages/Users/administrator/InternalUserList')
      },
      {
        name: 'External users',
        path: '/users/external-list',
        meta: { auth: true, roles: ['superadministrator'], flat: true },
        components: {
          default: () => import('@/components/Pages/Users/common/ExternalUserList'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.externalUsersList
        }
      },
      {
        name: 'Accruals List',
        path: '/users/accruals',
        meta: { auth: true, roles: ['superadministrator', 'app_manager'] },
        component: () => import('src/components/Pages/Users/common/Accruals')
      }
    ]
  },
  {
    name: 'Tasks',
    icon: 'nc-icon nc-paper',
    path: '/',
    meta: { auth: true },
    badgeDataStore: 'tasksBadge',
    component: () => import('src/components/Layout/MainLayout'),
    children: [
      {
        name: 'Task list executor',
        path: '/todo/list',
        meta: {
          roles: ['uidesigner', 'designer', 'programmer', 'tester'],
          startPageFor: ['uidesigner', 'designer', 'programmer', 'tester']
        },
        badgeDataStore: 'tasksListBadge',
        component: () => import('src/components/Pages/Tasks/TaskList'),
        beforeEnter: beforeEnterTaskList
      },
      {
        name: 'Notifications executor',
        path: '/todo/notifications',
        meta: { auth: true, roles: ['uidesigner', 'designer', 'programmer', 'tester'] },
        component: () => import('@/components/Pages/Notifications/Notifications')
      }
    ]
  },
  {
    name: 'Publication',
    icon: 'nc-icon nc-app',
    path: '/',
    meta: { auth: true },
    badgeDataStore: 'tasksBadge',
    component: () => import('src/components/Layout/MainLayout'),
    children: [
      {
        name: 'Task list publication',
        path: '/tasks/list',
        meta: {
          auth: true,
          roles: ['app_manager'],
          flat: true
        },
        badgeDataStore: 'tasksListBadge',
        components: {
          default: () => import('@/components/Pages/Tasks/app_manager/TaskList'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.tasks
        }
      },
      {
        name: 'Moderation',
        path: '/tasks/moderations',
        meta: { auth: true, roles: ['app_manager'] },
        badgeDataStore: 'tasksModerationListBadge',
        component: () => import('src/components/Pages/Tasks/TaskModerationList')
      },
      {
        name: 'Verification',
        path: '/tasks/verification',
        meta: { auth: true, roles: ['app_manager'] },
        badgeDataStore: 'tasksVerificationListBadge',
        component: () => import('src/components/Pages/Tasks/TaskVerificationList')
      }
    ]
  },
  {
    name: 'Simple tasks',
    icon: 'nc-icon nc-paper',
    path: '/',
    badgeDataStore: 'simpleTasksBadge',
    component: () => import('src/components/Layout/MainLayout'),
    children: [
      {
        name: SIDEBAR_ROUTES_NAMES.simpleTasks.simpleTaskAppManager,
        path: '/simple-tasks/list',
        meta: {
          auth: true,
          roles: ['app_manager'],
          flat: true
        },
        components: {
          default: () => import('src/components/Pages/Tasks/app_manager/SimpleTaskList'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.simpleTask
        }
      },
      {
        name: SIDEBAR_ROUTES_NAMES.simpleTasks.simpleModerationTasks,
        path: '/simple-tasks/moderations',
        meta: {
          auth: true,
          roles: ['app_manager'],
          flat: true
        },
        badgeDataStore: 'simpleModerationListBadge',
        components: {
          default: () => import('@/components/Pages/Tasks/app_manager/SimpleModerationList'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.simpleTaskModeration
        }
      },
      {
        name: SIDEBAR_ROUTES_NAMES.simpleTasks.simpleModerationGmailTasks,
        path: '/simple-tasks/moderations-gmail',
        meta: {
          auth: true,
          roles: ['app_manager'],
          flat: true
        },
        badgeDataStore: 'simpleModerationGmailListBadge',
        components: {
          default: () => import('@/components/Pages/Tasks/app_manager/SimpleModerationList'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.simpleTasksModerationGmail
        }
      }
    ]
  },
  {
    name: 'Simple tasks admin',
    icon: 'fa fa-google',
    path: '/',
    component: () => import('src/components/Layout/MainLayout'),
    children: [
      {
        name: SIDEBAR_ROUTES_NAMES.simpleTasks.simpleTaskAdmin,
        path: '/other-tasks',
        meta: {
          auth: true,
          roles: ['superadministrator'],
          flat: true
        },
        components: {
          default: () => import('src/components/Pages/Tasks/app_manager/SimpleTaskList'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.simpleTaskAdmin
        }
      }
    ]
  },
  {
    name: 'Settings',
    icon: 'nc-icon nc-settings',
    path: '/',
    meta: { auth: true },
    component: () => import('src/components/Layout/MainLayout'),
    children: [
      {
        name: 'Currency',
        path: '/settings/currency',
        meta: { auth: true, roles: ['superadministrator', 'app_manager'] },
        component: () => import('src/components/Pages/Settings/Currency')
      },
      {
        name: 'Task cost limits',
        path: '/settings/limits',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('@/components/Pages/Tasks/TaskCostLimits')
      },
      {
        name: 'Payout limits',
        path: '/settings/payout-limits',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('@/components/Pages/Settings/PayoutOptions')
      },
      {
        name: 'Deletion reasons appmanager',
        path: '/settings/deletion-reasons',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('@/components/Pages/Settings/DeletionReasons')
      },
      {
        name: 'Frameworks',
        path: '/settings/frameworks',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('@/components/Pages/Settings/FrameworksSettings')
      },
      {
        name: 'Programming languages',
        path: '/settings/programming-languages',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('@/components/Pages/Settings/ProgLangsSettings')
      },
      {
        name: 'Referral system',
        path: '/settings/referral-system',
        meta: {
          auth: true,
          roles: ['superadministrator'],
          flat: true
        },
        components: {
          default: () => import('@/components/Pages/Settings/Referrers'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.referralSystem
        }
      },
      {
        name: 'Promo code',
        path: '/settings/promo-code',
        meta: {
          auth: true,
          roles: ['superadministrator'],
          flat: true
        },
        components: {
          default: () => import('@/components/Pages/Settings/PromoCode/PromoCodesList'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.promoCode
        }
      },
      {
        name: 'Layouts email',
        path: '/settings/email-layouts',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('@/components/Pages/Settings/EmailLayoutsSettings')
      },
      {
        name: 'E-mail localizations',
        path: '/settings/localizations-email',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('@/components/Pages/Settings/EmailLocalizations')
      },
      {
        name: 'SMS localizations',
        path: '/settings/localizations-sms',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('@/components/Pages/Settings/SmsLocalizations')
      },
      {
        name: 'Passport verification',
        path: '/settings/passport-verification',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('@/components/Pages/Settings/PassportVerification')
      },
      {
        name: 'Task options',
        path: '/settings/tasks',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('@/components/Pages/Tasks/TaskOptions')
      },
      {
        name: 'Console commands',
        path: '/settings/console-commands',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('@/components/Pages/Settings/ConsoleCommands')
      }
    ]
  },
  {
    name: 'Stats',
    icon: 'nc-icon nc-chart-pie-36',
    path: '/',
    meta: { auth: true },
    component: () => import('src/components/Layout/MainLayout'),
    children: [
      {
        name: 'Referrals',
        path: '/stats/referrals',
        meta: { auth: true, roles: ['superadministrator', 'app_manager'] },
        component: () => import('src/components/Pages/Stats/Referrals')
      },
      {
        name: 'Expenses',
        path: '/stats/expenses/rub',
        meta: { auth: true, roles: ['superadministrator'] },
        components: {
          default: () => import('@/components/Pages/Stats/Expenses'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.expenses
        }
      },
      {
        name: 'Expenses usd',
        path: '/stats/expenses/usd',
        meta: {
          auth: true,
          roles: ['superadministrator'],
          flat: true
        },
        components: {
          default: () => import('@/components/Pages/Stats/Expenses'),
          toolbar: () => import('@/components/UIComponents/Toolbar/Toolbar')
        },
        props: {
          toolbar: TOOLBAR_CONFIG.expenses
        }
      },
      {
        name: 'Task expenses',
        path: '/stats/task-expenses',
        meta: { auth: true, roles: ['superadministrator'] },
        component: () => import('src/components/Pages/Stats/TaskExpenses')
      }
    ]
  }
]
