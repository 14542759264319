import moment from 'moment'
import { openLinkInNewTab, prepareUserData } from '@/utils/helpers'
import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3";
import FormDataV3 from "@/utils/formDataApiV3";
import { parseQueryStringToObject} from '../../utils/helpers'

export default {
  async downloadAttractionCodesList({ commit, rootGetters }, payload) {
    // Получение списка реферральных / промо кодов

    const { type, page } = payload
    const ABORT_KEY = type
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    const rootSearch = rootGetters['listSearch']
    const search = rootSearch?.includes('#') ? rootSearch.replace('#', '%23') : rootSearch
    let searchArg = ''
    let url = `v2/admin/${type}/users?page=${page}${searchArg}`
    if (search) searchArg = `&search=${search}`
    if (type === 'promo-codes') {
      const urlBuilder = new UrlBuilderApiV3()
      urlBuilder.setEndpoint(type)
        .addInclude('users')
        .setPage(page)
      if (search) urlBuilder.setSearch(search)
      url = urlBuilder.build()
    }
    try {
      const typeAxios = type === 'promo-codes' ? axiosV3 : axios
      const response = await typeAxios.get(url, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
      const attractionCodes = type === 'promo-codes' ? response.data : response.data.response.data
      const metaData = type === 'promo-codes' ? response.meta.page : response.data.response.meta
      commit('setAttractionCodes', attractionCodes)
      commit('setAttractionCodesPageCount', metaData.last_page)
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      if (e.handled || e.code === 'ERR_CANCELED') return
      const title = type === 'referral-system' ? 'реферальной системе' : 'промокодам'
      this.$app.$notifyError(`При загрузке данных по ${title} произошла ошибка. Попробуйте позже.`)
    }
  },

  async uploadEditedReferralCode({ commit, rootGetters }, model) {
    // Отправка данных для обновления настроек конкретного реферального кода
    const id = rootGetters.selectedAttractionCode.id
    const data = new FormData()
    for (const [key, value] of Object.entries(model)) {
      data.set(key, value)
    }
    try {
      await axios.put(`v2/admin/referral-system/users/${id}`, data)
      commit('updateAttractCodeSettingsById', { id, settings: model })
      this.$app.$notifySuccess('Настройки сохранены')
      return true
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('При сохранении данных произошла ошибка. Попробуйте позже.')
    }
  },

  async uploadPromoCode({ commit, rootGetters }, payload) {
    // Отправка данных для создания/обновления настроек конкретного промокода
    const { type, model } = payload
    const isEdit = type === 'edit'
    let id = ''
    const idRoot = rootGetters.selectedAttractionCode?.id
    const data = new FormDataV3()
    data.addParam('type', 'promo-codes')
    let typeReq = 'post'
    let titleError = 'создании'
    if (isEdit) {
      id = `/${rootGetters.selectedAttractionCode.id}`
      typeReq = 'patch'
      titleError = 'сохранении'
      data.addParam('id', idRoot)
    }
    const getSetter = (key, value) => {
      const setDefault = () => {
        data.addAttr(key, value)
      }
      const setConfig = () => {
        data.addAttr('config', {bonus: value})
      }
      const setNumber = () => {
        data.addAttr(key, +value)
      }
      const mapper = {
        bonus: setConfig,
        limit: setNumber
      }
      return mapper[key] || setDefault
    }
    for (const [key, value] of Object.entries(model)) {
      getSetter(key, value)()
    }
    try {
      await axiosV3[typeReq](`promo-codes${id}`, data)
      if (isEdit) {
        commit('updateAttractCodeSettingsById', { id: idRoot, settings: model })
        this.$app.$notifySuccess(`Промокод успешно сохранен`)
      }
      return true
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError(`При ${titleError} промокода произошла ошибка. Попробуйте позже.`)
    }
  },

  // Получение списка email-шаблонов
  async downloadEmailLayoutsList({ dispatch, commit, rootGetters }, payload) {
    const { page, searchEmailLayouts, isEmailLocalization } = payload
    const ABORT_KEY = 'emailLayout'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    const searchArg = searchEmailLayouts ? `&search=${searchEmailLayouts}` : ''
    try {
      const response = await axios.get(`v2/admin/email-templates?page=${page}${searchArg}`, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const emailLayouts = response.data.response.data
      const metaData = response.data.response.meta
      commit('deleteAbortController', ABORT_KEY, { root: true })
      if (isEmailLocalization) {
        commit('setEmailLayoutsId', emailLayouts)
        if (metaData.last_page > metaData.current_page) {
          dispatch('downloadEmailLayoutsList', {
            page: ++metaData.current_page,
            searchEmailLayouts: '',
            isEmailLocalization: true
          })
        }
        return
      }
      commit('setEmailLayouts', emailLayouts)
      commit('setEmailLayoutsPageCount', metaData.last_page)
    } catch (e) {
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('При загрузке данных по e-mail шаблонам произошла ошибка. Попробуйте позже.')
    }
  },

  async uploadEditedEmailLayout({ state, commit }, initData) {
    // Отправка данных для обновления настроек у конкретного email шаблона

    const id = state.selectedEmailLayout.id
    const data = new FormData()
    for (const [key, value] of Object.entries(initData)) {
      data.set(key, value)
    }
    try {
      await axios.put(`/v2/admin/email-templates/${id}`, data)
      commit('updateEmailLayoutById', { id, emailLayoutSettings: initData, isCreateLayout: false })
      this.$app.$notifySuccess('Шаблон изменен')
      return true
    } catch (e) {
      if (e.handled) return
      this.$app.$notifyError('При сохранении данных произошла ошибка. Попробуйте позже.')
    }
  },

  // Создание email шаблона
  async uploadCreatedEmailLayout({ commit }, initData) {
    const data = new FormData()
    for (const [key, value] of Object.entries(initData)) {
      if (key === 'id') {
        data.set('template_id', value)
        continue
      }
      data.set(key, value)
    }
    try {
      const response = await axios.post(`v2/admin/email-templates`, data)
      commit('updateEmailLayoutById', {
        id: response.data.response.id,
        emailLayoutSettings: initData,
        isCreateLayout: true
      })
      this.$app.$notifySuccess('E-mail шаблон создан')
      return true
    } catch (e) {
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('При создании E-mail шаблона произошла ошибка. Попробуйте позже.')
    }
  },

  // Удаление email шаблона
  async removeEmailLayout({ state, commit, dispatch }) {
    const id = state.selectedEmailLayout.id
    commit('setLoading', true)
    try {
      await axios.delete(`/v2/admin/email-templates/${id}`)
      this.$app.$notifySuccess('Email шаблон удалён')
      if (this.$app.$route.name === 'Layout email') {
        this.$app.$router.push({ name: 'Layouts email' })
      } else {
        dispatch('removeEmailLayoutCommonPage', { id })
      }
    } catch (e) {
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Не получилось удалить шаблон. Попробуйте позже.')
    } finally {
      commit('setLoading', false)
    }
  },

  removeEmailLayoutCommonPage({ state, commit, dispatch }, payload) {
    // удаление email шаблона из store и опр. отображаемой страницы при нахождении на странице со списком шаблонов

    const { id } = payload
    if (state.emailLayouts.length === 1 || !state.emailLayouts.length) {
      dispatch('downloadEmailLayoutsList', {
        page: state.emailLayoutsPageCount - 1,
        searchEmailLayouts: state.searchEmailLayouts,
        isEmailLocalization: false
      })
      return
    }
    commit('removeEmailLayout', id)
  },

  // Отправка тестового шаблона
  async sendTestEmailLayout(ctx, model) {
    const data = new FormData()
    for (const [key, value] of Object.entries(model)) {
      if (Array.isArray(value)) {
        value.forEach((titleEvent, index) => {
          data.set(`${key}[${index}]`, titleEvent)
        })
      } else {
        data.set(key, value)
      }
    }
    try {
      await axios.post(`v2/admin/email-send`, data)
      this.$app.$notifySuccess('Тестовый E-mail шаблон успешно отправлен')
      return true
    } catch (e) {
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('При отправке тестового E-mail шаблона произошла ошибка. Попробуйте позже.')
    }
  },

  async downloadTaskExpensesList({ commit, rootGetters }, payload) {
    // Получение списка расходов на задачи

    const { dateRange, filter } = payload
    const getDateRangeString = () => {
      const dateFrom = moment(dateRange[0]).format('YYYY-MM-DD')
      const dateTo = moment(dateRange[1]).format('YYYY-MM-DD')
      return `&from=${dateFrom}&to=${dateTo}`
    }
    const getRoleFilterString = () => {
      if (filter.role === 'all') return ''
      const roles = filter.role.split('+')
      let result = ''
      roles.map((item, index) => {
        result += `&role[${index}]=${item}`
      })
      return result
    }
    const getTypeFilterString = () => {
      if (filter.taskType === 'all') return ''
      return `&${filter.taskType}=1`
    }
    const ABORT_KEY = 'taskExpensesList'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    try {
      const params = getDateRangeString() + getRoleFilterString() + getTypeFilterString()
      const response = await axios.get(`superadministrator/statistic/withdrawal?currency=rub${params}`, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const taskExpensesList = response.data.response
      commit('setTaskExpensesList', taskExpensesList)
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Не удалось обновить статистику. Попробуйте позже.')
    }
  },

  async downloadExpensesList({ commit, rootGetters }, payload) {
    // Получение списка расходов
    const { currency } = payload
    const filtersString = rootGetters['selectedFilters']
    const filters = parseQueryStringToObject(filtersString)
    let filterArgs = filtersString ? `&role_name=${filters.filter.roles.name}` : ''
    const ABORT_KEY = 'expensesList'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    try {
      const response = await axios.get(`admin/withdrawal?currency=${currency}${filterArgs}`, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const expensesList = response.data.response
      commit('setExpensesList', expensesList)
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Не удалось обновить список расходов. Попробуйте позже.')
    }
  },

  async downloadProgrammingLanguagesList({ commit, rootGetters }) {
    // Получение списка языков программирования

    const ABORT_KEY = 'progLangsList'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    try {
      const response = await axios.get(`v2/admin/programming_language`, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const list = response.data.response
      commit('setProgrammingLanguagesList', list)
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Не удалось получить список языков программирования. Попробуйте позже.')
    }
  },

  async uploadNewProgrammingLanguage(ctx, payload) {
    // Создание нового языка программирования

    if (!payload) return
    const { name, frameworks } = payload
    const data = new FormData()
    data.set('name', name.toLowerCase().trim())
    data.set('title', name.trim())
    frameworks.map((item, index) => data.set(`frameworks[${index}]`, item))
    try {
      await axios.post('v2/admin/programming_language', data)
      this.$app.$notifySuccess('Язык программирования добавлен')
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось добавить язык программирования. Попробуйте позже.')
    }
  },

  async uploadEditedProgrammingLanguage(ctx, payload) {
    // Редактирование языка программирования

    if (!payload) return
    const { name, id, frameworks, initName } = payload
    const data = new FormData()
    if (name !== initName) {
      data.set('name', name.toLowerCase().trim())
    }
    data.set('title', name.trim())
    frameworks.map((item, index) => data.set(`frameworks[${index}]`, item))
    try {
      await axios.put(`v2/admin/programming_language/${id}`, data)
      this.$app.$notifySuccess('Язык программирования успешно обновлен')
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError(e)
    }
  },

  async uploadDeletedProgrammingLanguage(ctx, id) {
    // Удаление языка программирования

    try {
      await axios.delete(`v2/admin/programming_language/${id}`)
      this.$app.$notifySuccess('Язык программирования удален')
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError(e)
    }
  },

  clearProgrammingLanguageList({ commit }) {
    // Очистка списка языков программирования

    commit('setProgrammingLanguagesList', [])
  },

  async downloadFrameworksList({ commit, rootGetters }) {
    // Получение списка фреймворков

    const ABORT_KEY = 'frameworksList'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    try {
      const response = await axios.get(`v2/admin/framework`, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const list = response.data.response
      commit('setFrameworksList', list)
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Не удалось получить список фреймворков. Попробуйте позже.')
    }
  },

  async uploadNewFramework(ctx, payload) {
    // Создание нового фреймворка

    if (!payload) return
    const { name, programmingLangs } = payload
    const data = new FormData()
    data.set('name', name.toLowerCase().trim())
    data.set('title', name.trim())
    programmingLangs.map((item, index) => data.set(`programming_languages[${index}]`, item))
    try {
      await axios.post('v2/admin/framework', data)
      this.$app.$notifySuccess('Фреймворк добавлен')
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось добавить фреймворк. Попробуйте позже.')
    }
  },

  async uploadEditedFramework(ctx, payload) {
    // Редактирование фреймворка

    if (!payload) return
    const { name, id, programmingLangs, initName } = payload
    const data = new FormData()
    if (name !== initName) {
      data.set('name', name.toLowerCase().trim())
    }
    data.set('title', name.trim())
    programmingLangs.map((item, index) => data.set(`programming_languages[${index}]`, item))
    try {
      await axios.put(`v2/admin/framework/${id}`, data)
      this.$app.$notifySuccess('Фреймворк успешно обновлен')
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError(e)
    }
  },

  async uploadDeletedFramework(ctx, id) {
    // Удаление фреймворка

    try {
      await axios.delete(`v2/admin/framework/${id}`)
      this.$app.$notifySuccess('Фреймворк удален')
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError(e)
    }
  },

  clearFrameworkList({ commit }) {
    // Очистка списка фреймворков

    commit('setFrameworksList', [])
  },

  async downloadDeletionReasonsList({ commit, rootGetters }, payload) {
    // Получение списка причин удаления

    const { page } = payload
    const ABORT_KEY = 'deletionReasons'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    try {
      const response = await axios.get(`v2/admin/deletion-reason/type?page=${page}`, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const list = response.data.response.data
      const lastPage = response.data.response.meta.last_page
      commit('setDeletionReasonsList', list)
      commit('setDeletionReasonsListPageCount', lastPage)
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Не удалось получить список причин удаления. Попробуйте позже.')
    }
  },

  async uploadNewDeletionReason(ctx, payload) {
    // Создание новой причины удаления

    try {
      await axios.post('v2/admin/deletion-reason/type', payload)
      this.$app.$notifySuccess('Причина удаления добавлена')
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось добавить причину удаления. Попробуйте позже.')
    }
  },

  async uploadEditedDeletionReason(ctx, payload) {
    // Редактирование причины удаления

    if (!payload) return
    const { name, id } = payload
    const data = new FormData()
    data.set('title', name)
    data.set('name', name)
    try {
      await axios.put(`v2/admin/deletion-reason/type/${id}`, data)
      this.$app.$notifySuccess('Причина удаления успешно обновлена')
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError(e)
    }
  },

  async uploadDeletedDeletionReason(ctx, id) {
    // Удаление причины удаления

    try {
      await axios.delete(`v2/admin/deletion-reason/type/${id}`)
      this.$app.$notifySuccess('Причина удаления удалена')
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError(e)
    }
  },

  clearDeletionReasonsList({ commit }) {
    // Очистка списка причин удаления

    commit('setDeletionReasonsList', [])
    commit('setDeletionReasonsListPageCount', null)
  },

  async downloadPayoutLimits({ commit, rootGetters }) {
    // Получение списка лимитов выводов средств

    const ABORT_KEY = 'payoutLimits'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    let url = new UrlBuilderApiV3()
      .setEndpoint('types')
      .addFilters('filter[type]=payout-limits')
      .build()
    try {
      const response = await axiosV3.get(url, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const list = response.data
      commit('setPayoutLimits', list)
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Настройки лимитов выводов средств получить не удалось. Попробуйте позже.')
    }
  },

  async editPayoutLimits({ dispatch }, changedData) {
    // Редактирование лимитов выводов средств

    const promises = []
    for (const [key, value] of Object.entries(changedData)) {
      let data = new FormDataV3()
      data.addParam('type', 'payout-limits')
      data.addParam('id', key)
      data.addAttr('value', value)
      let url = new UrlBuilderApiV3()
        .setEndpoint(`types/${key}`)
        .build()
      promises.push(axiosV3.patch(url, data))
    }
    await Promise.allSettled(promises)
    try {
      await dispatch('downloadPayoutLimits')
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Настройки лимитов выводов средств обновить не удалось. Попробуйте позже.')
    }
  },

  async downloadInternalUserList({ commit, rootGetters }, payload) {
    // Получение списка внутренних пользователей

    const { page, searchUser } = payload
    const searchUserArg = searchUser ? `&search=${searchUser}` : ''
    const ABORT_KEY = 'userList'
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    try {
      const response = await axios.get(`admin/users?page=${page}&is_internal=1${searchUserArg}`, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const userList = response.data.response.data
      const metaData = response.data.response.meta
      commit('setInternalUserList', userList)
      commit('setInternalUserListPageCount', metaData.last_page)
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError(e)
    }
  },

  async uploadInternalUserData({ commit, dispatch, rootGetters }, payload) {
    // Отправка данных внутреннего пользователя для создания или редактирования

    const { type, data } = payload
    const formData = prepareUserData(data)
    const url = 'superadministrator/users'
    try {
      let response
      switch (type) {
        case 'add':
          response = await axios.post(url, formData)
          break
        default:
          const userId = rootGetters.selectedUser.id
          response = await axios.put(`${url}/${userId}`, formData)
          const user = response.data.response
          const userFormData = {
            ...user,
            role: user.roles && user.roles.length ? user.roles[0] : ''
          }
          commit('setSelectedUser', userFormData, { root: true })
          dispatch('updateUserInList', user)
          this.$app.$notifySuccess(`Данные пользователя ${user.first_name} изменены`)
      }
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError(e)
    }
  },

  async uploadDeletedInternalUser(ctx, id) {
    // Удаление внутреннего пользователя

    try {
      await axios.delete(`superadministrator/users/${id}`)
      this.$app.$notifySuccess('Пользователь удален')
      return true
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError('Не удалось удалить пользователя')
    }
  },

  updateUserInList({ state, commit }, updatedUser) {
    const userList = state.internalUserList.map(user => {
      if (user.id === updatedUser.id) return updatedUser
      return user
    })
    commit('setInternalUserList', userList)
  },

  clearUserListData({ commit }) {
    // Очистка списка внутренних пользователей

    commit('setInternalUserList', [])
    commit('setInternalUserListPageCount', null)
  },

  // Получение списка локализаций
  async downloadLocalizationsList({ commit, rootGetters }, payload) {
    const { page, type, search } = payload
    const ABORT_KEY = type
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    const searchArg = search ? `&search=${search}` : ''
    let path = 'sms-localisations'
    let url = `v2/admin/${path}?page=${page}${searchArg}`
    let notifyName = 'SMS'
    let typePageCount = 'smsLocalizationsPageCount'
    if (type === 'emailLocalizations') {
      path = 'email-localisations'
      notifyName = 'Email'
      typePageCount = 'emailLocalizationsPageCount'
      url = `v2/admin/${path}?page=${page}${searchArg}`
    }
    try {
      const response = await axios.get(url, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const localizations = response.data.response.data.map(localization => {
        for (const [key, value] of Object.entries(localization)) {
          if (typeof value === 'object') {
            let keyRu = (type === 'emailLocalizations') ? `${key}_ru` : 'ru'
            let keyEn = (type === 'emailLocalizations') ? `${key}_en` : 'en'
            if (value === null) {
              localization[keyRu] = ''
              localization[keyEn] = ''
            } else {
              localization[keyRu] = localization[key].ru
              localization[keyEn] = localization[key].en
            }
            delete localization[key]
          }
          if (key === 'event_alias') localization[key] = value.toLowerCase()
        }
        return localization
      })
      const last_page = response.data.response.meta.last_page
      commit('setLocalizations', { localizations, type })
      commit('setLocalizationsPageCount', { last_page, type: typePageCount })
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError(`При загрузке ${notifyName} локализаций произошла ошибка. Попробуйте позже.`)
    }
  },
  // Получение списка опций для редактирования email шаблона, локализации и sms локализации
  async downloadOptionsList({ state, commit }, payload) {
    const { type, notifyName } = payload
    let url = 'v2/admin/email-options'
    if (type === 'smsOptions') url = 'v2/admin/sms-options'
    commit('setWaitingResponse', true)
    try {
      const response = await axios.get(url)
      const options = response.data.response
      commit('setLocalizationOptions', { options, type })
    } catch (e) {
      if (e.handled || e.code === 'ERR_CANCELED') return
      if (state.isShowModal) {
        this.$app.$notifyError(`При загрузке функционала редактирования ${notifyName} произошла ошибка. Попробуйте позже.`)
      }
    } finally {
      commit('setWaitingResponse', false)
    }
  },
  // Удаление локализации
  async removeLocalization({ state, dispatch }, payload) {
    const { type } = payload
    let notifyName = 'Email'
    let url = 'email-localisations'
    if (type === 'smsLocalizations') {
      notifyName = 'SMS'
      url = 'sms-localisations'
    }
    const id = state.selectedLocalization.id
    try {
      await axios.delete(`/v2/admin/${url}/${id}`)
      this.$app.$notifySuccess(`${notifyName} локализация удалена`)
      if (this.$app.$route.name === 'Localization email') {
        this.$app.$router.push({ name: 'E-mail localizations' })
      } else {
        dispatch('removeLocalizationCommonPage', { id, type })
      }
    } catch (e) {
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError(`Не получилось удалить ${notifyName} локализацию. Попробуйте позже.`)
    }
  },
  removeLocalizationCommonPage({ state, commit, dispatch }, payload) {
    // удаление локализации из store и опр. отображаемой страницы при нахождении на странице со списком локализации
    const { id, type } = payload
    commit('removeLocalization', { id, type })
    if (!state[type].length) {
      dispatch('downloadLocalizationsList', {
        page: state[`${type}PageCount`] - 1,
        type,
        search: ''
      })
    }
  },
  // Отправка данных для обновления настроек у конкретной sms локализации
  async uploadEditedLocalization({ state, commit }, payload) {
    const { initData, type } = payload
    const id = state.selectedLocalization.id
    const data = (payload.type === 'smsLocalizations') ? new URLSearchParams() : new FormData()
    for (const [key, value] of Object.entries(initData)) {
      data.set(key, value)
    }
    let notifyName = 'Email'
    let url = 'email-localisations'
    let typeReq = 'put'
    let config = ''
    if (payload.type === 'smsLocalizations') {
      notifyName = 'SMS'
      url = 'sms-localisations'
      typeReq = 'patch'
      config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    }
    try {
      await axios[typeReq](`/v2/admin/${url}/${id}`, data, config)
      commit('updateLocalizeSettingsById', { id, localizeSettings: initData, isCreateLocalize: false, type })
      this.$app.$notifySuccess(`${notifyName} локализация изменена`)
      return true
    } catch (e) {
      if (e.handled) return
      this.$app.$notifyError('При сохранении данных произошла ошибка. Попробуйте позже.')
    }
  },
  // Создание sms локализации
  async uploadCreatedLocalization(ctx, payload) {
    const data = new FormData()
    for (const [key, value] of Object.entries(payload.initData)) {
      if (key === 'id') {
        payload.type === 'emailLocalizations' && data.set('template_id', value)
        continue
      }
      data.set(key, value)
    }
    let notifyName = 'Email'
    let url = 'email-localisations'
    if (payload.type === 'smsLocalizations') {
      notifyName = 'SMS'
      url = 'sms-localisations'
    }
    try {
      await axios.post(`v2/admin/${url}`, data)
      this.$app.$notifySuccess(`${notifyName} локализация создана`)
      return true
    } catch (e) {
      if (e.handled) return
      this.$app.$notifyError('При создании локализации произошла ошибка. Попробуйте позже.')
    }
  },
  // Обновление статуса переключателя с сервера для верификации паспорта
  async updateStatusPassportVerify({ commit }, payload) {
    const { flag, type } = payload
    const url = 'v2/admin/switch-passport-verification'
    commit('changeDisabledTrigger', { isDisabled: true, type })
    const data = new URLSearchParams()
    data.set('value', `${Number(flag)}`)
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    try {
      await axios.patch(url, data, config)
      commit('setPassportVerifyStatus', flag)
      return true
    } catch (e) {
      this.$app.$notifyError('При обновлении статуса верификации паспорта произошла ошибка. Попробуйте позже.')
    } finally {
      commit('changeDisabledTrigger', { isDisabled: false, type })
    }
  },
  async signInToAnotherAccount({ commit, rootGetters }) {
    // Получение токена и данных для авторизации под выбранным пользователем

    const id = rootGetters.selectedUser.id

    try {
      const { data: { response: { access_token, user }}} = await axios(`v2/admin/another-auth/${id}`)
      if (!access_token || !user) throw new Error()
      localStorage.setItem('token', access_token)
      commit('setUser', user, { root: true })
      this.$app.$notifyInfo(`Вы вошли в личный кабинет пользователя ${user.first_name} с ролью ${user.roles[0].name}`)
      return user.roles[0].name
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('При попытке входа произошла ошибка. Попробуйте позже.')
    }
  },
  async getPublisherTokenAndRedirect({ rootGetters }) {
    // Получение токена для авторизации под выбранным паблишером и открытие его ЛК в новой вкладке

    const id = rootGetters.selectedUser.id

    try {
      const { data: { response: { access_token, user }}} = await axios(`v2/admin/another-auth/${id}`)
      const url = `${process.env.VUE_APP_PUBLISHER_URL}?token=${access_token}&uid=${user.id}`
      openLinkInNewTab(url)
    } catch (e) {
      console.log(e)
      if (e.handled) return
      this.$app.$notifyError('При попытке входа произошла ошибка. Попробуйте позже.')
    }
  },
  async getConsoleCommands({ commit }) {
    try {
      const response = await axios.get('admin/commands')
      const commands = response.data.response
      commit('setConsoleCommands', commands)
    } catch (e) {
      if (e.handled) return
      this.$app.$notifyError(`При загрузке консольных команд произошла ошибка. Попробуйте позже.`)
      commit('setConsoleCommands', {})
    }
  },
  async runCommand(ctx, command) {
    const data = new FormData()
    data.set('command', command)
    try {
      const response = await axios.post('admin/commands', data)
      this.$app.$notifySuccess(response.data.response.message)
    } catch (e) {
      if (e.handled) return
      this.$app.$notifyError(`При выполнении команды произошла ошибка. Попробуйте позже.`)
    }
  },
  async getMailing({ commit }, payload) {
    const {type, url} = payload
    try {
      const response = await axios.get(url)
      const result = response.data.response
      const titleList = type === 'sms' ? 'services' : 'domains'
      commit('setMailingList', {type, selected: result.currents, list: result[titleList]})
    } catch (e) {
      if (e.handled) return
      this.$app.$notifyError(`При загрузке ${type} рассылок произошла ошибка. Попробуйте позже.`)
    }
  },
  async editMailing({ commit }, payload) {
    const {type, service, value, url} = payload
    try {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      const data = new URLSearchParams()
      const serviceName = type === 'sms' ? 'service' : 'domain'
      const channelName = type === 'sms' ? 'channel' : 'mailer'
      data.set(serviceName, service)
      data.set(channelName, value)
      await axios.put(url, data, config)
      commit('updateMailing', payload)
      this.$app.$notifySuccess(`Действия с ${type} рассылками успешно обновлены`)
    } catch(e) {
      console.info(e)
      this.$app.$notifyError(`Не удалось обновить действия с ${type} рассылками`)
    }
  },
  // Активация марафона
  async activateMarathon({ commit }, payload) {
    const { type } = payload
    let url = 'v2/admin/call-marathon'
    commit('changeDisabledTrigger', { isDisabled: true, type })
    try {
      let response = await axios.get(url)
      let countTasks = Number(response.data.response.replace('В марафоне: ', ''))
      commit('setCountTasks', { countTasks, type })
    } catch (e) {
      this.$app.$notifyError(`При активации марафона произошла ошибка. Попробуйте позже.`)
    } finally {
      commit('changeDisabledTrigger', { isDisabled: false, type })
    }
  },

  // обнуление кол-ва задач в марафоне
  clearMarathonCount({ commit }) {
    commit('setCountTasks', { countTasks: 0, type: 'marathonActivation' })
  },

  async getPermissions({ state, commit, rootGetters }, type) {
    const ABORT_KEY = type
    const card = state[type]
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    let url = `v2/admin/settings/${card.url}`
    if (card.typeApi === 'v3') {
      url = new UrlBuilderApiV3()
        .setEndpoint('types')
        .addFilters('filter[name]=allow_google_account_verification,allow_google_account_sharing')
        .build()
    }
    try {
      const response = card.typeApi === 'v3'
        ? await axiosV3.get(url, {signal: rootGetters.abortControllers[ABORT_KEY].signal,})
        : await axios.get(`v2/admin/settings/${card.url}`, {
            signal: rootGetters.abortControllers[ABORT_KEY].signal,
          })
      const result = card.typeApi === 'v3'
        ? response.data.find(item => item.name === card.url)
        : response.data.response
      commit('deleteAbortController', ABORT_KEY, { root: true })
      commit('setPermissionStatus', {
        titlePermission: type,
        flag: !!Number(result.value),
        id: result.id ?? undefined
      })
    } catch (e) {
      console.info(e)
      if (e.handled || axios.isCancel(e)) return
      this.$app.$notifyError(`Статус ${card.errMainContent} получить не удалось. Попробуйте позже.`)
    }
  },

  async editPermissions({ state, commit, rootGetters }, payload) {
    const { flag, type } = payload
    const card = state[type]
    const ABORT_KEY = type
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    let data = new URLSearchParams()
    data.set('value', `${Number(flag)}`)
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    let url = `v2/admin/settings/${card.url}`
    if (card.typeApi === 'v3') {
      data = new FormDataV3()
      data.addParam('type', 'settings')
      data.addParam('id', card.id)
      data.addAttr('value', Number(flag))
      url = new UrlBuilderApiV3()
        .setEndpoint(`types/${card.id}`)
        .build()
    }
    commit('changeDisabledTrigger', { isDisabled: true, type })
    try {
      card.typeApi === 'v3'
        ? await axiosV3.patch(url, data, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
        : await axios.patch(url, data, {
            signal: rootGetters.abortControllers[ABORT_KEY].signal,
            headers
          })
      commit('deleteAbortController', ABORT_KEY, { root: true })
      commit('setPermissionStatus', {
        titlePermission: type,
        flag,
        id: card.id ?? undefined
      })
      return true
    } catch (e) {
      console.info(e)
      if (e.handled || axios.isCancel(e)) return
      this.$app.$notifyError(`Статус ${card.errMainContent} обновить не удалось. Попробуйте позже.`)
    } finally {
      commit('changeDisabledTrigger', { isDisabled: false, type })
    }
  },
}
