export default {
  setUpdatedData(state, value) {
    state.updatedData = value
  },
  setUpdatedUnityData(state, value) {
    state.updatedUnityData = value
  },
  setComments(state, value) {
    state.programmerComments = value
  },
}
