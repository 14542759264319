<template>
  <div
    class="form-group"
    :class="[
      $attrs.class,
      {'input-group': hasIcon},
      {'has-danger': error},
      {'is-disabled': disabled},
      {'input-group-focus': focused},
      {'has-label': label || $slots.label},
      {'has-success': hasSuccess},
      {'has-changes': hasChanges}]">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span v-if="required" class="text-danger">
          *
        </span>
        <el-tooltip
          v-if="labelTooltip"
          :content="labelTooltip"
          placement="top"
          popper-class="input-title-tooltip">
          <el-icon>
            <QuestionFilled/>
          </el-icon>
        </el-tooltip>
      </label>
    </slot>
    <Hint v-if="isHint"/>
    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"/>
        </slot>
      </span>
    </div>
    <el-tooltip
      :disabled="disabled || !hasTooltip || hideMainTooltip"
      :placement="tooltipPlacement"
      effect="light"
      :trigger-keys="[]">
      <template #content>
        <div class="form-tooltip">
          <div v-if="tooltipIcon" class="form-tooltip__icon">
            <i :class="tooltipIcon"/>
          </div>
          <component :is="tooltipContent"/>
        </div>
      </template>
      <div
        :class="{ 'user-phone-country-register': $slots.codeCountries, 'disable-input-block': disabled }"
        @click="handleClick"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave">
        <slot>
          <input
            ref="input"
            :value="modelValue"
            :style="inputStyle"
            :required="required"
            :class="[
              'form-control',
              inputClasses,
              {'is-valid': hasSuccess, 'is-invalid': error, 'phone-register': $slots.codeCountries}
            ]"
            aria-describedby="addon-right addon-left"
            :autocomplete="noAutocomplete ? 'new-password' : 'on'"
            :disabled="disabled"
            v-bind="inputAttrs"
            v-on="listeners"
            @keyup.enter="$emit('pressEnter')">
        </slot>
        <slot name="codeCountries"/>
        <div v-if="verified" class="verify-sign">
          <i class="fa fa-check"/>
        </div>
        <div v-if="isShowDeleteAllBtn" class="delete-all" @click="handleClearValue">
          <i class="fa fa-times"/>
        </div>
        <div v-if="watchable" class="watch-sign" @click="handleWatchBtnClick">
          <img v-if="watching" src="/static/img/hide_password.svg" alt="">
          <img v-else src="/static/img/show_password.svg" alt="">
        </div>
        <div v-if="modelValue && allowCopy" class="copy-sign" @click="copyToClipboard">
          <el-tooltip
            :value="justCopied"
            manual
            :disabled="!justCopied"
            :content="$t('Copied')"
            placement="top"
            :visible-arrow="false">
            <i v-if="justCopied" class="fa fa-check"/>
            <i v-else class="fa fa-copy"/>
          </el-tooltip>
        </div>
      </div>
    </el-tooltip>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
      <span class="input-group-text">
        <slot name="addonRight">
          <i :class="addonRightIcon"/>
        </slot>
      </span>
    </div>
    <slot name="infoBlock"/>
    <slot name="helpBlock">
      <div
        v-if="error"
        class="invalid-feedback error-text"
        :class="{'mt-2': hasIcon}">
        {{ error }}
      </div>
    </slot>
    <slot name="robotInfo"/>
  </div>
</template>

<script>
import '../../../assets/sass/style/components/inputs/formGroupInput.scss'
import { ElTooltip } from 'element-plus'
import { formTooltipSettings } from '@/utils/formTooltipSettings'
import Hint from '../Tooltip/RegisterPhone'
import InputTransformer from '@/utils/inputTransformer'

export default {
  name: 'fg-input', // eslint-disable-line
  components: {
    ElTooltip,
    Hint
  },
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    label: {
      type: String,
      description: 'Input label (text before input)',
      default: ''
    },
    error: {
      type: String,
      description: 'Input error (below input)',
      default: ''
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
      default: undefined
    },
    inputClasses: {
      type: String,
      description: 'Input css classes',
      default: undefined
    },
    modelValue: {
      type: [String, Number],
      description: 'Input value',
      default: undefined
    },
    addonRightIcon: {
      type: String,
      description: 'Addon right icon',
      default: undefined
    },
    addonLeftIcon: {
      type: String,
      description: 'Addont left icon',
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    },
    watchable: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 0
    },
    isHint: {
      type: Boolean,
      default: false
    },
    noAutocomplete: {
      type: Boolean,
      default: false
    },
    transformMethod: {
      type: String,
      default: ''
    },
    deleteAllBtn: {
      type: Boolean,
      default: false
    },
    labelTooltip: {
      type: String,
      default: ''
    },
    hideMainTooltip: {
      type: Boolean,
      default: false
    },
    observe: {
      type: Boolean,
      default: false
    },
    focusHandler: {
      type: Function,
      default: () => {}
    },
    blurHandler: {
      type: Function,
      default: () => {}
    },
    allowCopy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      touched: false,
      focused: false,
      hadError: false,
      hasTooltip: false,
      tooltipContent: undefined,
      tooltipIcon: undefined,
      tooltipPlacement: undefined,
      watching: false,
      search: '',
      initValue: undefined,
      justCopied: false,
      timerCopy: undefined
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$attrs,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      }
    },
    hasSuccess() {
      if (this.$slots.codeCountries) {
        return false
      }
      return this.hadError && this.touched && !this.error
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots
      return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined
    },
    inputStyle() {
      const style = {}
      if (this.width) {
        style.width = `${this.width}px`
      }
      if (this.allowCopy) {
        style.paddingRight = '38px'
      }
      return style
    },
    isShowDeleteAllBtn() {
      return this.deleteAllBtn && this.modelValue
    },
    hasChanges() {
      if (!this.observe || this.initValue === undefined) return
      return String(this.modelValue) !== this.initValue
    },
    inputAttrs() {
      return Object.fromEntries(Object.entries(this.$attrs).filter(item => item[0] !== 'class'))
    }
  },
  watch: {
    hasChanges(value) {
      this.$emit('has-changes', value)
    }
  },
  methods: {
    updateValue(evt) {
      const transformer = new InputTransformer(evt.target.value, this.transformMethod)
      let value = transformer.change()
      evt.target.value = value
      if (!this.touched && value) {
        this.touched = true
      }
      if (value === this.modelValue) return
      this.$emit('update:model-value', value)
    },
    onFocus(value) {
      this.focused = true
      this.$refs.input.focus()
      this.$emit('focus', value) // TODO: Выпилить, заменить везде на focusHandler
      this.focusHandler(value)
    },
    onBlur(value) {
      this.focused = false
      this.$emit('blur', value) // TODO: Выпилить, заменить везде на blurHandler
      this.blurHandler(value)
    },
    handleWatchBtnClick() {
      const type = this.$refs.input.type
      if (!['text', 'password'].includes(type)) return
      if (type === 'password') {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = 'password'
      }
      this.watching = !this.watching
    },
    handleClick() {
      this.$emit('click')
    },
    handleMouseEnter() {
      this.$emit('mouseenter')
    },
    handleMouseLeave() {
      this.$emit('mouseleave')
    },
    handleClearValue() {
      this.$emit('update:model-value', '')
    },
    foundResults(list) {
      return list.filter(item => {
        const nameFound = item[this.$i18n.locale].toLowerCase().includes(this.search.toLowerCase())
        return nameFound || item.code.includes(this.search)
      })
    },
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.modelValue)
      this.justCopied = true
      if (this.timerCopy) {
        clearTimeout(this.timerCopy)
      }
      this.timerCopy = setTimeout(() => {
        this.justCopied = false
      }, 800)
    }
  },
  created() {
    this.$watch('error', (newVal) => {
      if (newVal) {
        this.hadError = true
      }
    }, { immediate: true })
  },
  mounted() {
    this.$emit('created')
    const tooltipData = formTooltipSettings.find(item => {
      const matchName = item.fieldName === this.$attrs.name
      const matchLabel = item.fieldName === this.label
      return matchName || matchLabel
    })
    if (tooltipData) {
      this.tooltipContent = tooltipData.content
      this.tooltipIcon = tooltipData.icon
      this.tooltipPlacement = tooltipData.placement || 'right'
      this.hasTooltip = true
    }
    if (this.modelValue !== undefined) {
      this.initValue = String(this.modelValue)
    }
  }
}
</script>
<style scoped>
.error-text {
  display: block;
}
</style>
