import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  taskList: null,
  taskListPageCount: null,
  updatedDataUi: {},
  updatedDataDesigner: {
    language: []
  },
  taskCount: {}
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
