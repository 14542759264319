// import axios from 'axios'

import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3";
import FormDataV3 from "@/utils/formDataApiV3";

export default {
  state: {
    taskOptionsCosts: [],
    taskOptionsTimeLimits: [],
    publishTasksCostLimits: JSON.parse(localStorage.getItem('publishTasksCostLimits')),
    updateTasksCostLimits: JSON.parse(localStorage.getItem('updateTasksCostLimits'))
  },

  getters: {
    taskOptionsCosts: state => state.taskOptionsCosts,
    taskOptionsTimeLimits: state => state.taskOptionsTimeLimits,
    publishTasksCostLimits: state => state.publishTasksCostLimits,
    updateTasksCostLimits: state => state.updateTasksCostLimits
  },

  mutations: {
    setTaskOptionsCosts(state, value) {
      state.taskOptionsCosts = value
    },
    setTaskOptionsTimeLimits(state, value) {
      state.taskOptionsTimeLimits = value
    },
    setPublishTasksCostLimits(state, value) {
      state.publishTasksCostLimits = value
      localStorage.setItem('publishTasksCostLimits', JSON.stringify(value))
    },
    setUpdateTasksCostLimits(state, value) {
      state.updateTasksCostLimits = value
      localStorage.setItem('updateTasksCostLimits', JSON.stringify(value))
    }
  },

  actions: {
    async getOptionsList({ commit, rootGetters }, payload) {
      const { limitsType } = payload
      const ABORT_KEY = limitsType
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      let url = new UrlBuilderApiV3()
        .setEndpoint('types')
        .addFilters(`filter[type]=${limitsType}`)
        .build()
      commit('setAbortController', { controller, name: ABORT_KEY })
      try {
        const response = await axiosV3.get(url, {
          signal: rootGetters.abortControllers[ABORT_KEY].signal
        })
        const limitList = response.data
        limitsType === 'costs' && commit('setTaskOptionsCosts', limitList)
        limitsType === 'time-limits' && commit('setTaskOptionsTimeLimits', limitList)
        commit('deleteAbortController', ABORT_KEY)
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('Не удалось получить настройки задач. Попробуйте позже.')
      }
    },

    async getTasksCostLimits({ commit, rootGetters }, payload) {
      const { limitsType } = payload
      const ABORT_KEY = limitsType
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })
      try {
        let url = new UrlBuilderApiV3()
          .setEndpoint('types')
          .addFilters(`filter[meta][group]=${limitsType}`)
          .build()
        const response = await axiosV3.get(url, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
        const costList = response.data
        limitsType === 'create' && commit('setPublishTasksCostLimits', costList)
        limitsType === 'update' && commit('setUpdateTasksCostLimits', costList)
        commit('deleteAbortController', ABORT_KEY)
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('Настройки лимитов задач получить не удалось. Попробуйте позже.')
      }
    },

    async editOptionsList({ dispatch }, payload) {
      const { changedData, limitsType } = payload
      const promises = []
      for (const item of changedData) {
        const data = new FormDataV3()
        data.addParam('type', limitsType)
        data.addParam('id', item.id)
        data.addAttr('value', item.value)
        const url = new UrlBuilderApiV3()
          .setEndpoint(`types/${item.id}`)
          .build()
        promises.push(axiosV3.patch(url, data))
      }
      await Promise.allSettled(promises)
      try {
        await dispatch('getOptionsList', { limitsType })
      } catch (e) {
        console.info(e)
        this.$app.$notifyError('Настройку обновить не удалось. Попробуйте позже.')
      }
    },

    async editTasksCostLimits({ dispatch }, payload) {
      const { changedData, limitsType } = payload
      const promises = []
      for (const item of changedData) {
        const data = new FormDataV3()
        data.addParam('type', 'sub-task-cost-limits')
        data.addParam('id', item.id)
        data.addAttr('value', item.value)
        const url = new UrlBuilderApiV3()
          .setEndpoint(`types/${item.id}`)
          .build()
        promises.push(axiosV3.patch(url, data))
      }
      await Promise.allSettled(promises)
      try {
        await dispatch('getTasksCostLimits', { limitsType })
      } catch (e) {
        console.info(e)
        this.$app.$notifyError('Настройку лимита задач обновить не удалось. Попробуйте позже.')
      }
    }
  }
}
