<template>
  <div class="download-page">
    <div class="download-page-header">
      <router-link to="/">
        <img class="logo" src="/static/img/getwork_logo/full_white.svg" alt="">
      </router-link>
    </div>
    <div class="download-page-content">
      <div class="content-wrap">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/sass/style/downloadPage.scss'

export default {
  name: 'DownloadLayout'
}
</script>

<style scoped>

</style>
