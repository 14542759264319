export default {
  setTaskList(state, value) {
    state.taskList = value
  },
  setTaskListPageCount(state, value) {
    state.taskListPageCount = value
  },
  changeTaskList(state, payload) {
    const {idRedirect, appId} = payload
    state.taskList = state.taskList.map(task => {
      if (task.subtask.application.id === appId) {
        task.subtask.application.mobtracking_id = idRedirect
      }
      return task
    })
  },
  setApplications(state, applications) {
    state.applications = applications
  },
  setApplicationsForUpdate(state, applications) {
    state.applicationsForUpdate = applications
  },
  setApplicationsListPageCount(state, value) {
    state.applicationsListPageCount = value
  },
  setDeletedAppsList(state, value) {
    state.deletedAppsList = value
  },
  setDeletedAppsPageCount(state, value) {
    state.deletedAppsPageCount = value
  },
  changeApplication(state, { application, newValue }) {
    let index = state.applications.indexOf(application)
    state.applications.splice(index, 1, newValue)
  },
  clearTaskBadge(state, taskId) {
    const foundTask = state.taskList.find(i => i.id === taskId)
    if (!foundTask.badge) return
    state.taskList = state.taskList.map(task => {
      if (task.id === taskId) {
        task.badge = undefined
      }
      return task
    })
  },
  clearTaskListData(state) {
    state.taskList = []
    state.taskListPageCount = null
  },
  switcherUserModal(state, value) {
    state.showUserModal = value
  },
  chooseAgeRestrictions(state, hash) {
    for (const [key, value] of Object.entries(hash)) {
      if (value === !!state.chosenAgeRestrictions[key]) continue
      state.chosenAgeRestrictions[key] = value
    }
  },
  resetChosenAgeRestrictions(state) {
    state.chosenAgeRestrictions = {}
  },
  setAgeRestrictionsValidated(state, value) {
    state.ageRestrictionsValidated = value
  },
  setHistoryChanges(state, value) {
    state.historyChanges = [...state.historyChanges, ...value]
  },
  setHistoryReference(state, value) {
    state.historyReference = [...state.historyReference, ...value]
  },
  setTotalHistoryPages(state, value) {
    state.totalHistoryPages = value
  },
  setCurrentHistoryPage(state, value) {
    state.currentHistoryPage = value
  },
  clearHistory(state) {
    state.historyChanges = []
    state.historyReference = []
    state.totalHistoryPages = undefined
    state.currentHistoryPage = 0
  }
}
