<template>
  <div class="input__hintBlock">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5223 8.524C11.0903 8.524 10.6823 8.578 10.2983 8.686C9.9143 8.794 9.4883 8.968 9.0203 9.208L8.2643 7.174C8.7323 6.874 9.2843 6.646 9.9203 6.49C10.5563 6.322 11.1803 6.238 11.7923 6.238C12.5603 6.238 13.1903 6.34 13.6823 6.544C14.1863 6.748 14.5823 7.006 14.8703 7.318C15.1583 7.63 15.3563 7.972 15.4643 8.344C15.5843 8.716 15.6443 9.07 15.6443 9.406C15.6443 9.742 15.5903 10.054 15.4823 10.342C15.3863 10.618 15.2603 10.876 15.1043 11.116C14.9483 11.356 14.7683 11.584 14.5643 11.8C14.3603 12.016 14.1623 12.22 13.9703 12.412C13.8263 12.556 13.6763 12.712 13.5203 12.88C13.3643 13.036 13.2203 13.204 13.0883 13.384C12.9683 13.564 12.8663 13.756 12.7823 13.96C12.6983 14.152 12.6563 14.35 12.6563 14.554C12.6563 14.602 12.6563 14.674 12.6563 14.77C12.6563 14.866 12.6623 14.95 12.6743 15.022H10.2983C10.2743 14.902 10.2563 14.77 10.2443 14.626C10.2443 14.47 10.2443 14.338 10.2443 14.23C10.2443 13.894 10.2863 13.594 10.3703 13.33C10.4543 13.054 10.5623 12.802 10.6943 12.574C10.8383 12.334 11.0003 12.112 11.1803 11.908C11.3603 11.704 11.5463 11.506 11.7383 11.314C12.0143 11.038 12.2663 10.768 12.4943 10.504C12.7223 10.228 12.8363 9.916 12.8363 9.568C12.8363 9.28 12.7223 9.034 12.4943 8.83C12.2783 8.626 11.9543 8.524 11.5223 8.524ZM13.1963 17.578C13.1963 18.106 13.0223 18.514 12.6743 18.802C12.3383 19.09 11.9543 19.234 11.5223 19.234C11.0903 19.234 10.7003 19.09 10.3523 18.802C10.0163 18.514 9.8483 18.106 9.8483 17.578C9.8483 17.05 10.0163 16.642 10.3523 16.354C10.7003 16.066 11.0903 15.922 11.5223 15.922C11.9543 15.922 12.3383 16.066 12.6743 16.354C13.0223 16.642 13.1963 17.05 13.1963 17.578Z"
        fill="#2684FE"/>
      <circle
        cx="12"
        cy="12"
        r="11.5"
        stroke="#2684FE"/>
    </svg>
    <div :class="locale === 'en' ? 'hintBlock enHint' : 'hintBlock'">
      <p class="titleHint">
        {{ $t('Example phone') }}
      </p>
      <div class="phoneFormat">
        <div class="phoneFormat__number">
          <p class="number">
            {{ $store.getters.codeCountry }}
            <span class="number__border"/>
          </p>
          <p>
            {{ $t('Country code') }}
          </p>
        </div>
        <div class="phoneFormat__number fullPhone">
          <p class="number">
            92198736782
            <span class="number__border"/>
          </p>
          <p>
            {{ $t('Phone number code') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hint",
  computed: {
    locale() {
      return this.$i18n.locale
    }
  }
}
</script>

<style scoped>

</style>
