export default function getGtmOptions(vueRouter) {
  return {
    id: 'GTM-5LZHQZV', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    queryParams: {
      /*
      // Добавить строку запроса URL при загрузке gtm.js с идентификатором GTM (требуется при использовании настраиваемых сред)
      gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
      gtm_preview: 'env-4',
      gtm_cookies_win: 'x'
      */
    },
    defer: false, // Сценарий можно настроить на «defer», чтобы ускорить загрузку страницы за счет менее точных результатов (в случае, если посетитель уйдет до загрузки сценария, что маловероятно, но возможно). По умолчанию false, поэтому скрипт по умолчанию загружается `асинхронно`.
    compatibility: true, // Добавит `async` и `defer` в тег script, чтобы не блокировать запросы для старых браузеров, которые не поддерживают `async`
    nonce: false, // Добавит `nonce` к тегу скрипта
    enabled: false, // по умолчанию истинно. Плагин можно отключить, установив для этого параметра значение false (необязательно)
    /*
    debug: true, // Отображать отладки в журналах консоли (необязательно)
    loadScript: true, // Загружать или нет скрипт GTM (полезно, если вы включаете GTM вручную, но вам нужны функции dataLayer в ваших компонентах) (необязательно)
    */
    vueRouter, // Передайте экземпляр роутера для автоматической синхронизации с роутером (необязательно)
    // ignoredViews: ['homepage'], // Не инициировать события для указанных имен маршрутизаторов (необязательно)
    trackOnNextTick: false // Вызывать ли trackView в Vue.nextTick или нет?
  }
}
