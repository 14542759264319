export default {
  taskList: state => state.taskList,
  taskListPageCount: state => state.taskListPageCount,
  applications: state => state.applications,
  applicationsForUpdate: state => state.applicationsForUpdate,
  applicationsListPageCount: state => state.applicationsListPageCount,
  deletedAppsList: state => state.deletedAppsList,
  deletedAppsPageCount: state => state.deletedAppsPageCount,
  showUserModal: state => state.showUserModal,
  chosenAgeRestrictions: state => state.chosenAgeRestrictions,
  ageRestrictionsValidated: state => state.ageRestrictionsValidated,
  historyChanges: state => state.historyChanges,
  historyReference: state => state.historyReference,
  totalHistoryPages: state => state.totalHistoryPages,
  currentHistoryPage: state => state.currentHistoryPage
}
