<template>
  <navbar
    :use-classes="false"
    :class="{'navbar-fixed': !smallScreen}">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"/>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"/>
        </button>
      </div>
      <div v-if="smallScreen" class="logo-img" @click="toggleMenu">
        <img src="/static/img/getwork_logo/short.png" alt="">
        <svg
          class="menu-icon"
          :class="{active: showSidebar}"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100">
          <path d="M0,15 L100,15"/>
          <path d="M0,50 L100,50"/>
          <path d="M0,50 L100,50"/>
          <path d="M0,85 L100,85"/>
        </svg>
      </div>
      <div class="navbar-brand">
        <div class="navbar-brand__title">
          <div class="title-text">
            {{ titleText }}
          </div>
          <div v-if="titleBadgeText" class="title-badge">
            {{ titleBadgeText }}
          </div>
        </div>
        <div
          v-if="smallScreen && $route.name === 'Profile'"
          class="logout-mobile-block"
          @click="logout">
          <p>{{ $t('Sign out') }}</p>
          <img src="/static/img/exit.svg" alt="" width="24">
        </div>
      </div>
    </div>
    <template #navbar-menu>
      <div v-if="displayedBalance" class="user-balance">
        <el-icon>
          <Money/>
        </el-icon>
        <div class="user-balance__amount user-balance__main">
          {{ displayedBalance?.main?.text }}
        </div>
      </div>
      <template v-if="isExternalUser">
        <el-dropdown
          :class="['interface-lang', {'display-change-lang': showSelectLang}]"
          trigger="click"
          :teleported="false"
          @visible-change="showSelectLang = !showSelectLang"
          @command="changeInterfaceLang">
          <el-button class="el-dropdown-link btn-change-lang">
            {{ user?.interface_language.toUpperCase() }}
          </el-button>
          <template #dropdown>
            <el-dropdown-menu class="change-lang-menu">
              <el-dropdown-item v-for="(item, index) in localeList" :key="index" :command="item">
                {{ item.toUpperCase() }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
      <slot name="toolbar"/>
      <drop-down
        ref="DropDown"
        icon="nc-icon nc-bell-55"
        tag="div"
        position="right"
        direction="none"
        close-by-outside-click
        class="nav-item dropdown notifications">
        <template #title="{isOpen}">
          <div
            class="nav-link dropdown-toggle notifications-button"
            :class="{hasLabel: hasNotifications}"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="isOpen">
            <el-icon>
              <Bell/>
            </el-icon>
          </div>
        </template>
        <div class="notifications-area">
          <div class="notifications-area__body text-muted">
            <div class="notifications-area__list-wrap custom-scroll">
              <div v-if="!notificationList.length" class="notifications-area__dummy text-muted">
                <el-icon>
                  <MuteNotification/>
                </el-icon>
                {{ $t('No new notifications') }}
              </div>
              <div v-else class="notifications-area__list">
                <div class="notifications-area__list-toolbar">
                  <p-button type="default" link @click="clearNotificationList">
                    {{ $t('Clear list') }}
                  </p-button>
                </div>
                <notification-card
                  v-for="notice in notificationList"
                  :key="notice.id"
                  :notice="notice"
                  :show-link="showLink"/>
              </div>
            </div>
          </div>
        </div>
      </drop-down>
    </template>
  </navbar>
</template>
<script>
import { Navbar } from 'src/components/UIComponents'
import NotificationCard from '../../Pages/Notifications/NotificationCard'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { ElButton, ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus'
import { localeList } from '@/utils/constants'

export default {
  components: {
    NotificationCard,
    Navbar,
    ElButton,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem
  },
  inject: ["mq"],
  data() {
    return {
      showSelectLang: false
    }
  },
  computed: {
    ...mapGetters([
      'titleText',
      'titleBadgeText',
      'user',
      'activeRoleName',
      'notificationList',
      'displayedBalance',
      'userWallet',
      'showSidebar',
      'types'
    ]),
    hasNotifications() {
      return !!this.notificationList.length
    },
    showLink() {
      return ['creator', 'aso_manager'].includes(this.activeRoleName)
    },
    isEng() {
      return this.$i18n.locale === 'en'
    },
    actualBalance() {
      if (!this.userWallet) return
      return parseInt(this.userWallet.balance)
    },
    mainCurrencySign() {
      return this.isEng ? '$' : '₽'
    },
    smallScreen() {
      return ['xs', 'sm'].includes(this.mq.current)
    },
    localeList() {
      return localeList.map(locale => {
        if (locale === this.user?.interface_language) return
        return locale
      }).filter(i => i)
    },
    isExternalUser() {
      return !this.user?.is_internal
    }
  },
  watch: {
    userWallet: {
      deep: true,
      handler() {
        this.saveDisplayedBalance()
      }
    }
  },
  methods: {
    ...mapMutations(['changeUserData']),
    ...mapActions(['updateUserInterfaceLang']),
    closeDropDown() {
      this.$refs['DropDown'].closeDropDown()
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize()
    },
    clearNotificationList() {
      this.$store.dispatch('clearNotificationList')
      this.closeDropDown()
    },
    getDisplayedBalance(sign, wallet) {
      return sign === '$' ? `${sign} ${wallet}` : `${wallet} ${sign}`
    },
    saveDisplayedBalance() {
      if (!this.userWallet || this.actualBalance === undefined) return
      const balance = this.isEng ? this.actualBalance / this.types?.currencyExchange ?? 1 : this.actualBalance
      const transformBalance = Number.isInteger(balance) ? balance : balance.toFixed(2)
      const result = {
        main: {
          name: this.isEng ? 'usd' : 'rub',
          text: this.getDisplayedBalance(this.mainCurrencySign, transformBalance)
        }
      }
      this.$store.dispatch('setDisplayedBalance', result)
    },
    toggleMenu() {
      this.$sidebar.displaySidebar(!this.showSidebar)
    },
    async logout() {
      const locale = this.$i18n.locale
      await this.$store.dispatch('logout')
      await this.$router.push(`/${locale}/login`)
    },
    changeInterfaceLang(value) {
      this.updateUserInterfaceLang(value)
      this.changeUserData({interface_language: value})
      this.saveDisplayedBalance()
    }
  },
  beforeMount() {
    this.saveDisplayedBalance()
  }
}

</script>
<style>

</style>
