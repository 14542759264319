import { defineAsyncComponent } from 'vue'

export default {
  common: {
    user: {
      content: defineAsyncComponent(() => import('@/components/Pages/Users/common/Modals/UserInfoModal')),
      style: {
        height: '720px',
        maxHeight: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '920px'
      },
      props: {
        hideCloseBtn: true
      }
    }
  },
  creator: {
    task: {
      content: defineAsyncComponent(() => import('@/components/Pages/Tasks/creator/TaskTemplateModal')),
      style: {
        height: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '1160px'
      },
      closeByOutsideClick: false
    },
    taskExecute: {
      content: defineAsyncComponent(() => import('@/components/Pages/Tasks/creator/TaskExecuteModal')),
      style: {
        height: 'auto',
        maxHeight: 'calc(100vh - 140px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '700px'
      },
      contentStyle: {
        maxHeight: 'calc(100vh - 140px)'
      },
      vertical: 'top'
    },
    // TODO: Удалить этот шаблон. Применить для всех приложений шаблон applicationCollapse.
    application: {
      content: defineAsyncComponent(() => import('@/components/Pages/Application/creator/ApplicationTemplateModal')),
      style: {
        height: 'auto',
        width: 'calc(100vw - 80px)',
        maxWidth: '1260px'
      },
      vertical: 'top',
      closeConfirm: true
    },
    applicationCollapse: {
      content: defineAsyncComponent(() => import('@/components/Pages/Application/creator/ApplicationCollapseModal')),
      style: {
        height: 'auto',
        maxHeight: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '700px'
      },
      contentStyle: {
        maxHeight: 'calc(100vh - 80px)'
      },
      closeConfirm: true
    },
    simpleCreAso: {
      content: defineAsyncComponent(() => import('@/components/Pages/Tasks/app_manager/SimpleTaskModal')),
      style: {
        height: 'auto',
        maxHeight: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '700px'
      },
      contentStyle: {
        maxHeight: 'calc(100vh - 80px)'
      }
    },
  },
  app_manager: {
    verification: {
      content: defineAsyncComponent(() => import('@/components/Pages/Tasks/app_manager/VerificationModal')),
      style: {
        height: 'auto',
        maxHeight: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '1100px'
      },
      closeByOutsideClick: true
    },
    updateTask: {
      content: defineAsyncComponent(() => import('@/components/Pages/Tasks/common/Modals/UpdateTaskForm')),
      style: {
        height: 'auto',
        maxHeight: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '1260px'
      },
      contentStyle: {
        maxHeight: 'calc(100vh - 80px)'
      }
    },
    simple: {
      content: defineAsyncComponent(() => import('@/components/Pages/Tasks/app_manager/SimpleTaskModal')),
      style: {
        height: 'auto',
        maxHeight: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '700px'
      },
      contentStyle: {
        maxHeight: 'calc(100vh - 80px)'
      }
    },
    simpleModeration: {
      content: defineAsyncComponent(() => import('@/components/Pages/Tasks/app_manager/SimpleModerationModal')),
      style: {
        height: 'auto',
        maxHeight: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '700px'
      },
      contentStyle: {
        maxHeight: 'calc(100vh - 80px)'
      }
    },
    browserSession: {
      content: defineAsyncComponent(() => import('@/components/Pages/Application/app_manager/BrowserSessionModal')),
      style: {
        height: 'auto',
        maxHeight: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '700px'
      },
      contentStyle: {
        maxHeight: 'calc(100vh - 80px)'
      }
    }
  },
  superadministrator: {
    promoCode: {
      content: defineAsyncComponent(() => import('@/components/Pages/Settings/PromoCode/modalWindow/PromoCodeModal')),
      style: {
        height: 'auto',
        maxHeight: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '900px'
      },
      contentStyle: {
        maxHeight: 'calc(100vh - 80px)'
      }
    },
    testEmail: {
      content: defineAsyncComponent(() => import('@/components/Pages/Settings/EmailLayouts/TestLayoutModalWindow')),
      style: {
        width: 'calc(100vw - 80px)',
        maxWidth: '900px'
      }
    },
    simple: {
      content: defineAsyncComponent(() => import('@/components/Pages/Tasks/app_manager/SimpleTaskModal')),
      style: {
        height: 'auto',
        maxHeight: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '700px'
      },
      contentStyle: {
        maxHeight: 'calc(100vh - 80px)'
      }
    },
  },
  aso_manager: {
    simpleCreAso: {
      content: defineAsyncComponent(() => import('@/components/Pages/Tasks/app_manager/SimpleTaskModal')),
      style: {
        height: 'auto',
        maxHeight: 'calc(100vh - 80px)',
        width: 'calc(100vw - 80px)',
        maxWidth: '700px'
      },
      contentStyle: {
        maxHeight: 'calc(100vh - 80px)'
      }
    },
  }
}
