export default {
  setTaskList(state, value) {
    state.taskList = value
  },
  setTaskListPageCount(state, value) {
    state.taskListPageCount = value
  },
  setUpdatedDataUi(state, value) {
    state.updatedDataUi = value
  },
  setLanguage(state, value) {
    state.updatedDataDesigner.language = value
  },
  setUpdatedDataDesigner(state, value) {
    state.updatedDataDesigner = value
  },
  setTaskCount(state, value) {
    state.taskCount = value
  }
}
