import * as rules from '@vee-validate/rules'
import { defineRule, configure } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import ru from '@vee-validate/i18n/dist/locale/ru'
import en from '@vee-validate/i18n/dist/locale/en'
import customRules from './customRules'
import customMessages from './customMessages'

export default function () {
  configure({
    validateOnInput: true
  })

  configure({
    generateMessage: localize('en', {
      messages: {
        ...en.messages,
        ...customMessages.en
      }
    })
  })

  configure({
    generateMessage: localize('ru', {
      messages: {
        ...ru.messages,
        ...customMessages.ru
      }
    })
  })

  Object.entries(rules).forEach(([rule, validator]) => {
    defineRule(rule, validator)
  })

  Object.entries(customRules).forEach(([rule, validator]) => {
    defineRule(rule, validator)
  })
}
