<template>
  <div>
    <div :class="{'nav-open': showSidebar}">
      <component :is="layout" v-if="layout"/>
      <router-view v-else/>
    </div>
  </div>
</template>

<script>
import MainLayout from 'src/components/Layout/MainLayout'
import LoginLayout from './components/Layout/LoginLayout'
import ExternalLayout from 'src/components/Layout/ExternalLayout'
import DownloadLayout from 'src/components/Layout/DownloadLayout'
import { mapGetters, mapMutations } from 'vuex'
import { EXECUTORS_HOSTNAMES } from '@/utils/constants'
import { setLocale } from '@vee-validate/i18n';

//Stop error resizeObserver
const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    super(debounce(callback, 20));
  }
};

export default {
  name: 'App',
  components: {
    MainLayout,
    ExternalLayout,
    LoginLayout,
    DownloadLayout
  },
  inject: ["mq"],
  computed: {
    ...mapGetters(['hostName', 'showSidebar', 'activeRoleName']),
    layout() {
      return this.$route.meta.layout ? this.$route.meta.layout + '-layout' : null
    },
    locale() {
      return this.$i18n.locale
    },
    mqBodyClass() {
      return `mq-${this.mq.current}`
    }
  },
  watch: {
    locale(value) {
      setLocale(value)
      this.$store.commit('setUILocale', value)
      document.title = this.$t('Title name')
      this.setTitleText(this.$i18n.t(this.$route.name))
    },
    mqBodyClass: {
      immediate: true,
        handler(value, oldValue) {
        document.body.classList.remove(oldValue)
        document.body.classList.add(value)
      }
    }
  },
  methods: {
    ...mapMutations(['setTitleText']),
  },
  created() {
    const isProduction = process.env.VUE_APP_ENV === 'prod'
    const isExecutor = EXECUTORS_HOSTNAMES.includes(this.hostName)
    this.$gtm.enable(isProduction && isExecutor)
  },
  beforeMount() {
    this.$store.dispatch('getTypes')
    this.$store.commit('setUILocale', this.locale)
    document.title = this.$t('Title name')
  }
}
</script>
<style lang="scss">

</style>
