/*
Особенности конфига фильтров:
- isDynamic - true/false - опр. необходимость динамической загрузки опций для фильтра
- default - true/false - опр. опцию по умолчанию
- disable - name of route, для которого этот вариант не должен отображаться
*/

export const filterConfig = [
  {
    name: "type",
    title: "Категория доступности",
    options: [
      {
        value: "",
        label: "Все категории",
        default: true,
      },
      {
        value: "&filter[simple][limit]=single",
        label: "Одиночные задачи",
      },
      {
        value: "&filter[simple][limit]=limited",
        label: "Лимитированные задачи",
      },
      {
        value: "&filter[simple][limit]=unlimited",
        label: "Безлимитиные задачи",
      },
    ],
  },
  {
    name: "status",
    title: "Статус",
    isDynamic: true,
    options: [
      {
        value: "",
        label: "Все статусы",
        default: true,
      },
    ],
  },
  {
    name: "statusCreAso",
    title: "Статус",
    isDynamic: true,
    options: [
      {
        value: "",
        label: "Все статусы",
        default: true,
      },
    ],
  },
  {
    name: "statusCheck",
    title: "Статус проверки",
    options: [
      {
        value: "",
        label: "Все статусы",
        default: true,
      },
      {
        value: "&filter[status][name]=published",
        label: "Доступная",
      },
      {
        value: "&filter[status][name]=moderation_rejected",
        label: "Отклонена",
      },
      {
        value: "&filter[status][name]=failed",
        label: "Провалено",
      },
      {
        value: "&filter[status][name]=completed",
        label: "Завершено",
      },
    ],
  },
  {
    name: "appNoDevelopNoPublish",
    title: "Приложения",
    options: [
      {
        value: "filter[archive]=0&filter[creator][id]={creatorId}&filter[tasks][type][name]=no_develop_no_publish",
        label: "Мои приложения",
        default: true,
      },
      {
        value: "filter[archive]=0&filter[tasks][type][name]=no_develop_no_publish",
        label: "Все приложения",
      },
    ],
  },
  {
    name: "appDevelopPublish",
    title: "Приложения",
    options: [
      {
        value: "filter[archive]=0&filter[creator][id]={creatorId}&filter[tasks][type][name]=unity_export_application,with_develop,no_develop",
        label: "Все приложения",
        default: true,
      },
      {
        value: "filter[archive]=0&filter[creator][id]={creatorId}&filter[tasks][type][name]=with_develop",
        label: "с разработкой",
      },
      {
        value: "filter[archive]=0&filter[creator][id]={creatorId}&filter[tasks][type][name]=no_develop",
        label: "без разработки",
      },
      {
        value: "filter[archive]=0&filter[creator][id]={creatorId}&filter[tasks][type][name]=unity_export_application",
        label: "Unity-приложения",
      },
    ],
  },
  {
    name: "archivedApplications",
    title: "Приложения",
    options: [
      {
        value: "filter[archive]=1&filter[creator][id]={creatorId}&filter[tasks][type][name]=unity_export_application,with_develop,no_develop",
        label: "Все приложения",
        default: true,
      },
      {
        value: "filter[archive]=1&filter[creator][id]={creatorId}&filter[tasks][type][name]=with_develop",
        label: "с разработкой",
      },
      {
        value: "filter[archive]=1&filter[creator][id]={creatorId}&filter[tasks][type][name]=no_develop",
        label: "без разработки",
      },
      {
        value: "filter[archive]=1&filter[creator][id]={creatorId}&filter[tasks][type][name]=unity_export_application",
        label: "Unity-приложения",
      },
    ],
  },
  {
    name: "userStatusConsole",
    title: "Статус консоли",
    isDynamic: true,
    options: [
      {
        value: "",
        label: "Все статусы",
        default: true,
      },
      {
        value: "filter[has_console_status]=0",
        label: "Без статуса",
      },
    ],
  },
  {
    name: "marathon",
    title: "Статус марафона",
    isDynamic: true,
    options: [
      {
        value: "",
        label: "Все статусы",
        default: true,
      },
    ],
  },
  {
    name: "typeTask",
    title: "Тип задания",
    isDynamic: true,
    options: [
      {
        value: "",
        label: "Все типы заданий",
        default: true,
      },
    ],
  },
  {
    name: "statusUpdateTask",
    title: "Статус",
    isDynamic: true,
    options: [
      {
        value: "",
        label: "Все статусы",
        default: true,
      },
    ],
  },
  {
    name: "categoryApps",
    title: "Категория приложения",
    isDynamic: true,
    options: [
      {
        value: "",
        label: "Все категории приложений",
        default: true,
      },
    ],
  },
  {
    name: "userRoles",
    title: "Роль пользователя",
    options: [
      {
        value: "",
        label: "Все роли",
        default: true,
      },
      {
        value: "filter[roles][name]=uploader",
        label: "Паблишер",
      },
      {
        value: "filter[roles][name]=uidesigner",
        label: "UX/UI-дизайнер",
      },
      {
        value: "filter[roles][name]=designer",
        label: "Дизайнер",
      },
      {
        value: "filter[roles][name]=programmer",
        label: "Разработчик",
      },
      {
        value: "filter[roles][name]=uidesigner,designer,programmer",
        label: "UX/UI дизайнер, Дизайнер, Разработчик",
        disable: 'External users'
      },
    ],
  },
];
