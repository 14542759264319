import FormDataV3 from "@/utils/formDataApiV3"
import { SIMPLE_TASK_STATUS } from '@/utils/constants'
import { UrlBuilderApiV3 } from "@/utils/urlBuilderApiV3"

export default {
  async downloadTaskList({ commit, dispatch, rootGetters }, payload) {
    const ABORT_KEY = 'taskList'
    const { activeTabName, page } = payload
    const userId = rootGetters.user.id
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    const activeStatuses = SIMPLE_TASK_STATUS[activeTabName]
      ? [activeTabName, SIMPLE_TASK_STATUS[activeTabName]].join(',')
      : activeTabName
    try {
      const filters = [
        `filter[status][name]=${activeStatuses}`,
        activeTabName === 'published' ? `filter[available_for]=${userId}` : `filter[used_by]=${userId}`
      ].join('&')
      const inclusions = [
        'status',
        'application'
      ].join(',')
      const { data, meta } = await axiosV3.get(`/subtasks?${filters}&include=${inclusions}&sort=id&page[number]=${page}`, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      commit('setTaskList', data)
      commit('setTaskListPageCount', meta.page.last_page)
      commit('deleteAbortController', ABORT_KEY, { root: true })
      dispatch('getTasksCount')
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Task list error')
    }
  },
  async downloadTask({ commit, dispatch, rootGetters }, payload) {
    const { id, type } = payload
    const ABORT_KEY = 'taskData'
    let userRole = rootGetters.activeRoleName
    if (userRole === 'superadministrator') {
      userRole = rootGetters.user.roles[0].name
    }
    const controller = new AbortController()
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    const url = ['simple', 'user_simple'].includes(type) ? `subtasks/${id}?include=task,status,user,comments,comments.user` : `${userRole}/tasks/${id}`
    try {
      const response = ['simple', 'user_simple'].includes(type)
        ? await axiosV3.get(url, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
        : await axios.get(`${userRole}/tasks/${id}`, {signal: rootGetters.abortControllers[ABORT_KEY].signal})
      const task = ['simple', 'user_simple'].includes(type) ? response.data : response.data.response
      dispatch('openTaskCard', { task }, { root: true })
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      console.info(e)
      if (e.handled || e.code === 'ERR_CANCELED') return
      this.$app.$notifyError('Task list error')
    }
  },
  clearTaskListData({ commit }) {
    commit('setTaskList', [])
    commit('setTaskListPageCount', null)
    commit('setTaskCount', {})
  },
  async takeTask({ commit, rootGetters }, simpleTask) {
    const taskId = rootGetters.selectedTask.id
    try {
      const response = simpleTask
        ? await axiosV3.post(`subtasks/${taskId}/process?include=status`)
        : await axios.put(`processing/tasks/${taskId}`)
      const updatedTask = simpleTask ? response.data : response.data.response
      const task = {
        ...rootGetters.selectedTask,
        status: updatedTask.status,
        user_id: updatedTask.user_id,
        id: simpleTask ? updatedTask.id : taskId
      }
      commit('setSelectedTask', task, { root: true })
      this.$app.$notifySuccess('Task in progress')
    } catch (e) {
      console.info(e)
      if (e.handled) return
      this.$app.$notifyError()
      throw(e)
    }
  },
  async sendTaskToCheck({ commit, rootGetters, state }, payload) {
    const { simpleTask, model } = payload
    let userRole = rootGetters.activeRoleName
    if (userRole === 'superadministrator') {
      userRole = rootGetters.user.roles[0].name
    }
    const mainTaskType = rootGetters.selectedMainTask.type?.name
    const taskId = rootGetters.selectedTask.id
    let checkingDays = {
      uidesigner: '2',
      designer: '2',
      programmer: '2'
    }[userRole]
    let data = simpleTask ? new FormDataV3() : new FormData()
    let callback = () => {
    }
    if (simpleTask) {
      data.addParam('id', String(taskId))
      data.addParam('type', 'subtasks')
      for (const [key, value] of Object.entries(model)) {
        data.addMeta(key, value)
      }
    } else {
      switch (userRole) {
        case 'uidesigner':
          const uiData = state.updatedDataUi
          if (uiData.prototype_link !== null) {
            data.set('prototype_link', uiData.prototype_link)
          }
          if (uiData.task_files) {
            if (uiData.task_files.length) {
              uiData.task_files.map((file, index) => {
                data.set(`task_files[${index}]`, file)
              })
            } else {
              data.set(`task_files[0]`, '')
            }
          }
          data.set('comment', uiData.comment)
          callback = () => {
            commit('setUpdatedDataUi', {})
          }
          break
        case 'designer':
          const designerData = state.updatedDataDesigner
          const iconRequired = rootGetters.selectedTask.main_task_type !== 'update_aso_assembly_image'
          if (iconRequired || !!designerData.icon) {
            data.set('application[icon]', designerData.icon)
          }
          if (iconRequired || !!designerData.icon_background) {
            data.set('application[icon_background]', designerData.icon_background)
          }
          designerData.language.map((item, index) => {
            data.set(`language[${index}][name]`, item.name)
            data.set(`language[${index}][icon]`, item.icon)
            for (const field in item) {
              if (['name', 'icon'].includes(field)) continue
              item[field].map((file, idx) => {
                data.set(`language[${index}][${field}][${idx}]`, file)
              })
            }
          })
          callback = () => {}
          break
        case 'programmer':
          if (mainTaskType === 'unity_export_application') {
            const programmerData = rootGetters['programmer/updatedUnityData']
            let counter = 0
            for (const lang in programmerData) {
              for (const field in programmerData[lang]) {
                data.set(`application[${counter}][${field}]`, programmerData[lang][field])
              }
              counter++
            }
            callback = () => {
              commit('programmer/setUpdatedUnityData', {}, { root: true })
            }
          } else {
            const programmerData = rootGetters['programmer/updatedData']
            const fieldRequired = rootGetters.selectedTask.main_task_type !== 'update_aso_assembly_image'
            for (const field in programmerData) {
              if (fieldRequired || !!programmerData[field]) {
                data.set(field, programmerData[field])
              }
            }
            callback = () => {
              commit('programmer/setUpdatedData', {}, { root: true })
            }
          }
          break
      }
    }
    try {
      simpleTask
        ? await axiosV3.post(`subtasks/${taskId}/review`, data)
        : await axios.put(`checking/tasks/${taskId}`, data)
      this.$app.$notifySuccess({
        key: 'Task checking message',
        params: { days: checkingDays },
        customSettings: { duration: 0, showClose: true }
      })
      callback()
    } catch (e) {
      console.info(e)
      if (!e.handled) this.$app.$notifyError()
      throw(e)
    }
  },
  async getTasksCount({ commit, rootGetters }) {
    const ABORT_KEY = 'taskCount'
    const controller = new AbortController()
    const userId = rootGetters.user.id
    if (rootGetters.abortControllers[ABORT_KEY]) {
      rootGetters.abortControllers[ABORT_KEY].abort()
    }
    commit('setAbortController', { controller, name: ABORT_KEY }, { root: true })
    const url = new UrlBuilderApiV3()
      .setEndpoint('subtasks')
      .addAggregate('total_statuses')
      .addFilters(`filter[used_by]=${userId}`)
      .addFields('fields[subtasks]=title')
      .withoutPages()
      .build()
    try {
      const response = await axiosV3.get(url, {
        signal: rootGetters.abortControllers[ABORT_KEY].signal
      })
      const taskCount = response.meta.total_statuses || {}
      commit('setTaskCount', taskCount)
      commit('deleteAbortController', ABORT_KEY, { root: true })
    } catch (e) {
      console.log(e)
    }
  }
}
