import FormDataV3 from "@/utils/formDataApiV3";

export default {

  state: {
    modeForModal: null,
    userPassport: null,
    userSelfie: null,
    checkoutResults: null
  },

  getters: {
    modeForModal: state => state.modeForModal,
    userPassport: state => state.userPassport,
    userSelfie: state => state.userSelfie,
    checkoutResults: state => state.checkoutResults
  },

  mutations: {
    setModeForModal(state, value) {
      state.modeForModal = value
    },
    setUserPassport(state, value) {
      state.userPassport = value
    },
    setUserSelfie(state, value) {
      state.userSelfie = value
    },
    setCheckoutResult(state, value) {
      state.checkoutResults = value
    }
  },

  actions: {
    async completionTask(ctx, payload) {
      const { taskId, dataSafetyFile } = payload
      const data = new FormData()
      dataSafetyFile && data.set('data_safety_file', dataSafetyFile)
      try {
        await axios.put(`completion/tasks/${taskId}`, data)
        this.$app.$notifySuccess('Задача завершена успешно')
        return true
      } catch (e) {
        console.info(e)
        if (e.handled) return
        this.$app.$notifyError('Не удалось завершить задачу. Попробуйте позже.')
        throw(e)
      }
    },
    async sendTaskForRevision(ctx, payload) {
      const {task_time, taskId} = payload
      const timeParam = task_time ? `?task_time=${task_time}` : ''
      try {
        await axios.put(`revision/tasks/${taskId}${timeParam}`)
        this.$app.$notifySuccess('Задача возвращена на доработку')
        return true
      } catch (e) {
        console.info(e)
        if (e.handled) return
        this.$app.$notifyError('Не удалось вернуть задачу на доработку. Попробуйте позже.')
        throw(e)
      }
    },

    async downloadTaskData({ rootGetters, commit, dispatch }, taskId) {
      const ABORT_KEY = 'taskData_' + taskId
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })
      try {
        const response = await axios.get(`v2/admin/sub_tasks/${taskId}`, {
          signal: rootGetters.abortControllers[ABORT_KEY].signal
        })
        const task = response.data.response
        dispatch('openTaskCard', { task, dontSetApp: true })
        commit('deleteAbortController', ABORT_KEY)
        return true
      } catch (e) {
        console.info(e)
        if (e.handled || e.code === 'ERR_CANCELED') return
        this.$app.$notifyError('При загрузке данных произошла ошибка. Попробуйте позже.')
      }
    },

    async confirmModeration({ rootGetters }, moderationType) {
      try {
        const getTaskId = () => {
          const getter = {
            upload: () => rootGetters.selectedTask.task.uploader.id,
            update: () => rootGetters.selectedTask.task.uploader.id,
            purchase: () => rootGetters.selectedTask.task.uploader.id
          }[moderationType]
          return getter()
        }
        const getUrl = () => {
          return {
            upload: 'moderation',
            update: 'moderation',
            purchase: 'confirm-purchase-moderation'
          }[moderationType]
        }
        const taskId = getTaskId()
        const url = getUrl()
        await axios.put(`${url}/tasks/${taskId}`)
        this.$app.$notifySuccess('Задача проверена')
        return true
      } catch (e) {
        console.info(e)
        if (e.handled) return
        this.$app.$notifyError('Не удалось подтвердить модерацию. Попробуйте позже.')
      }
    },

    async rejectModeration({ rootGetters }, moderationType) {
      try {
        const getTaskId = () => {
          const getter = {
            upload: () => rootGetters.selectedTask.task.uploader.id,
            update: () => rootGetters.selectedTask.task.uploader.id,
            purchase: () => rootGetters.selectedTask.task.uploader.id
          }[moderationType]
          return getter()
        }
        const getUrl = () => {
          return {
            upload: 'reject-moderation',
            update: 'reject-moderation',
            purchase: 'reject-purchase-moderation'
          }[moderationType]
        }
        const taskId = getTaskId()
        const url = getUrl()
        await axios.put(`${url}/tasks/${taskId}`)
        this.$app.$notifySuccess('Модерация отклонена')
        return true
      } catch (e) {
        console.info(e)
        if (e.handled) return
        this.$app.$notifyError('Не удалось отклонить модерацию. Попробуйте позже.')
      }
    },

    async returnPrePostModeration(ctx, payload) {
      const { id, type } = payload
      try {
        const response = await axios.put(`return_${type}/tasks/${id}`)
        return response.data.success
      } catch (e) {
        console.info(e)
        if (e.handled) return
        this.$app.$notifyError('При отправке задачи на проверку произошла ошибка')
      }
    },

    clearModeForModal({ commit }) {
      commit('setModeForModal', null)
    },

    clearSelectedMainTask({ commit }) {
      commit('setSelectedMainTask', null)
    },

    async updateMainTaskStart({ rootGetters, commit }, date) {
      const app = rootGetters.selectedApplication
      const mainTask = rootGetters.selectedMainTask
      const data = new FormData()
      data.set('publish_at', date)
      data.set('task_id', mainTask.id)
      data.set('application[id]', app.id)
      try {
        const type = {
          update_aso_text: 'aso_text',
          update_aso_image: 'aso_image',
          update_aso_assembly_image: 'aso_assembly_image'
        }[mainTask.type.name]
        const response = await axios.put(`aso_manager/update/${type}/app/${app.id}`, data)
        const newApp = response.data.response
        const apps = rootGetters.applications.map(item => {
          if (item.id === newApp.id) return newApp
          return item
        })
        commit('setApplications', apps)
      } catch (e) {
        console.info(e)
        if (e.handled) return
        this.$app.$notifyError('При редактировании задачи произошла ошибка')
      }
    },

    async getUserPassport({ commit, rootGetters }, url) {
      const ABORT_KEY = 'userPassport'
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })
      if (!url) return
      try {
        const response = await axios.get(url, {
          signal: rootGetters.abortControllers[ABORT_KEY].signal
        })
        const image = response.data.response.image
        commit('setUserPassport', image)
        commit('deleteAbortController', ABORT_KEY)
        return true
      } catch (e) {
        console.info(e)
        return false
      }
    },

    async getUserSelfie({ commit, rootGetters }, url) {
      const ABORT_KEY = 'userSelfie'
      const controller = new AbortController()
      if (rootGetters.abortControllers[ABORT_KEY]) {
        rootGetters.abortControllers[ABORT_KEY].abort()
      }
      commit('setAbortController', { controller, name: ABORT_KEY })
      if (!url) return
      try {
        const response = await axios.get(url, {
          signal: rootGetters.abortControllers[ABORT_KEY].signal
        })
        const image = response.data.response.image
        commit('setUserSelfie', image)
        commit('deleteAbortController', ABORT_KEY)
        return true
      } catch (e) {
        console.info(e)
        return false
      }
    },

    clearUserScreenshots({ commit }) {
      commit('setUserSelfie', null)
      commit('setUserPassport', null)
    },

    async downloadCheckoutResult({ commit }, payload) {
      const {id, type, checkId, task} = payload
      if (task && task.status.title !== 'Проверка') return true
      let data = ''
      if (type) {
        data = new FormDataV3()
        data.addParam('type', 'application-checks')
        data.addRelation('application', {data: {id: String(id), type: 'applications'}})
      }
      const typeReq = !type ? 'get' : 'post'
      const filterParam = !type && !checkId ? `?filter[application][id]=${String(id)}` : ''
      try {
        const response = await axiosV3[typeReq](`/application-checks${checkId || ''}${filterParam}`, data)
        const checkApp = !type && !checkId ? response.data[0] : response.data
        if (response.data) commit('setCheckoutResult', checkApp)
        return true
      } catch (e) {
        console.info(e)
        if (e.handled || (!type && !checkId)) return
        const message = typeReq === 'get' ? 'Не удалось получить данные по проверке' : 'Не удалось отправить файл на проверку'
        this.$app.$notifyError(`${message}. Попробуйте позже.`)
      }
    }
  }
}
