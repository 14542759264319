export class UrlBuilderApiV3 {
  constructor() {
    this._endpoint = ''
    this._include = []
    this._filters = []
    this._fields = []
    this._aggregate = []
    this._sort = []
    this._search = ''
    this._page = {}
    this._withoutPages = false
  }

  setEndpoint(endpoint) {
    this._endpoint = endpoint
    return this
  }

  addInclude(...include) {
    this._include.push(include)
    return this
  }

  addFilters(...filters) {
    this._filters.push(filters)
    return this
  }

  addFields(...fields) {
    this._fields.push(fields)
    return this
  }

  addAggregate(...aggregate) {
    this._aggregate.push(aggregate)
    return this
  }

  addSorting(...sorting) {
    this._sort.push(sorting)
    return this
  }

  setSearch(search) {
    if (search === undefined) {
      return this
    }
    this._search = search
    return this
  }

  setPage(number, limit = 10) {
    this._page = { number: number, limit: limit }
    return this
  }

  withoutPages() {
    this._withoutPages = true
    return this
  }



  build() {
    let url = this._endpoint
    const urlParams = []
    if (this._include.length) urlParams.push(`include=${this._include.join(',')}`)
    if (this._filters.length) urlParams.push(this._filters.join('&'))
    if (this._fields.length) urlParams.push(this._fields.join('&'))
    if (this._aggregate.length) urlParams.push(`aggregates=${this._aggregate.join(',')}`)
    if (this._sort.length) urlParams.push(`sort=${this._sort.join(',')}`)
    if (this._search.length) urlParams.push(`filter[search]=${this._search}`)
    if (Object.keys(this._page).length && !this._withoutPages) {
      urlParams.push(`page[number]=${this._page.number}`)
      urlParams.push(`page[limit]=${this._page.limit}`)
    }
    if (urlParams.length) url += `?${urlParams.join('&')}`
    return url
  }
}
