<template>
  <div class="preloader">
    <div
      v-for="item in rows"
      :key="item"
      class="preloader-item"
      :style="getItemStyle(item)"/>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    width: {
      type: [Number, String],
      default: 300
    },
    rowHeight: {
      type: Number,
      default: 21
    },
    rows: {
      type: Number,
      default: 1
    },
    indent: {
      type: Number,
      default: 3
    },
    marginBottom: {
      type: Number,
      default: 20
    }
  },
  methods: {
    getItemStyle(num) {
      const getWidth = () => {
        if (typeof this.width === 'number') {
          return `${num > 1 && num === this.rows ? this.width - 40 : this.width}px`
        } else if (typeof this.width === 'string') {
          return num > 1 && num === this.rows ? `calc(${this.width} - 40px)` : this.width
        }
      }
      return {
        width: getWidth(),
        height: `${this.rowHeight}px`,
        marginBottom: `${num < this.rows ? this.indent : this.marginBottom}px`
      }
    }
  }
}
</script>

<style scoped>

</style>
